import React, { FC } from "react";
import { Id } from "../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../machines/fetch";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluationsState,
  evaluations_ratingsState,
  peopleState,
} from "../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { addTextLineRevisionsToEvaluation } from "../../api/add-text-line-revisions-to-evaluation";
import { people_textLineRevisionStatusesState } from "3.0/selectors";
import { groupBy, intersection, sortBy } from "lodash";

type FormData = {
  sourceEvaluationId: Id;
};

type Props = {
  targetEvaluationId: Id;
  onSuccess: () => void;
};

export const CopyFromScope: FC<Props> = (props) => {
  const { targetEvaluationId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const [evaluations_ratings, setEvaluations_ratings] = useRecoilState(
    evaluations_ratingsState
  );
  const evaluations = useRecoilValue(evaluationsState);
  const evaluationsByPersonId = groupBy(
    evaluations,
    (evaluation) => evaluation.personId
  );
  const people = useRecoilValue(peopleState);
  const people_textLineRevisionStatuses = useRecoilValue(
    people_textLineRevisionStatusesState
  );

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const sourceEvaluationId = watch("sourceEvaluationId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () => {
        const sourceEvaluationTextLineRevisionIds = Object.values(
          evaluations_ratings
        )
          .filter((rel) => rel.evaluationId === Number(sourceEvaluationId))
          .map((rel) => rel.textLineRevisionId);

        const targetPersonTextLineRevisionIds = Object.values(
          people_textLineRevisionStatuses
        )
          .filter(
            (rel) =>
              rel.personId === evaluations[targetEvaluationId].personId &&
              (rel.status === "checkedInOwnProfile" ||
                rel.status === "checkedInSubProfile")
          )
          .map((rel) => rel.textLineRevisionId);

        const textLineRevisionIdsToCopy = intersection(
          sourceEvaluationTextLineRevisionIds,
          targetPersonTextLineRevisionIds
        );

        return addTextLineRevisionsToEvaluation({
          evaluationId: targetEvaluationId,
          textLineRevisionIds: textLineRevisionIdsToCopy,
          subscriptionId: activeSubscriptionId as Id,
        });
      },
    },

    actions: {
      success: (_, event: any) => {
        setEvaluations_ratings(event.data.evaluations_ratings);
        onSuccess();
        toast({
          title: "scope van bron overgenomen",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "scope overnemen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl
          isRequired
          isInvalid={errors.sourceEvaluationId !== undefined}
        >
          <FormLabel>bron</FormLabel>
          <Select
            placeholder="kies bron"
            name="sourceEvaluationId"
            ref={register({
              required: "je hebt geen bron gekozen",
            })}
          >
            {sortBy(Object.values(people), (person) =>
              person.name.toLowerCase()
            )
              .filter((person) => person.id in evaluationsByPersonId)
              .map((person) => (
                <optgroup key={person.id} label={person.name}>
                  {[...Object.values(evaluations)]
                    ?.reverse()
                    .filter((evaluation) => evaluation.personId === person.id)
                    .map(
                      (evaluation) =>
                        evaluation.id !== targetEvaluationId && (
                          <option key={evaluation.id} value={evaluation.id}>
                            {new Date(evaluation.dateTime).toLocaleDateString(
                              "nl-NL",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )}
                          </option>
                        )
                    )}
                </optgroup>
              ))}
          </Select>
          <FormErrorMessage>
            {errors.sourceEvaluationId?.message}
          </FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          neem scope over
        </Button>
      </Stack>
    </form>
  );
};

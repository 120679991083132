import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../contexts/organisation";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  latestTextLineRevisionByTextLineIdState,
  orgEntities_textLineStatusesState,
  orgEntitiesState,
  orgEntity_textLineKey,
  OrgEntity_TextLineStatus,
  orgEntityKey,
  OrgEntityType,
  textLineIdsState,
} from "../../selectors";
import { Matrix } from "../../components/matrix";
import {
  Box,
  Flex,
  Stack,
  Spacer,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  RadioGroup,
  Radio,
  Heading,
  StackDivider,
  useToast,
  Button,
  Icon,
} from "@chakra-ui/react";
import {
  Function,
  Id,
  ModelFunction,
  Person,
  Role,
  SubSubGroup,
  TextLineRevision,
} from "../../types";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { updateOrgEntityTextLine } from "../../api/update-org-entity-text-line";
import {
  activeSubscriptionIdState,
  functions_textLineRevisionsState,
  modelFunctions_textLineRevisionsState,
  orgUnits_textLineRevisionsState,
  people_textLineRevisionsState,
  roles_textLineRevisionsState,
  roleTemplates_textLinesState,
  roleTemplatesState,
  scopes_textLinesState,
  scopesState,
  subSubGroupsState,
  teams_textLineRevisionsState,
} from "../../atoms";
import Tree, { ReactD3TreeItem } from "react-d3-tree";
import {
  difference,
  flatMap,
  mapValues,
  uniq,
  differenceBy,
  groupBy,
} from "lodash";
import { Group } from "../../components/group";
import { HelpText } from "../../components/help-text";

type Props = {
  onlySelectedTextLines?: boolean;
};

type ComparisonType =
  | "none"
  | "onlyInBaseline"
  | "notInBaselineButInOthers"
  | "inAll"
  | "inSomeButNotAll";

export const TextLineMatrix: FC<Props> = ({
  onlySelectedTextLines = false,
}) => {
  const { keywordFilter, tpgFilter, subjectsFilter, selectedOrgEntityIds } =
    useContext(OrganisationContext) as NonNullable<OrganisationContextShape>;

  const [comparisonType, setComparisonType] = useState<ComparisonType>("none");

  const [comparisonBaselineOrgEntityId, setComparisonBaselineOrgEntityId] =
    useState<Id>(selectedOrgEntityIds[0]);

  useEffect(() => {
    if (comparisonType === "none") {
      setOnlyShowHighlightedTextLines(false);
    }
  }, [comparisonType]);

  useEffect(() => {
    if (!selectedOrgEntityIds.includes(comparisonBaselineOrgEntityId)) {
      setComparisonType("none");
      setComparisonBaselineOrgEntityId(selectedOrgEntityIds[0]);
    }
  }, [selectedOrgEntityIds, comparisonBaselineOrgEntityId]);

  const [onlyShowHighlightedTextLines, setOnlyShowHighlightedTextLines] =
    useState(false);

  const scopes_textLines = useRecoilValue(scopes_textLinesState);
  const scopes = useRecoilValue(scopesState);
  const roleTemplates = useRecoilValue(roleTemplatesState);
  const roleTemplates_textLines = useRecoilValue(roleTemplates_textLinesState);
  const textLineIds = useRecoilValue(textLineIdsState);
  const subSubGroups = useRecoilValue(subSubGroupsState);
  const orgEntities = useRecoilValue(orgEntitiesState);

  const orgEntities_textLineStatuses = useRecoilValue(
    orgEntities_textLineStatusesState
  );

  const selectedOrgEntities_textLineStatuses = Object.values(
    orgEntities_textLineStatuses
  ).filter((rel) => {
    if (
      !selectedOrgEntityIds.includes(
        orgEntityKey({ id: rel.orgEntityId, type: rel.orgEntityType })
      )
    ) {
      return false;
    }

    return (
      rel.calculatedStatus === "checkedInOwnProfile" ||
      rel.calculatedStatus === "checkedInSubProfile"
    );
  });

  const highlightedIntersections = (() => {
    if (comparisonType === "onlyInBaseline") {
      return differenceBy(
        // baseline org entity text line ids
        selectedOrgEntities_textLineStatuses.filter(
          (rel) =>
            comparisonBaselineOrgEntityId ===
            orgEntityKey({ type: rel.orgEntityType, id: rel.orgEntityId })
        ),
        // non-baseline org entities text line ids
        selectedOrgEntities_textLineStatuses.filter(
          (rel) =>
            comparisonBaselineOrgEntityId !==
            orgEntityKey({ type: rel.orgEntityType, id: rel.orgEntityId })
        ),
        (rel) => rel.textLineId
      );
    } else if (comparisonType === "notInBaselineButInOthers") {
      return differenceBy(
        // non-baseline org entities text line ids
        selectedOrgEntities_textLineStatuses.filter(
          (rel) =>
            comparisonBaselineOrgEntityId !==
            orgEntityKey({ type: rel.orgEntityType, id: rel.orgEntityId })
        ),
        // baseline org entity text line ids
        selectedOrgEntities_textLineStatuses.filter(
          (rel) =>
            comparisonBaselineOrgEntityId ===
            orgEntityKey({ type: rel.orgEntityType, id: rel.orgEntityId })
        ),
        (rel) => rel.textLineId
      ).map((rel) => {
        return {
          ...rel,
          orgEntityType: orgEntities[comparisonBaselineOrgEntityId].type,
          orgEntityId: orgEntities[comparisonBaselineOrgEntityId].id,
        };
      });
    } else if (comparisonType === "inAll") {
      const byTextLineId = groupBy(
        selectedOrgEntities_textLineStatuses,
        (rel) => rel.textLineId
      );

      return selectedOrgEntities_textLineStatuses.filter(
        (rel) =>
          byTextLineId[rel.textLineId].length === selectedOrgEntityIds.length
      );
    } else if (comparisonType === "inSomeButNotAll") {
      const byTextLineId = groupBy(
        selectedOrgEntities_textLineStatuses,
        (rel) => rel.textLineId
      );

      return selectedOrgEntities_textLineStatuses.filter(
        (rel) =>
          byTextLineId[rel.textLineId].length > 0 &&
          byTextLineId[rel.textLineId].length !== selectedOrgEntityIds.length
      );
    } else {
      return [];
    }
  })();

  const selectedTextLineIds = selectedOrgEntities_textLineStatuses.map(
    (rel) => rel.textLineId
  );

  const textLineIdsPassingTPGFilter = (() => {
    if (
      tpgFilter.notInRoleTemplatesAndInScopeIds.length === 0 &&
      tpgFilter.inRoleTemplateIds.length === 0
    ) {
      // filter is empty, thus ignored, return all text line ids
      return textLineIds;
    } else {
      const textLineIdsInRoleTemplates = uniq(
        Object.values(roleTemplates_textLines).map((rel) => rel.textLineId)
      );

      const textLineIdsNotInRoleTemplates = difference(
        textLineIds,
        textLineIdsInRoleTemplates
      );

      const textLineIdsNotInRoleTemplatesByScopeId = Object.values(
        scopes_textLines
      ).reduce(
        (textLineIdsNotInRoleTemplatesByScopeId: any, rel) => {
          if (textLineIdsNotInRoleTemplates.includes(rel.textLineId)) {
            return {
              ...textLineIdsNotInRoleTemplatesByScopeId,
              [rel.scopeId]: [
                ...textLineIdsNotInRoleTemplatesByScopeId[rel.scopeId],
                rel.textLineId,
              ],
            };
          }

          return {
            ...textLineIdsNotInRoleTemplatesByScopeId,
          };
        },
        mapValues(scopes, () => [])
      );

      const textLineIdsByRoleTemplateId = Object.values(
        roleTemplates_textLines
      ).reduce(
        (textLineIdsByRoleTemplateId: any, rel) => {
          return {
            ...textLineIdsByRoleTemplateId,
            [rel.roleTemplateId]: [
              ...textLineIdsByRoleTemplateId[rel.roleTemplateId],
              rel.textLineId,
            ],
          };
        },
        mapValues(roleTemplates, () => [])
      );

      return [
        ...flatMap(
          tpgFilter.inRoleTemplateIds,
          (roleTemplateId) => textLineIdsByRoleTemplateId[roleTemplateId]
        ),

        ...flatMap(
          tpgFilter.notInRoleTemplatesAndInScopeIds,
          (scopeId) => textLineIdsNotInRoleTemplatesByScopeId[scopeId]
        ),
      ];
    }
  })();

  const textLineRevisions = Object.values(
    useRecoilValue(latestTextLineRevisionByTextLineIdState)
  ).filter((textLineRevision) => {
    if (textLineRevision.content.includes("{param}")) {
      return false;
    }

    if (onlyShowHighlightedTextLines) {
      return highlightedIntersections.some(
        (intersection) =>
          intersection.textLineId === textLineRevision.textLineId
      );
    }

    if (onlySelectedTextLines) {
      return selectedTextLineIds.includes(textLineRevision.textLineId);
    }

    if (
      !textLineRevision.content
        .toLowerCase()
        .includes(keywordFilter.toLowerCase())
    ) {
      return false;
    }

    if (
      !subjectsFilter.includes(
        subSubGroups[textLineRevision.subSubGroupId]?.subGroupId
      )
    ) {
      return false;
    }

    if (!textLineIdsPassingTPGFilter.includes(textLineRevision.textLineId)) {
      return false;
    }

    if (textLineRevision.id) return true;

    return true;
  });

  return (
    <Flex direction="column" style={{ height: "100%" }}>
      <Box pb={2}>
        {selectedOrgEntityIds.length > 1 && (
          <Stack direction="row">
            <Popover>
              <PopoverTrigger>
                <Button size="xs">
                  vergelijk profielen {comparisonType !== "none" && "(actief)"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Stack spacing={4}>
                    <Stack>
                      <Heading as="h3" size="sm" fontWeight="medium">
                        markeer tekstregels:
                      </Heading>

                      <RadioGroup
                        onChange={(type: ComparisonType) => {
                          setComparisonType(type);
                        }}
                        value={comparisonType}
                      >
                        <Stack>
                          <Radio value="none">geen</Radio>
                          <Radio value="onlyInBaseline">
                            alleen gekozen voor{" "}
                            {orgEntities[comparisonBaselineOrgEntityId]?.name}
                          </Radio>
                          <Radio value="notInBaselineButInOthers">
                            niet gekozen voor{" "}
                            {orgEntities[comparisonBaselineOrgEntityId]?.name}{" "}
                            maar wel voor andere profielen
                          </Radio>
                          <Radio value="inAll">
                            gekozen voor alle geselecteerde profielen
                          </Radio>
                          <Radio value="inSomeButNotAll">
                            gekozen voor sommige (maar niet alle) van de
                            geselecteerde profielen
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Stack>

                    <Stack>
                      <Heading as="h3" size="sm" fontWeight="medium">
                        uitgangspunt voor vergelijking:
                      </Heading>

                      <RadioGroup
                        onChange={(id) => {
                          setComparisonBaselineOrgEntityId(id);
                        }}
                        value={comparisonBaselineOrgEntityId}
                      >
                        <Stack>
                          {selectedOrgEntityIds.map((id) => (
                            <Radio key={id} value={id}>
                              {orgEntities[id].name}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    </Stack>

                    <Stack>
                      <Heading as="h3" size="sm" fontWeight="medium">
                        weergave:
                      </Heading>

                      <Checkbox
                        isDisabled={comparisonType === "none"}
                        isChecked={onlyShowHighlightedTextLines}
                        onChange={(e) => {
                          setOnlyShowHighlightedTextLines(e.target.checked);
                        }}
                      >
                        toon alleen gemarkeerde tekstregels
                      </Checkbox>
                    </Stack>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <HelpText tag="AddRulesSource" />
          </Stack>
        )}
      </Box>

      <Box flexGrow={1}>
        <Matrix
          textLineRevisionIds={textLineRevisions.map(
            (textLineRevision) => textLineRevision.id
          )}
          renderGroupRow={(subSubGroup, index) => (
            <GroupRow
              subSubGroup={subSubGroup}
              index={index}
              comparisonType={comparisonType}
              comparisonBaselineOrgEntityId={comparisonBaselineOrgEntityId}
            />
          )}
          renderTextLineRevisionRow={(textLineRevision) => (
            <TextLineRevisionRow
              textLineRevision={textLineRevision}
              highlightedIntersections={highlightedIntersections}
            />
          )}
        />
      </Box>
    </Flex>
  );
};

type GroupRowProps = {
  subSubGroup: SubSubGroup;
  index: number;
  comparisonType: ComparisonType;
  comparisonBaselineOrgEntityId: Id;
};

const GroupRow: FC<GroupRowProps> = memo((props) => {
  const { subSubGroup, index, comparisonType, comparisonBaselineOrgEntityId } =
    props;
  const containerRef = useRef<any>();
  const labelRef = useRef<any>();

  useLayoutEffect(() => {
    if (containerRef.current && labelRef.current) {
      containerRef.current.style.height = `${
        labelRef.current.getBoundingClientRect().height + 5
      }px`;
    }
  });

  const { selectedOrgEntityIds } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const orgEntities = useRecoilValue(orgEntitiesState);

  return (
    <Flex
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      alignItems="flex-end"
      pl={2}
      pr={4}
      mt={index > 0 ? 12 : 0}
    >
      <Group subSubGroup={subSubGroup} />

      <Spacer />

      <Flex ref={containerRef} pr={2}>
        <Stack
          transform="rotate(90deg) translateY(100%)"
          transformOrigin="bottom right"
          spacing={8}
        >
          {selectedOrgEntityIds
            .slice()
            .reverse()
            .map((id) => (
              <Flex
                key={id}
                ref={labelRef}
                transform="rotate(-45deg)"
                transformOrigin="bottom right"
                align="center"
                lineHeight={0}
                fontSize="sm"
                fontWeight={
                  comparisonType !== "none" &&
                  comparisonBaselineOrgEntityId === id
                    ? "bold"
                    : "normal"
                }
                _after={{
                  content: "''",
                  borderBottom: "1px solid #eee",
                  flexGrow: 1,
                  ml: 2,
                  minW: 3,
                }}
              >
                {orgEntities[id].name}
              </Flex>
            ))}
        </Stack>
      </Flex>
    </Flex>
  );
});

type TextLineRevisionRowProps = {
  textLineRevision: TextLineRevision;
  highlightedIntersections: OrgEntity_TextLineStatus[];
};

const TextLineRevisionRow: FC<TextLineRevisionRowProps> = memo((props) => {
  const { textLineRevision, highlightedIntersections } = props;

  const { selectedOrgEntityIds } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const orgEntities = useRecoilValue(orgEntitiesState);

  return (
    <Stack
      direction="row"
      align="baseline"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      spacing={3}
      px={2}
      py={1}
    >
      <Box flexGrow={1}>{textLineRevision.content}</Box>

      <Flex direction="row">
        {selectedOrgEntityIds.map((id) => (
          <Intersection
            key={`${id}-${textLineRevision.id}`}
            orgEntity={orgEntities[id]}
            textLineRevision={textLineRevision}
            isHighlighted={highlightedIntersections.some(
              (rel) =>
                rel.textLineId === textLineRevision.textLineId &&
                rel.orgEntityType === orgEntities[id].type &&
                rel.orgEntityId === orgEntities[id].id
            )}
          />
        ))}
      </Flex>
    </Stack>
  );
});

type IntersectionProps = {
  orgEntity: (Person | Function | ModelFunction | Role) & {
    type: OrgEntityType;
  };
  textLineRevision: TextLineRevision;
  isHighlighted: boolean;
};

const Intersection: FC<IntersectionProps> = (props) => {
  const { orgEntity, textLineRevision, isHighlighted } = props;

  const toast = useToast();

  const [updateState, updateSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setPeople_textLineRevisions(event.data.people_textLineRevisions);
        setFunctions_textLineRevisions(event.data.functions_textLineRevisions);
        setModelFunctions_textLineRevisions(
          event.data.modelFunctions_textLineRevisions
        );
        setRoles_textLineRevisions(event.data.roles_textLineRevisions);
        setOrgUnits_textLineRevisions(event.data.orgUnits_textLineRevisions);
        setTeams_textLineRevisions(event.data.teams_textLineRevisions);

        toast({
          title: "status aangepast",
          status: "success",
          isClosable: true,
          duration: 1000,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "aanpassen van status niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  const [popoverIsVisible, setPopoverIsVisible] = useState(false);

  const [treeIsVisible, setTreeIsVisible] = useState(false);

  const setRoles_textLineRevisions = useSetRecoilState(
    roles_textLineRevisionsState
  );
  const setModelFunctions_textLineRevisions = useSetRecoilState(
    modelFunctions_textLineRevisionsState
  );
  const setFunctions_textLineRevisions = useSetRecoilState(
    functions_textLineRevisionsState
  );
  const setPeople_textLineRevisions = useSetRecoilState(
    people_textLineRevisionsState
  );
  const setOrgUnits_textLineRevisions = useSetRecoilState(
    orgUnits_textLineRevisionsState
  );
  const setTeams_textLineRevisions = useSetRecoilState(
    teams_textLineRevisionsState
  );

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const orgEntities = useRecoilValue(orgEntitiesState);

  const orgEntity_textLineStatus = useRecoilValue(
    orgEntities_textLineStatusesState
  )[
    orgEntity_textLineKey({
      orgEntityType: orgEntity.type,
      orgEntityId: orgEntity.id,
      textLineId: textLineRevision.textLineId,
    })
  ] as OrgEntity_TextLineStatus | undefined;

  const checkbox = (
    <Flex
      align="center"
      borderWidth={8}
      borderColor={isHighlighted ? "yellow.300" : "transparent"}
    >
      <Checkbox
        isChecked={
          orgEntity_textLineStatus?.calculatedStatus ===
            "checkedInOwnProfile" ||
          orgEntity_textLineStatus?.calculatedStatus === "checkedInSubProfile"
        }
        isDisabled={
          orgEntity_textLineStatus?.orgEntityType === "roleTemplate" ||
          updateState.matches("loading")
        }
        onChange={() => {
          if (
            orgEntity_textLineStatus?.orgEntityType === "role" ||
            orgEntity_textLineStatus?.orgEntityType === "orgUnit" ||
            orgEntity_textLineStatus?.orgEntityType === "team"
          ) {
            updateSend(updateState.matches("failure") ? "RETRY" : "UPDATE", {
              update: updateOrgEntityTextLine({
                subscriptionId: activeSubscriptionId as Id,
                orgEntityType: orgEntity.type as "role" | "orgUnit" | "team",
                orgEntityId: orgEntity.id,
                textLineId: textLineRevision.textLineId,
                status:
                  orgEntity_textLineStatus.ownProfileStatus === "checked"
                    ? "notChecked"
                    : "checked",
              }),
            });
          } else {
            setPopoverIsVisible(true);
          }
        }}
        borderColor={
          orgEntity_textLineStatus?.calculatedStatus === "uncheckedInOwnProfile"
            ? "persianGreen"
            : undefined
        }
        {...(orgEntity_textLineStatus?.calculatedStatus ===
        "checkedInSubProfile"
          ? {
              icon: (
                <Icon viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M21 16.142V11h-8V7.858a4 4 0 10-2 0V11H3v5.142a4 4 0 102 0V13h14v3.142a4 4 0 102 0z"
                  />
                </Icon>
              ),
            }
          : {})}
        bgColor="white"
      />
    </Flex>
  );

  if (popoverIsVisible) {
    const ownProfileStatus = (() => {
      if (orgEntity_textLineStatus?.ownProfileStatus) {
        return orgEntity_textLineStatus.ownProfileStatus;
      } else if (
        orgEntity.type === "person" ||
        orgEntity.type === "function" ||
        orgEntity.type === "modelFunction"
      ) {
        return "inherited";
      } else {
        return "notChecked";
      }
    })();

    return (
      <Popover
        isOpen={true}
        onClose={() => {
          setPopoverIsVisible(false);
        }}
        placement="left"
      >
        <PopoverTrigger>{checkbox}</PopoverTrigger>
        <PopoverContent {...(treeIsVisible ? { w: "lg" } : {})}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Stack divider={<StackDivider />} spacing={3}>
              {/* own profile state */}
              <Stack>
                <Heading size="sm" fontWeight="medium">
                  status
                </Heading>
                <RadioGroup
                  value={ownProfileStatus}
                  onChange={(status) => {
                    updateSend(
                      updateState.matches("failure") ? "RETRY" : "UPDATE",
                      {
                        update: updateOrgEntityTextLine({
                          subscriptionId: activeSubscriptionId as Id,
                          orgEntityType: orgEntity.type as Exclude<
                            OrgEntityType,
                            "roleTemplate"
                          >,
                          orgEntityId: orgEntity.id,
                          textLineId: textLineRevision.textLineId,
                          // @ts-ignore
                          status,
                        }),
                      }
                    );
                  }}
                >
                  <Stack spacing={5} direction="row">
                    {(orgEntity.type === "person" ||
                      orgEntity.type === "function" ||
                      orgEntity.type === "modelFunction") && (
                      <Radio
                        value="inherited"
                        isDisabled={updateState.matches("loading")}
                      >
                        erf
                      </Radio>
                    )}

                    <Radio
                      value="checked"
                      isDisabled={updateState.matches("loading")}
                    >
                      aan
                    </Radio>

                    {(orgEntity.type === "person" ||
                      orgEntity.type === "function" ||
                      orgEntity.type === "modelFunction") && (
                      <Radio
                        value="unchecked"
                        isDisabled={updateState.matches("loading")}
                      >
                        uit
                      </Radio>
                    )}

                    {(orgEntity.type === "role" ||
                      orgEntity.type === "orgUnit" ||
                      orgEntity.type === "team") && (
                      <Radio
                        value="notChecked"
                        isDisabled={updateState.matches("loading")}
                      >
                        uit
                      </Radio>
                    )}
                  </Stack>
                </RadioGroup>
              </Stack>

              {orgEntity_textLineStatus && (
                <Checkbox
                  isChecked={treeIsVisible}
                  onChange={(e) => {
                    return setTreeIsVisible(e.target.checked);
                  }}
                >
                  toon herkomst
                </Checkbox>
              )}

              {/* calculated state */}
              {orgEntity_textLineStatus ? (
                treeIsVisible && (
                  <Box overflowY="auto" h="xs" w="full">
                    <Tree
                      orientation="vertical"
                      collapsible={false}
                      separation={{ siblings: 3, nonSiblings: 3 }}
                      pathFunc="step"
                      // nodeSvgShape={{ shape: "none" }}
                      scaleExtent={{ min: 0.1, max: 5 }}
                      translate={{
                        x: 150,
                        y: 50,
                      }}
                      transitionDuration={0}
                      data={(() => {
                        const toD3TreeItem = (
                          item: OrgEntity_TextLineStatus
                        ): ReactD3TreeItem => {
                          const orgEntity =
                            orgEntities[
                              orgEntityKey({
                                type: item.orgEntityType,
                                id: item.orgEntityId,
                              })
                            ];

                          return {
                            name: orgEntity.name,
                            attributes: {
                              soort:
                                (item.orgEntityType === "person" &&
                                  "persoon") ||
                                (item.orgEntityType === "modelFunction" &&
                                  "modelfunctie") ||
                                (item.orgEntityType === "orgUnit" &&
                                  "organisatie-eenheid") ||
                                (item.orgEntityType === "function" &&
                                  "functie") ||
                                (item.orgEntityType === "role" && "rol") ||
                                "team",

                              "status eigen profiel":
                                (item.ownProfileStatus === "checked" &&
                                  "aangevinkt") ||
                                (item.ownProfileStatus === "inherited" &&
                                  "geërfd") ||
                                (item.ownProfileStatus === "notChecked" &&
                                  "niet aangevinkt") ||
                                "uitgevinkt",

                              "berekende status":
                                (item.calculatedStatus ===
                                  "checkedInOwnProfile" &&
                                  "aangevinkt in eigen profiel") ||
                                (item.calculatedStatus ===
                                  "checkedInSubProfile" &&
                                  "aangevinkt in subprofiel") ||
                                (item.calculatedStatus ===
                                  "uncheckedInOwnProfile" &&
                                  "uitgevinkt in eigen profiel") ||
                                "niet aangevinkt",
                            },

                            nodeSvgShape: {
                              shape: "rect",
                              shapeProps: {
                                height: 10,
                                width: 10,
                                x: -5,
                                y: -5,
                                fill:
                                  (item.calculatedStatus ===
                                    "checkedInOwnProfile" &&
                                    "green") ||
                                  (item.calculatedStatus ===
                                    "checkedInSubProfile" &&
                                    "lightgreen") ||
                                  (item.calculatedStatus ===
                                    "uncheckedInOwnProfile" &&
                                    "red") ||
                                  "blue",
                              },
                            },
                            children: item.subProfileStatuses.map(toD3TreeItem),
                          };
                        };

                        return toD3TreeItem(orgEntity_textLineStatus);
                      })()}
                    />
                  </Box>
                )
              ) : (
                <div>
                  {/* geen enkel profiel binnen dit abonnement heeft een aangepaste
                  status voor deze tekstregel, derhalve is vanuit
                  performance-overwegingen de profiel/tekstregel-boom niet
                  berekend */}
                </div>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return checkbox;
};

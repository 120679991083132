import { api } from "./common";
import {
  Id,
  ModelFunction,
  ModelFunction_Role,
  Person,
  Role,
  Function,
  Function_Role,
  Function_ModelFunction,
  OrgUnit,
  Person_Role,
  Person_Function,
  modelFunction_RoleKey,
  function_RoleKey,
  function_modelFunctionKey,
  person_roleKey,
  person_functionKey,
  Team,
  Person_Team,
  person_teamKey,
} from "../types";
import { keyBy, flatMap } from "lodash";

type ApiResponse = {
  roles: {
    id: number;
    name: string;
  }[];

  modelFunctions: {
    id: number;
    name: string;
    roleIds: number[];
  }[];

  functions: {
    id: number;
    name: string;
    departmentId: number;
    roleIds: number[];
    modelFunctionIds: number[];
  }[];

  person: {
    id: number;
    displayName: string;
    roleIds: number[];
    functionIds: number[];
    teamMembershipIds: number[];
  }[];

  departments: {
    id: number;
    name: string;
    parentDepartmentId: number | null;
  }[];

  teams: {
    id: number;
    name: string;
  }[];
};

type TransformedData = {
  roles: Record<Id, Role>;
  modelFunctions: Record<Id, ModelFunction>;
  modelFunctions_roles: Record<Id, ModelFunction_Role>;
  functions: Record<Id, Function>;
  functions_roles: Record<Id, Function_Role>;
  functions_modelFunctions: Record<Id, Function_ModelFunction>;
  people: Record<Id, Person>;
  people_roles: Record<Id, Person_Role>;
  people_functions: Record<Id, Person_Function>;
  people_teams: Record<Id, Person_Team>;
  orgUnits: Record<Id, OrgUnit>;
  teams: Record<Id, Team>;
};

export const fetchOrgEntities = async (
  subscriptionId: Id
): Promise<TransformedData> => {
  const data = await api<ApiResponse>("v2/init/subscription", {
    headers: {
      subscriptionid: subscriptionId.toString(),
    },
  });

  return {
    roles: keyBy(
      data.roles.map(({ id, name }) => ({
        id,
        name,
      })),
      (role) => role.id
    ),

    modelFunctions: keyBy(
      data.modelFunctions.map(({ id, name }) => ({
        id,
        name,
      })),
      (modelFunction) => modelFunction.id
    ),

    modelFunctions_roles: keyBy(
      flatMap(data.modelFunctions, (modelFunction) =>
        modelFunction.roleIds.map((roleId) => ({
          modelFunctionId: modelFunction.id,
          roleId,
        }))
      ),
      modelFunction_RoleKey
    ),

    functions: keyBy(
      data.functions.map(({ id, name, departmentId }) => ({
        id,
        name,
        orgUnitId: departmentId,
      })),
      (func) => func.id
    ),

    functions_roles: keyBy(
      flatMap(data.functions, (func) =>
        func.roleIds.map((roleId) => ({
          functionId: func.id,
          roleId,
        }))
      ),
      function_RoleKey
    ),

    functions_modelFunctions: keyBy(
      flatMap(data.functions, (func) =>
        func.modelFunctionIds.map((modelFunctionId) => ({
          functionId: func.id,
          modelFunctionId,
        }))
      ),
      function_modelFunctionKey
    ),

    orgUnits: keyBy(
      data.departments.map(({ id, name, parentDepartmentId }) => ({
        id,
        name,
        parentOrgUnitId: parentDepartmentId || undefined,
      })),
      (orgUnit) => orgUnit.id
    ),

    teams: keyBy(
      data.teams.map(({ id, name }) => ({
        id,
        name,
      })),
      (team) => team.id
    ),

    people: keyBy(
      data.person.map(({ id, displayName: name }) => ({
        id,
        name,
      })),
      (person) => person.id
    ),

    people_roles: keyBy(
      flatMap(data.person, (person) =>
        person.roleIds.map((roleId) => ({
          personId: person.id,
          roleId,
        }))
      ),
      person_roleKey
    ),

    people_functions: keyBy(
      flatMap(data.person, (person) =>
        person.functionIds.map((functionId) => ({
          personId: person.id,
          functionId,
        }))
      ),
      person_functionKey
    ),

    people_teams: keyBy(
      flatMap(data.person, (person) =>
        person.teamMembershipIds.map((teamId) => ({
          personId: person.id,
          teamId,
        }))
      ),
      person_teamKey
    ),
  };
};

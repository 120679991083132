import React, { FC } from "react";
import { Id, Person } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { addPerson } from "../../../api/add-person";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeSubscriptionIdState, peopleState } from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";

type FormData = Pick<Person, "name">;

type Props = {
  onSuccess: (newPersonId: Id) => void;
};

export const AddPerson: FC<Props> = (props) => {
  const { onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople = useSetRecoilState(peopleState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchName = watch("name");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        addPerson({
          name: watchName,
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople(event.data.people);
        onSuccess(event.data.newPersonId);
        toast({
          title: "persoon toegevoegd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "toevoegen van persoon niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.name !== undefined}>
          <FormLabel>naam</FormLabel>
          <Input
            name="name"
            ref={register({
              required: "je hebt geen naam ingevuld",
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          voeg toe
        </Button>
      </Stack>
    </form>
  );
};

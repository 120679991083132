import { api } from "./common";
import { Id, Person_Team } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person_Team, "personId" | "teamId">;

export const linkPersonToTeam = async (props: Props) => {
  await api("v2/teammemberships", {
    method: "POST",
    body: JSON.stringify({
      personId: props.personId,
      teamId: props.teamId,
      name: `${props.personId}-${props.teamId}`, // why does a team membership even need a name?
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people_teams } = await fetchOrgEntities(props.subscriptionId);

  return { people_teams };
};

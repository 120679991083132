import { Machine } from "xstate";

export type UpdateStateSchema = {
  states: {
    idle: {};
    loading: {};
    success: {};
    failure: {};
  };
};

export type UpdateEvent =
  | { type: "UPDATE"; update: any }
  | { type: "RETRY"; update: any };

export const updateMachine = Machine<{}, UpdateStateSchema, UpdateEvent>({
  strict: true,
  id: "update",
  initial: "idle",
  states: {
    idle: {
      on: {
        UPDATE: {
          target: "loading",
        },
      },
    },

    loading: {
      invoke: {
        id: "update",
        src: (_, e) => e.update,
        onDone: {
          target: "success",
          actions: ["success"],
        },
        onError: {
          target: "failure",
          actions: ["failure"],
        },
      },
    },

    success: {
      always: "idle",
    },

    failure: {
      on: {
        RETRY: "loading",
      },
    },
  },
});

import React, { FC } from "react";
import { Id, Person_Role } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  rolesState,
  people_rolesState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { linkPersonToRole } from "../../../api/link-person-to-role";

type FormData = Pick<Person_Role, "roleId">;

type Props = {
  personId: Id;
  onSuccess: () => void;
};

export const LinkPersonToRole: FC<Props> = (props) => {
  const { personId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople_roles = useSetRecoilState(people_rolesState);
  const roles = useRecoilValue(rolesState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchRoleId = watch("roleId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        linkPersonToRole({
          personId,
          roleId: Number(watchRoleId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople_roles(event.data.people_roles);
        onSuccess();
        toast({
          title: "koppeling tussen persoon en rol tot stand gebracht",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "koppelen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.roleId !== undefined}>
          <FormLabel>rol</FormLabel>
          <Select
            placeholder="kies rol"
            name="roleId"
            ref={register({
              required: "je hebt geen rol gekozen",
            })}
          >
            {Object.values(roles).map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.roleId?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          koppel
        </Button>
      </Stack>
    </form>
  );
};

import { api } from "./common";
import {
  ModelFunction_TextLineRevision,
  Person_TextLineRevision,
  Role_TextLineRevision,
  Function_TextLineRevision,
  OrgUnit_TextLineRevision,
  Id,
  role_textLineRevisionKey,
  modelFunction_textLineRevisionKey,
  function_textLineRevisionKey,
  person_textLineRevisionKey,
  orgUnit_textLineRevisionKey,
  Team_TextLineRevision,
  team_textLineRevisionKey,
} from "../types";
import { keyBy, flatMap } from "lodash";

type ApiResponse = {
  roles: {
    id: number;
    textLines: {
      textLineRevisionId: number;
    }[];
  }[];

  modelFunctions: {
    id: number;
    textLines: {
      textLineRevisionId: number;
      check: boolean;
    }[];
  }[];

  functions: {
    id: number;
    textLines: {
      textLineRevisionId: number;
      check: boolean;
    }[];
  }[];

  persons: {
    id: number;
    textLines: {
      textLineRevisionId: number;
      check: boolean;
    }[];
  }[];

  departments: {
    id: number;
    textLines: {
      textLineRevisionId: number;
      check: boolean;
    }[];
  }[];

  teams: {
    id: number;
    textLines: {
      textLineRevisionId: number;
      check: boolean;
    }[];
  }[];
};

type TransformedData = {
  roles_textLineRevisions: Record<Id, Role_TextLineRevision>;
  modelFunctions_textLineRevisions: Record<Id, ModelFunction_TextLineRevision>;
  functions_textLineRevisions: Record<Id, Function_TextLineRevision>;
  people_textLineRevisions: Record<Id, Person_TextLineRevision>;
  orgUnits_textLineRevisions: Record<Id, OrgUnit_TextLineRevision>;
  teams_textLineRevisions: Record<Id, Team_TextLineRevision>;
};

export const fetchProfiles = async (
  subscriptionId: Id
): Promise<TransformedData> => {
  const data = await api<ApiResponse>("v2/init/profiles", {
    headers: {
      subscriptionid: subscriptionId.toString(),
    },
  });

  return {
    roles_textLineRevisions: keyBy(
      flatMap(data.roles, (role) =>
        role.textLines.map(({ textLineRevisionId }) => ({
          roleId: role.id,
          textLineRevisionId,
        }))
      ),
      role_textLineRevisionKey
    ),

    modelFunctions_textLineRevisions: keyBy(
      flatMap(data.modelFunctions, (modelFunction) =>
        modelFunction.textLines.map(({ textLineRevisionId, check }) => ({
          modelFunctionId: modelFunction.id,
          textLineRevisionId,
          isChecked: check,
        }))
      ),
      modelFunction_textLineRevisionKey
    ),

    functions_textLineRevisions: keyBy(
      flatMap(data.functions, (func) =>
        func.textLines.map(({ textLineRevisionId, check }) => ({
          functionId: func.id,
          textLineRevisionId,
          isChecked: check,
        }))
      ),
      function_textLineRevisionKey
    ),

    people_textLineRevisions: keyBy(
      flatMap(data.persons, (person) =>
        person.textLines.map(({ textLineRevisionId, check }) => ({
          personId: person.id,
          textLineRevisionId,

          // isChecked: true, // temp until API is fixed
          isChecked: check,
        }))
      ),
      person_textLineRevisionKey
    ),

    orgUnits_textLineRevisions: keyBy(
      flatMap(data.departments, (orgUnit) =>
        orgUnit.textLines.map(({ textLineRevisionId }) => ({
          orgUnitId: orgUnit.id,
          textLineRevisionId,
        }))
      ),
      orgUnit_textLineRevisionKey
    ),

    teams_textLineRevisions: keyBy(
      flatMap(data.teams, (team) =>
        team.textLines.map(({ textLineRevisionId }) => ({
          teamId: team.id,
          textLineRevisionId,
        }))
      ),
      team_textLineRevisionKey
    ),
  };
};

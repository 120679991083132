import React, { FC, useContext, useEffect } from "react";
import { AppContext } from "../../contexts/app";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../machines/fetch";
import { fetchEvaluations } from "../../api/fetch-evaluations";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
  evaluationsState as evaluationsStateAtom,
} from "../../atoms";
import { Id } from "../../types";
import { EvaluationsContext } from "../../contexts/evaluations";
import { List } from "./list";
import { selectedEvaluationMachine } from "../../machines/selected-evaluation";
import { Box } from "@chakra-ui/react";
import { Scope } from "./scope";
import { Ratings } from "./ratings";
import { Panel, PanelGroup } from "../../components/panel";
import { Helmet } from "react-helmet";
import { Details } from "./details";
import { Status } from "./status";

export const Evaluations: FC = () => {
  const [evaluations, setEvaluations] = useRecoilState(evaluationsStateAtom);
  const setEvaluations_ratings = useSetRecoilState(evaluations_ratingsState);
  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const { userAndSubscriptionsMachine } = useContext(AppContext);
  const [userAndSubscriptionsState] =
    userAndSubscriptionsMachine as NonNullable<
      typeof userAndSubscriptionsMachine
    >;

  const [evaluationsState, evaluationsSend, evaluationsService] = useMachine(
    fetchMachine,
    {
      services: {
        fetch: () => fetchEvaluations(activeSubscriptionId as Id),
      },

      actions: {
        success: (_, event: any) => {
          setEvaluations(event.data.evaluations);
          setEvaluations_ratings(event.data.evaluations_ratings);
        },
      },
    }
  );

  const [
    selectedEvaluationState,
    selectedEvaluationSend,
    selectedEvaluationService,
  ] = useMachine(selectedEvaluationMachine);

  /**
   * Fetch evaluations once user and subscriptions are fetched.
   */
  useEffect(() => {
    if (userAndSubscriptionsState.matches("success")) {
      evaluationsSend("FETCH");
    }
  }, [userAndSubscriptionsState, evaluationsSend]);

  useEffect(() => {
    if (selectedEvaluationState.matches({ selected: "pending" })) {
      const selectedEvaluation =
        evaluations[selectedEvaluationState.context.selectedEvaluationId as Id];

      const eventByStatus = {
        definingScope: "DEFINING_SCOPE",
        scopeDefined: "SCOPE_DEFINED",
        conducting: "CONDUCTING",
        conducted: "CONDUCTED",
      } as const;

      selectedEvaluationSend(eventByStatus[selectedEvaluation.status]);
    }
  }, [selectedEvaluationState, selectedEvaluationSend, evaluations]);

  useEffect(() => {
    if (
      selectedEvaluationState.matches("selected") &&
      !Object.values(evaluations).some(
        (evaluation) =>
          evaluation.id === selectedEvaluationState.context.selectedEvaluationId
      )
    ) {
      selectedEvaluationSend("DESELECT_EVALUATION");
    }
  }, [selectedEvaluationState, selectedEvaluationSend, evaluations]);

  return (
    <>
      <Helmet>
        <title>Evaluaties</title>
      </Helmet>

      <EvaluationsContext.Provider
        value={{
          evaluationsMachine: [
            evaluationsState,
            evaluationsSend,
            evaluationsService,
          ],

          selectedEvaluationMachine: [
            selectedEvaluationState,
            selectedEvaluationSend,
            selectedEvaluationService,
          ],
        }}
      >
        <PanelGroup>
          {/* list */}
          <Panel id="list" title="overzicht" minWidth="500px" maxWidth="500px">
            <Box p={3}>
              <List />
            </Box>
          </Panel>

          {/* details */}
          {selectedEvaluationState.matches("selected") && (
            <Panel id="list" title="details" minWidth="500px" maxWidth="500px">
              <Box p={3}>
                <Details />
              </Box>
            </Panel>
          )}

          {/* status */}
          {selectedEvaluationState.matches("selected") && (
            <Panel id="list" title="status" minWidth="300px" maxWidth="300px">
              <Box p={3}>
                <Status />
              </Box>
            </Panel>
          )}

          {/* scope */}
          {(selectedEvaluationState.matches({ selected: "definingScope" }) ||
            selectedEvaluationState.matches({ selected: "scopeDefined" })) && (
            <Panel id="scope" title="scope" minWidth="600px" maxWidth="600px">
              <Box p={3} h={"full"}>
                <Scope />
              </Box>
            </Panel>
          )}

          {/* ratings */}
          {(selectedEvaluationState.matches({ selected: "conducting" }) ||
            selectedEvaluationState.matches({ selected: "conducted" })) && (
            <Panel
              id="ratings"
              title="beoordelingen"
              minWidth="800px"
              maxWidth="800px"
            >
              <Box p={3} h={"full"}>
                <Ratings />
              </Box>
            </Panel>
          )}
        </PanelGroup>
      </EvaluationsContext.Provider>
    </>
  );
};

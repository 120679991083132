import React, { FC, useContext } from "react";
import { groupBy, sortBy } from "lodash";
import {
  Skeleton,
  StackDivider,
  Stack,
  Heading,
  Button,
  Tag,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useRecoilValue } from "recoil";
import {
  peopleState,
  evaluationsState as evaluationsStateAtom,
} from "../../atoms";
import { EvaluationsContext } from "../../contexts/evaluations";
import { evaluationStatuses } from "../../constants";
import { AddEvaluation } from "./add-evaluation";

export const List: FC = () => {
  const { evaluationsMachine, selectedEvaluationMachine } =
    useContext(EvaluationsContext);
  const [evaluationsState] = evaluationsMachine as NonNullable<
    typeof evaluationsMachine
  >;

  const [selectedEvaluationState, selectedEvaluationSend] =
    selectedEvaluationMachine as NonNullable<typeof selectedEvaluationMachine>;

  const people = useRecoilValue(peopleState);
  const evaluations = useRecoilValue(evaluationsStateAtom);

  const evaluationsByPersonId = groupBy(
    evaluations,
    (evaluation) => evaluation.personId
  );

  const evaluationsAreLoading =
    evaluationsState.matches("pending") || evaluationsState.matches("loading");
  const evaluationsAreLoaded = evaluationsState.matches("success");
  const evaluationsHaveFailed = evaluationsState.matches("failure");

  const anEvaluationIsSelected = selectedEvaluationState.matches("selected");

  return (
    <AnimatePresence exitBeforeEnter={true}>
      {evaluationsAreLoading && (
        <motion.div
          key="loading"
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Stack divider={<StackDivider />}>
            <Skeleton h={8} />
            <Skeleton h={8} />
            <Skeleton h={8} />
          </Stack>
        </motion.div>
      )}

      {evaluationsHaveFailed && (
        <motion.div
          key="failed"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          failed ...
        </motion.div>
      )}

      {evaluationsAreLoaded && (
        <motion.div
          key="loaded"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={containerVariants}
        >
          {/* TODO: list 'active' evaluations ... (not in 'conducted' state) */}

          <Stack spacing={6}>
            <Box alignSelf="start">
              <AddEvaluation />
            </Box>

            <Stack divider={<StackDivider />} spacing={4}>
              {sortBy(Object.values(people), (person) =>
                person.name.toLowerCase()
              )
                .filter((person) => person.id in evaluationsByPersonId)
                .map((person) => (
                  <motion.div key={person.id} variants={childVariants}>
                    <Stack>
                      <Heading as="h3" size="md" fontWeight="medium">
                        {person.name}
                      </Heading>

                      <SimpleGrid columns={3} spacingY={3} spacingX={3}>
                        {[...(evaluationsByPersonId[person.id] || [])]
                          .reverse()
                          .map((evaluation) => (
                            <React.Fragment key={evaluation.id}>
                              <div>
                                {new Date(
                                  evaluation.dateTime
                                ).toLocaleDateString("nl-NL", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </div>

                              <div>
                                <Tag
                                  colorScheme={
                                    evaluationStatuses.find(
                                      (status) =>
                                        status.value === evaluation.status
                                    )?.color
                                  }
                                  size="sm"
                                >
                                  {
                                    evaluationStatuses.find(
                                      (status) =>
                                        status.value === evaluation.status
                                    )?.label
                                  }
                                </Tag>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {anEvaluationIsSelected ? (
                                  selectedEvaluationState.context
                                    .selectedEvaluationId === evaluation.id ? (
                                    <Button
                                      ml={4}
                                      size="xs"
                                      colorScheme="teal"
                                      onClick={() =>
                                        selectedEvaluationSend(
                                          "DESELECT_EVALUATION"
                                        )
                                      }
                                    >
                                      deselecteer
                                    </Button>
                                  ) : (selectedEvaluationState.matches({
                                      selected: "conducting",
                                    }) ||
                                      selectedEvaluationState.matches({
                                        selected: "conducted",
                                      })) &&
                                    (evaluation.status === "conducting" ||
                                      evaluation.status === "conducted") ? (
                                    <Button
                                      ml={4}
                                      size="xs"
                                      onClick={() => {
                                        if (
                                          selectedEvaluationState.context
                                            .selectedComparisonEvaluationId ===
                                          evaluation.id
                                        ) {
                                          selectedEvaluationSend(
                                            "DESELECT_COMPARISON_EVALUATION"
                                          );
                                        } else {
                                          selectedEvaluationSend(
                                            "SELECT_COMPARISON_EVALUATION",
                                            { evaluationId: evaluation.id }
                                          );
                                        }
                                      }}
                                    >
                                      {selectedEvaluationState.context
                                        .selectedComparisonEvaluationId ===
                                      evaluation.id
                                        ? "annuleer vergelijking"
                                        : "vergelijk"}
                                    </Button>
                                  ) : null
                                ) : (
                                  <Button
                                    ml={4}
                                    size="xs"
                                    onClick={() =>
                                      selectedEvaluationSend(
                                        "SELECT_EVALUATION",
                                        {
                                          evaluationId: evaluation.id,
                                        }
                                      )
                                    }
                                  >
                                    selecteer
                                  </Button>
                                )}
                              </div>
                            </React.Fragment>
                          ))}
                      </SimpleGrid>
                    </Stack>
                  </motion.div>
                ))}
            </Stack>
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const containerVariants: Variants = {
  hidden: {
    y: -20,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const childVariants: Variants = {
  hidden: {
    x: -20,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

import { api } from "./common";
import { Evaluation, Id } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";
import { evaluationStatuses } from "../constants";

type Props = { subscriptionId: Id; evaluationId: Id } & Partial<
  Pick<Evaluation, "personId" | "evaluatorPersonId" | "remarks">
>;

export const updateEvaluationBaseData = async (props: Props) => {
  const evaluations = await fetchEvaluations(props.subscriptionId);
  const evaluation = evaluations.evaluations[props.evaluationId];

  return api("v2/personevaluation/update", {
    method: "POST",
    body: JSON.stringify({
      // values that should be preserved:
      id: evaluation.id,
      status: evaluationStatuses.find(
        (status) => status.value === evaluation.status
      )?.apiValue,

      // values that should conditionally be updated:
      textLineRevisionIds:
        !props.personId || props.personId === evaluation.personId
          ? Object.values(evaluations.evaluations_ratings)
              .filter((rel) => rel.evaluationId === evaluation.id)
              .map((rel) => rel.textLineRevisionId)
          : [],
      personId: props.personId || evaluation.personId,
      evaluatorId: props.evaluatorPersonId || evaluation.evaluatorPersonId,
      remarks: props.remarks || evaluation.remarks,

      // values that should be updated:
      evaluationDateTimeUTC: new Date().toISOString(),
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  }).then(() => fetchEvaluations(props.subscriptionId));
};

import React, { FC } from "react";
import { Id, Person_Team } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  people_teamsState,
  teamsState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { linkPersonToTeam } from "3.0/api/link-person-to-team";

type FormData = Pick<Person_Team, "teamId">;

type Props = {
  personId: Id;
  onSuccess: () => void;
};

export const LinkPersonToTeam: FC<Props> = (props) => {
  const { personId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople_teams = useSetRecoilState(people_teamsState);
  const teams = useRecoilValue(teamsState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchTeamId = watch("teamId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        linkPersonToTeam({
          personId,
          teamId: Number(watchTeamId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople_teams(event.data.people_teams);
        onSuccess();
        toast({
          title: "koppeling tussen persoon en team tot stand gebracht",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "koppelen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.teamId !== undefined}>
          <FormLabel>team</FormLabel>
          <Select
            placeholder="kies team"
            name="teamId"
            ref={register({
              required: "je hebt geen team gekozen",
            })}
          >
            {Object.values(teams).map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.teamId?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          koppel
        </Button>
      </Stack>
    </form>
  );
};

import { api } from "./common";
import { Id, Person } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person, "name">;

type ApiResponse = {
  id: number;
};

export const addPerson = async (props: Props) => {
  const res = await api<ApiResponse>("v2/persons", {
    method: "POST",
    body: JSON.stringify({ displayName: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people } = await fetchOrgEntities(props.subscriptionId);

  return { people, newPersonId: res.id };
};

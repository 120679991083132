import React, { FC, useContext } from "react";
import { AppContext } from "../contexts/app";
import {
  Flex,
  Skeleton,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Stack,
  Link,
} from "@chakra-ui/react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useRecoilValue } from "recoil";
import {
  activeSubscriptionIdState,
  subscriptionsState,
  userState,
} from "../atoms";
import { NavLink } from "react-router-dom";

export const Nav: FC = () => {
  const user = useRecoilValue(userState);
  const subscriptions = useRecoilValue(subscriptionsState);
  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const { authMachine, userAndSubscriptionsMachine } = useContext(AppContext);

  const [authState, authSend] = authMachine as NonNullable<typeof authMachine>;

  const [
    userAndSubscriptionsState,
  ] = userAndSubscriptionsMachine as NonNullable<
    typeof userAndSubscriptionsMachine
  >;

  return (
    <Flex
      align="center"
      justify="space-between"
      px={3}
      py={2}
      borderBottomWidth={1}
      borderBottomColor="gray.200"
    >
      <Stack direction="row" align="center" spacing={4}>
        <svg viewBox="0 0 81.5 81.5" width="2rem">
          <path
            fill="#00a79d"
            d="M40.7,81.5L5.5,61.1V20.4L40.7,0L76,20.4v40.7L40.7,81.5z M13.7,56.4L40.7,72l27.1-15.6V25.1L40.7,9.5 L13.7,25.1V56.4z"
          />
          <path
            fill="#00a79d"
            d="M30.1,72.9V25.3h26.9v9.2H40.8v8.4h13.3v8.9H40.8v21.2H30.1z"
          />
        </svg>

        <nav>
          <Stack direction="row" spacing={6}>
            <NavLink to="/organisatie" activeStyle={{ color: "#00a79d" }}>
              organisatie
            </NavLink>

            <NavLink to="/evaluaties" activeStyle={{ color: "#00a79d" }}>
              evaluaties
            </NavLink>
          </Stack>
        </nav>
      </Stack>

      <Stack direction="row" spacing={4} align="center">
        <Link href="https://wiki.regelbox.nl/" isExternal>
          <Stack direction="row" spacing={2} align="center">
            <span>help</span>
            <ExternalLinkIcon />
          </Stack>
        </Link>

        <Skeleton
          w={!userAndSubscriptionsState.matches("loading") ? "initial" : 40}
          isLoaded={!userAndSubscriptionsState.matches("loading")}
        >
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              {user?.name}
            </MenuButton>

            <MenuList>
              <MenuGroup title="abonnement">
                {Object.values(subscriptions).map((subscription) => (
                  <MenuItem
                    key={subscription.id}
                    disabled={subscription.id === activeSubscriptionId}
                  >
                    {subscription.name}
                  </MenuItem>
                ))}
              </MenuGroup>

              <MenuDivider />

              <MenuGroup title="gebruiker">
                <MenuItem
                  onClick={() => authSend("LOG_OUT")}
                  disabled={authState.matches("busyLoggingOut")}
                >
                  log uit
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Skeleton>
      </Stack>
    </Flex>
  );
};

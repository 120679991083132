import React, { FC, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useMachine } from "@xstate/react";
import { authMachine } from "./machines/auth";
import { AppContext } from "./contexts/app";
import { fetchMachine } from "./machines/fetch";
import {
  userState,
  subscriptionsState,
  activeSubscriptionIdState,
  peopleState,
  scopesState,
  scopes_textLinesState,
  roleTemplatesState,
  roleTemplates_textLinesState,
  textLineRevisionsState,
  mainGroupsState,
  subGroupsState,
  subSubGroupsState,
  rolesState,
  orgUnitsState,
  functionsState,
  modelFunctionsState,
  modelFunctions_rolesState,
  modelFunctions_textLineRevisionsState,
  roles_textLineRevisionsState,
  functions_modelFunctionsState,
  functions_rolesState,
  functions_textLineRevisionsState,
  people_rolesState,
  people_functionsState,
  people_textLineRevisionsState,
  orgUnits_textLineRevisionsState,
  teamsState,
  people_teamsState,
  teams_textLineRevisionsState,
  helpTextsState,
} from "./atoms";
import { fetchUserAndSubscriptions } from "./api/fetch-user-and-subscriptions";
import { fetchContent } from "./api/fetch-content";
import { fetchOrgEntities } from "./api/fetch-org-entities";
import { fetchProfiles } from "./api/fetch-profiles";
import { HelpText, Id } from "./types";
import { Evaluations } from "./screens/evaluations";
import { Box, Flex } from "@chakra-ui/react";
import { Nav } from "./components/nav";
import { Organisation } from "./screens/organisation";
import { api } from "./api/common";
import { keyBy } from "lodash";

export const App: FC = () => {
  const setUser = useSetRecoilState(userState);
  const setSubscriptions = useSetRecoilState(subscriptionsState);
  const [activeSubscriptionId, setActiveSubscriptionId] = useRecoilState(
    activeSubscriptionIdState
  );
  const setRoles = useSetRecoilState(rolesState);
  const setRoles_textLineRevisions = useSetRecoilState(
    roles_textLineRevisionsState
  );
  const setModelFunctions = useSetRecoilState(modelFunctionsState);
  const setModelFunctions_roles = useSetRecoilState(modelFunctions_rolesState);
  const setModelFunctions_textLineRevisions = useSetRecoilState(
    modelFunctions_textLineRevisionsState
  );
  const setFunctions = useSetRecoilState(functionsState);
  const setFunctions_roles = useSetRecoilState(functions_rolesState);
  const setFunctions_modelFunctions = useSetRecoilState(
    functions_modelFunctionsState
  );
  const setFunctions_textLineRevisions = useSetRecoilState(
    functions_textLineRevisionsState
  );
  const setPeople = useSetRecoilState(peopleState);
  const setPeople_roles = useSetRecoilState(people_rolesState);
  const setPeople_functions = useSetRecoilState(people_functionsState);
  const setPeople_teams = useSetRecoilState(people_teamsState);
  const setPeople_textLineRevisions = useSetRecoilState(
    people_textLineRevisionsState
  );
  const setOrgUnits = useSetRecoilState(orgUnitsState);
  const setOrgUnits_textLineRevisions = useSetRecoilState(
    orgUnits_textLineRevisionsState
  );
  const setTeams = useSetRecoilState(teamsState);
  const setTeams_textLineRevisions = useSetRecoilState(
    teams_textLineRevisionsState
  );
  const setScopes = useSetRecoilState(scopesState);
  const setScopes_textLines = useSetRecoilState(scopes_textLinesState);
  const setRoleTemplates = useSetRecoilState(roleTemplatesState);
  const setRoleTemplates_textLines = useSetRecoilState(
    roleTemplates_textLinesState
  );
  const setTextLineRevisions = useSetRecoilState(textLineRevisionsState);
  const setMainGroups = useSetRecoilState(mainGroupsState);
  const setSubGroups = useSetRecoilState(subGroupsState);
  const setSubSubGroups = useSetRecoilState(subSubGroupsState);
  const setHelpTexts = useSetRecoilState(helpTextsState);

  useEffect(() => {
    api<HelpText[]>("v1/helptext").then((helpTexts) => {
      setHelpTexts(keyBy(helpTexts, (helpText) => helpText.tag));
    });
  }, [setHelpTexts]);

  const [authState, authSend, authService] = useMachine(authMachine);

  const [
    userAndSubscriptionsState,
    userAndSubscriptionsSend,
    userAndSubscriptionsService,
  ] = useMachine(fetchMachine, {
    services: {
      fetch: () => fetchUserAndSubscriptions(),
    },

    actions: {
      success: (_, event: any) => {
        setUser(event.data.user);
        setSubscriptions(event.data.subscriptions);
        setActiveSubscriptionId(
          (Object.values(event.data.subscriptions)[0] as any).id
        );
      },
    },
  });

  const [contentState, contentSend, contentService] = useMachine(fetchMachine, {
    services: {
      fetch: () => fetchContent(activeSubscriptionId as Id),
    },

    actions: {
      success: (_, event: any) => {
        setScopes(event.data.scopes);
        setScopes_textLines(event.data.scopes_textLines);
        setRoleTemplates(event.data.roleTemplates);
        setRoleTemplates_textLines(event.data.roleTemplates_textLines);
        setTextLineRevisions(event.data.textLineRevisions);
        setMainGroups(event.data.mainGroups);
        setSubGroups(event.data.subGroups);
        setSubSubGroups(event.data.subSubGroups);
      },
    },
  });

  const [orgEntitiesState, orgEntitiesSend, orgEntitiesService] = useMachine(
    fetchMachine,
    {
      services: {
        fetch: () => fetchOrgEntities(activeSubscriptionId as Id),
      },

      actions: {
        success: (_, event: any) => {
          setRoles(event.data.roles);
          setModelFunctions(event.data.modelFunctions);
          setModelFunctions_roles(event.data.modelFunctions_roles);
          setFunctions(event.data.functions);
          setFunctions_roles(event.data.functions_roles);
          setFunctions_modelFunctions(event.data.functions_modelFunctions);
          setOrgUnits(event.data.orgUnits);
          setTeams(event.data.teams);
          setPeople(event.data.people);
          setPeople_roles(event.data.people_roles);
          setPeople_functions(event.data.people_functions);
          setPeople_teams(event.data.people_teams);
        },
      },
    }
  );

  const [profilesState, profilesSend, profilesService] = useMachine(
    fetchMachine,
    {
      services: {
        fetch: () => fetchProfiles(activeSubscriptionId as Id),
      },

      actions: {
        success: (_, event: any) => {
          setRoles_textLineRevisions(event.data.roles_textLineRevisions);
          setModelFunctions_textLineRevisions(
            event.data.modelFunctions_textLineRevisions
          );
          setFunctions_textLineRevisions(
            event.data.functions_textLineRevisions
          );
          setOrgUnits_textLineRevisions(event.data.orgUnits_textLineRevisions);
          setPeople_textLineRevisions(event.data.people_textLineRevisions);
          setTeams_textLineRevisions(event.data.teams_textLineRevisions);
        },
      },
    }
  );

  /**
   * Fetch user and subscriptions once authenticated.
   */
  useEffect(() => {
    if (authState.matches("loggedIn")) {
      userAndSubscriptionsSend("FETCH");
    }
  }, [authState, userAndSubscriptionsSend]);

  /**
   * Fetch content, org org-entities and profiles once user and subscriptions are fetched.
   */
  useEffect(() => {
    if (userAndSubscriptionsState.matches("success")) {
      contentSend("FETCH");
      orgEntitiesSend("FETCH");
      profilesSend("FETCH");
    }
  }, [userAndSubscriptionsState, contentSend, profilesSend, orgEntitiesSend]);

  return (
    <AppContext.Provider
      value={{
        authMachine: [authState, authSend, authService],
        userAndSubscriptionsMachine: [
          userAndSubscriptionsState,
          userAndSubscriptionsSend,
          userAndSubscriptionsService,
        ],
        contentMachine: [contentState, contentSend, contentService],
        profilesMachine: [profilesState, profilesSend, profilesService],
        orgEntitiesMachine: [
          orgEntitiesState,
          orgEntitiesSend,
          orgEntitiesService,
        ],
      }}
    >
      <Router>
        <Flex direction="column" h="100vh">
          <Box as="header">
            <Nav />
          </Box>

          <Box as="main" flexGrow={1} overflowY="hidden" h="full">
            <Switch>
              <Route exact path="/">
                <Redirect to="/organisatie" />
              </Route>

              <Route path="/organisatie">
                <Organisation />
              </Route>

              <Route path="/evaluaties">
                <Evaluations />
              </Route>

              <Route>404</Route>
            </Switch>
          </Box>
        </Flex>
      </Router>
    </AppContext.Provider>
  );
};

import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  functions_rolesState,
} from "../../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { unlinkFunctionFromRole } from "../../../api/unlink-function-from-role";

type Props = {
  functionId: Id;
  roleId: Id;
  onSuccess: () => void;
};

export const UnlinkFunctionFromRole: FC<Props> = (props) => {
  const { functionId, roleId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setFunctions_roles = useSetRecoilState(functions_rolesState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        unlinkFunctionFromRole({
          functionId,
          roleId,
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setFunctions_roles(event.data.functions_roles);
        onSuccess();
        toast({
          title: "koppeling tussen functie en rol verwijderd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "verwijderen van koppeling niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
    >
      bevestig
    </Button>
  );
};

import React, { FC } from "react";
import { Id, Person_Function } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  functionsState,
  people_functionsState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { linkPersonToFunction } from "../../../api/link-person-to-function";

type FormData = Pick<Person_Function, "functionId">;

type Props = {
  personId: Id;
  onSuccess: () => void;
};

export const LinkPersonToFunction: FC<Props> = (props) => {
  const { personId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople_functions = useSetRecoilState(people_functionsState);
  const functions = useRecoilValue(functionsState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchFunctionId = watch("functionId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        linkPersonToFunction({
          personId,
          functionId: Number(watchFunctionId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople_functions(event.data.people_functions);
        onSuccess();
        toast({
          title: "koppeling tussen persoon en functie tot stand gebracht",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "koppelen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.functionId !== undefined}>
          <FormLabel>functie</FormLabel>
          <Select
            placeholder="kies functie"
            name="functionId"
            ref={register({
              required: "je hebt geen functie gekozen",
            })}
          >
            {Object.values(functions).map((func) => (
              <option key={func.id} value={func.id}>
                {func.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.functionId?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          koppel
        </Button>
      </Stack>
    </form>
  );
};

import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import {
  Stack,
  Heading,
  Checkbox,
  Spacer,
  Flex,
  Link as ChakraLink,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { rolesState } from "../../../atoms";
import { Link as ReactRouterLink, useRouteMatch } from "react-router-dom";
import { AddRole } from "./add-role";
import { sortBy } from "lodash";

export const OverviewRoles: FC = () => {
  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const roles = useRecoilValue(rolesState);

  const { url } = useRouteMatch();

  return (
    <Stack spacing={4}>
      <Flex align="center">
        <Heading as="h3" size="sm" fontWeight="medium">
          rollen
        </Heading>

        <Spacer />

        {/* add role */}
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">voeg rol toe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>rol toevoegen</PopoverHeader>
                <PopoverBody>
                  {isOpen && ( // force re-render when closed
                    <AddRole
                      onSuccess={(newRoleId) => {
                        toggleSelectOrgEntity({ type: "role", id: newRoleId });
                        onClose();
                      }}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>

      {sortBy(Object.values(roles), (role) => role.name.toLowerCase()).map(
        (role) => (
          <Flex key={role.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "role", id: role.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "role", id: role.id });
              }}
            >
              {role.name}
            </Checkbox>

            <Spacer />

            <ChakraLink as={ReactRouterLink} to={`${url}/rollen/${role.id}`}>
              details
            </ChakraLink>
          </Flex>
        )
      )}
    </Stack>
  );
};

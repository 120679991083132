/* eslint-disable */

import { createSelector } from "reselect";
import { sortBy } from "lodash";
import { AppState } from "./store";

/**
 * ===================================================
 * Types
 * ===================================================
 */

/**
 * Headings
 */

export type Role = {
  id: string;
  name: string;
};

export type Role_Textline = {
  roleId: string;
  textLineId: string;
};

export type ModelFunction = {
  id: string;
  name: string;
};

export type Modelfunction_Textline = {
  modelFunctionId: string;
  textLineId: string;
  isChecked: boolean;
};

export type Modelfunction_Role = {
  modelFunctionId: string;
  roleId: string;
};

export type Function = {
  id: string;
  name: string;
  departmentId: string;
};

export type Function_Textline = {
  functionId: string;
  textLineId: string;
  isChecked: boolean;
};

export type Function_Role = {
  functionId: string;
  roleId: string;
};

export type Function_Modelfunction = {
  functionId: string;
  modelFunctionId: string;
};

export type Department = {
  id: string;
  name: string;
  parentDepartmentId?: string;
};

export type Department_Textline = {
  departmentId: string;
  textLineId: string;
};

export type Team = {
  id: string;
  name: string;
};

export type Person = {
  id: string;
  name: string;
};

export type Person_Role = {
  personId: string;
  roleId: string;
};

export type Person_Function = {
  personId: string;
  functionId: string;
};

export type TeamMembership = {
  id: string;
  name: string;
  teamId: string;
  personId: string;
  isSuperior: boolean;
};

export type TextLine = {
  id: string;
};

export type TextLineRevision = {
  id: string;
  content: string;
  textLineId: string;
  subSubGroupId: string;
};

export type MainGroup = {
  id: string;
  name: string;
  position: number;
  displayToUser: boolean;
};

export type SubGroup = {
  id: string;
  name: string;
  mainGroupId: string;
  position: number;
  displayToUser: boolean;
};

export type SubSubGroup = {
  id: string;
  name: string;
  subGroupId: string;
  position: number;
  displayToUser: boolean;
};

export type Selection = {
  id: string;
  order: number;
};

export type Selection_Orgentity = {
  selectionId: string;
  orgEntityType: string;
  orgEntityId: string;
  order: number;
};

export type Scope = {
  id: string;
  name: string;
};

export type RoleTemplate = {
  id: string;
  name: string;
  scopeId: string;
};

export type Scope_Textline = {
  scopeId: string;
  textLineId: string;
};

export type Roletemplate_Textline = {
  roleTemplateId: string;
  textLineId: string;
};

export type Setting = {
  name: string;
  value: any;
};

/**
 * Primary keys
 */

export type RolePrimaryKey = Pick<Role, "id">;

export type Role_TextlinePrimaryKey = Pick<
  Role_Textline,
  "roleId" | "textLineId"
>;

export type ModelFunctionPrimaryKey = Pick<ModelFunction, "id">;

export type Modelfunction_TextlinePrimaryKey = Pick<
  Modelfunction_Textline,
  "modelFunctionId" | "textLineId"
>;

export type Modelfunction_RolePrimaryKey = Pick<
  Modelfunction_Role,
  "modelFunctionId" | "roleId"
>;

export type FunctionPrimaryKey = Pick<Function, "id">;

export type Function_TextlinePrimaryKey = Pick<
  Function_Textline,
  "functionId" | "textLineId"
>;

export type Function_RolePrimaryKey = Pick<
  Function_Role,
  "functionId" | "roleId"
>;

export type Function_ModelfunctionPrimaryKey = Pick<
  Function_Modelfunction,
  "functionId" | "modelFunctionId"
>;

export type DepartmentPrimaryKey = Pick<Department, "id">;

export type Department_TextlinePrimaryKey = Pick<
  Department_Textline,
  "departmentId" | "textLineId"
>;

export type TeamPrimaryKey = Pick<Team, "id">;

export type PersonPrimaryKey = Pick<Person, "id">;

export type Person_RolePrimaryKey = Pick<Person_Role, "personId" | "roleId">;

export type Person_FunctionPrimaryKey = Pick<
  Person_Function,
  "personId" | "functionId"
>;

export type TeamMembershipPrimaryKey = Pick<TeamMembership, "id">;

export type TextLinePrimaryKey = Pick<TextLine, "id">;

export type TextLineRevisionPrimaryKey = Pick<TextLineRevision, "id">;

export type MainGroupPrimaryKey = Pick<MainGroup, "id">;

export type SubGroupPrimaryKey = Pick<SubGroup, "id">;

export type SubSubGroupPrimaryKey = Pick<SubSubGroup, "id">;

export type SelectionPrimaryKey = Pick<Selection, "id">;

export type Selection_OrgentityPrimaryKey = Pick<
  Selection_Orgentity,
  "selectionId" | "orgEntityType" | "orgEntityId"
>;

export type ScopePrimaryKey = Pick<Scope, "id">;

export type RoleTemplatePrimaryKey = Pick<RoleTemplate, "id">;

export type Scope_TextlinePrimaryKey = Pick<
  Scope_Textline,
  "scopeId" | "textLineId"
>;

export type Roletemplate_TextlinePrimaryKey = Pick<
  Roletemplate_Textline,
  "roleTemplateId" | "textLineId"
>;

export type SettingPrimaryKey = Pick<Setting, "name">;

/**
 * Database
 */

export type Database = {
  roles: { [primaryIndex: string]: Role };
  role_textline: { [primaryIndex: string]: Role_Textline };
  modelFunctions: { [primaryIndex: string]: ModelFunction };
  modelfunction_textline: { [primaryIndex: string]: Modelfunction_Textline };
  modelfunction_role: { [primaryIndex: string]: Modelfunction_Role };
  functions: { [primaryIndex: string]: Function };
  function_textline: { [primaryIndex: string]: Function_Textline };
  function_role: { [primaryIndex: string]: Function_Role };
  function_modelfunction: { [primaryIndex: string]: Function_Modelfunction };
  departments: { [primaryIndex: string]: Department };
  department_textline: { [primaryIndex: string]: Department_Textline };
  teams: { [primaryIndex: string]: Team };
  people: { [primaryIndex: string]: Person };
  person_role: { [primaryIndex: string]: Person_Role };
  person_function: { [primaryIndex: string]: Person_Function };
  teamMemberships: { [primaryIndex: string]: TeamMembership };
  textLines: { [primaryIndex: string]: TextLine };
  textLineRevisions: { [primaryIndex: string]: TextLineRevision };
  mainGroups: { [primaryIndex: string]: MainGroup };
  subGroups: { [primaryIndex: string]: SubGroup };
  subSubGroups: { [primaryIndex: string]: SubSubGroup };
  selections: { [primaryIndex: string]: Selection };
  selection_orgentity: { [primaryIndex: string]: Selection_Orgentity };
  scopes: { [primaryIndex: string]: Scope };
  roleTemplates: { [primaryIndex: string]: RoleTemplate };
  scope_textline: { [primaryIndex: string]: Scope_Textline };
  roletemplate_textline: { [primaryIndex: string]: Roletemplate_Textline };
  settings: { [primaryIndex: string]: Setting };
};

/**
 * Database update action
 */

export type DatabaseUpdateAction = {
  type: string;
  payload: {
    toNextDatabase: (prevDatabase: Database) => Database;
  };
};

/**
 * ===================================================
 * Constants
 * ===================================================
 */

/**
 * Initial database
 */

export const initialDatabase = {
  roles: {} as { [primaryIndex: string]: Role },
  role_textline: {} as { [primaryIndex: string]: Role_Textline },
  modelFunctions: {} as { [primaryIndex: string]: ModelFunction },
  modelfunction_textline: {} as {
    [primaryIndex: string]: Modelfunction_Textline;
  },
  modelfunction_role: {} as { [primaryIndex: string]: Modelfunction_Role },
  functions: {} as { [primaryIndex: string]: Function },
  function_textline: {} as { [primaryIndex: string]: Function_Textline },
  function_role: {} as { [primaryIndex: string]: Function_Role },
  function_modelfunction: {} as {
    [primaryIndex: string]: Function_Modelfunction;
  },
  departments: {} as { [primaryIndex: string]: Department },
  department_textline: {} as { [primaryIndex: string]: Department_Textline },
  teams: {} as { [primaryIndex: string]: Team },
  people: {} as { [primaryIndex: string]: Person },
  person_role: {} as { [primaryIndex: string]: Person_Role },
  person_function: {} as { [primaryIndex: string]: Person_Function },
  teamMemberships: {} as { [primaryIndex: string]: TeamMembership },
  textLines: {} as { [primaryIndex: string]: TextLine },
  textLineRevisions: {} as { [primaryIndex: string]: TextLineRevision },
  mainGroups: {} as { [primaryIndex: string]: MainGroup },
  subGroups: {} as { [primaryIndex: string]: SubGroup },
  subSubGroups: {} as { [primaryIndex: string]: SubSubGroup },
  selections: {} as { [primaryIndex: string]: Selection },
  selection_orgentity: {} as { [primaryIndex: string]: Selection_Orgentity },
  scopes: {} as { [primaryIndex: string]: Scope },
  roleTemplates: {} as { [primaryIndex: string]: RoleTemplate },
  scope_textline: {} as { [primaryIndex: string]: Scope_Textline },
  roletemplate_textline: {} as {
    [primaryIndex: string]: Roletemplate_Textline;
  },
  settings: {} as { [primaryIndex: string]: Setting }
};

/**
 * ===================================================
 * Functions
 * ===================================================
 */

/**
 * Database reducer
 */

export const databaseReducer = (
  prevDatabase = initialDatabase,
  action: DatabaseUpdateAction
) => {
  if (action.payload && action.payload.toNextDatabase) {
    return action.payload.toNextDatabase(prevDatabase);
  } else {
    return prevDatabase;
  }
};

/**
 * Database update action creator
 */

export const updateDatabase = (
  description: string,
  toNextDatabase: DatabaseUpdateAction["payload"]["toNextDatabase"]
) => ({
  type: description,
  payload: {
    toNextDatabase
  }
});

/**
 * Primary indexes
 */

export const toRolePrimaryIndex = ({ id }: RolePrimaryKey) => `${id}`;

export const toRole_TextlinePrimaryIndex = ({
  roleId,
  textLineId
}: Role_TextlinePrimaryKey) => `${roleId}_${textLineId}`;

export const toModelFunctionPrimaryIndex = ({ id }: ModelFunctionPrimaryKey) =>
  `${id}`;

export const toModelfunction_TextlinePrimaryIndex = ({
  modelFunctionId,
  textLineId
}: Modelfunction_TextlinePrimaryKey) => `${modelFunctionId}_${textLineId}`;

export const toModelfunction_RolePrimaryIndex = ({
  modelFunctionId,
  roleId
}: Modelfunction_RolePrimaryKey) => `${modelFunctionId}_${roleId}`;

export const toFunctionPrimaryIndex = ({ id }: FunctionPrimaryKey) => `${id}`;

export const toFunction_TextlinePrimaryIndex = ({
  functionId,
  textLineId
}: Function_TextlinePrimaryKey) => `${functionId}_${textLineId}`;

export const toFunction_RolePrimaryIndex = ({
  functionId,
  roleId
}: Function_RolePrimaryKey) => `${functionId}_${roleId}`;

export const toFunction_ModelfunctionPrimaryIndex = ({
  functionId,
  modelFunctionId
}: Function_ModelfunctionPrimaryKey) => `${functionId}_${modelFunctionId}`;

export const toDepartmentPrimaryIndex = ({ id }: DepartmentPrimaryKey) =>
  `${id}`;

export const toDepartment_TextlinePrimaryIndex = ({
  departmentId,
  textLineId
}: Department_TextlinePrimaryKey) => `${departmentId}_${textLineId}`;

export const toTeamPrimaryIndex = ({ id }: TeamPrimaryKey) => `${id}`;

export const toPersonPrimaryIndex = ({ id }: PersonPrimaryKey) => `${id}`;

export const toPerson_RolePrimaryIndex = ({
  personId,
  roleId
}: Person_RolePrimaryKey) => `${personId}_${roleId}`;

export const toPerson_FunctionPrimaryIndex = ({
  personId,
  functionId
}: Person_FunctionPrimaryKey) => `${personId}_${functionId}`;

export const toTeamMembershipPrimaryIndex = ({
  id
}: TeamMembershipPrimaryKey) => `${id}`;

export const toTextLinePrimaryIndex = ({ id }: TextLinePrimaryKey) => `${id}`;

export const toTextLineRevisionPrimaryIndex = ({
  id
}: TextLineRevisionPrimaryKey) => `${id}`;

export const toMainGroupPrimaryIndex = ({ id }: MainGroupPrimaryKey) => `${id}`;

export const toSubGroupPrimaryIndex = ({ id }: SubGroupPrimaryKey) => `${id}`;

export const toSubSubGroupPrimaryIndex = ({ id }: SubSubGroupPrimaryKey) =>
  `${id}`;

export const toSelectionPrimaryIndex = ({ id }: SelectionPrimaryKey) => `${id}`;

export const toSelection_OrgentityPrimaryIndex = ({
  selectionId,
  orgEntityType,
  orgEntityId
}: Selection_OrgentityPrimaryKey) =>
  `${selectionId}_${orgEntityType}_${orgEntityId}`;

export const toScopePrimaryIndex = ({ id }: ScopePrimaryKey) => `${id}`;

export const toRoleTemplatePrimaryIndex = ({ id }: RoleTemplatePrimaryKey) =>
  `${id}`;

export const toScope_TextlinePrimaryIndex = ({
  scopeId,
  textLineId
}: Scope_TextlinePrimaryKey) => `${scopeId}_${textLineId}`;

export const toRoletemplate_TextlinePrimaryIndex = ({
  roleTemplateId,
  textLineId
}: Roletemplate_TextlinePrimaryKey) => `${roleTemplateId}_${textLineId}`;

export const toSettingPrimaryIndex = ({ name }: SettingPrimaryKey) => `${name}`;

/**
 * Database selector
 */

export const getDatabase = (state: AppState) => state.db;

/**
 * Base relation selectors
 */

export const getRoles = createSelector(
  [getDatabase],
  database => database.roles
);

export const getRole_Textline = createSelector(
  [getDatabase],
  database => database.role_textline
);

export const getModelFunctions = createSelector(
  [getDatabase],
  database => database.modelFunctions
);

export const getModelfunction_Textline = createSelector(
  [getDatabase],
  database => database.modelfunction_textline
);

export const getModelfunction_Role = createSelector(
  [getDatabase],
  database => database.modelfunction_role
);

export const getFunctions = createSelector(
  [getDatabase],
  database => database.functions
);

export const getFunction_Textline = createSelector(
  [getDatabase],
  database => database.function_textline
);

export const getFunction_Role = createSelector(
  [getDatabase],
  database => database.function_role
);

export const getFunction_Modelfunction = createSelector(
  [getDatabase],
  database => database.function_modelfunction
);

export const getDepartments = createSelector(
  [getDatabase],
  database => database.departments
);

export const getDepartment_Textline = createSelector(
  [getDatabase],
  database => database.department_textline
);

export const getTeams = createSelector(
  [getDatabase],
  database => database.teams
);

export const getPeople = createSelector(
  [getDatabase],
  database => database.people
);

export const getPerson_Role = createSelector(
  [getDatabase],
  database => database.person_role
);

export const getPerson_Function = createSelector(
  [getDatabase],
  database => database.person_function
);

export const getTeamMemberships = createSelector(
  [getDatabase],
  database => database.teamMemberships
);

export const getTextLines = createSelector(
  [getDatabase],
  database => database.textLines
);

export const getTextLineRevisions = createSelector(
  [getDatabase],
  database => database.textLineRevisions
);

export const getMainGroups = createSelector(
  [getDatabase],
  database => database.mainGroups
);

export const getSubGroups = createSelector(
  [getDatabase],
  database => database.subGroups
);

export const getSubSubGroups = createSelector(
  [getDatabase],
  database => database.subSubGroups
);

export const getSelections = createSelector(
  [getDatabase],
  database => database.selections
);

export const getSelection_Orgentity = createSelector(
  [getDatabase],
  database => database.selection_orgentity
);

export const getScopes = createSelector(
  [getDatabase],
  database => database.scopes
);

export const getRoleTemplates = createSelector(
  [getDatabase],
  database => database.roleTemplates
);

export const getScope_Textline = createSelector(
  [getDatabase],
  database => database.scope_textline
);

export const getRoletemplate_Textline = createSelector(
  [getDatabase],
  database => database.roletemplate_textline
);

export const getSettings = createSelector(
  [getDatabase],
  database => database.settings
);

/**
 * Sort <relationSchemaName> by <attributeName> functions
 */

export const sortRolesById = (
  relation: { [primaryKey: string]: Role } | Role[]
) => sortBy(relation, "id") as Role[];

export const sortRolesByName = (
  relation: { [primaryKey: string]: Role } | Role[]
) => sortBy(relation, "name") as Role[];

export const sortRole_TextlineByRoleId = (
  relation: { [primaryKey: string]: Role_Textline } | Role_Textline[]
) => sortBy(relation, "roleId") as Role_Textline[];

export const sortRole_TextlineByTextLineId = (
  relation: { [primaryKey: string]: Role_Textline } | Role_Textline[]
) => sortBy(relation, "textLineId") as Role_Textline[];

export const sortModelFunctionsById = (
  relation: { [primaryKey: string]: ModelFunction } | ModelFunction[]
) => sortBy(relation, "id") as ModelFunction[];

export const sortModelFunctionsByName = (
  relation: { [primaryKey: string]: ModelFunction } | ModelFunction[]
) => sortBy(relation, "name") as ModelFunction[];

export const sortModelfunction_TextlineByModelFunctionId = (
  relation:
    | { [primaryKey: string]: Modelfunction_Textline }
    | Modelfunction_Textline[]
) => sortBy(relation, "modelFunctionId") as Modelfunction_Textline[];

export const sortModelfunction_TextlineByTextLineId = (
  relation:
    | { [primaryKey: string]: Modelfunction_Textline }
    | Modelfunction_Textline[]
) => sortBy(relation, "textLineId") as Modelfunction_Textline[];

export const sortModelfunction_TextlineByIsChecked = (
  relation:
    | { [primaryKey: string]: Modelfunction_Textline }
    | Modelfunction_Textline[]
) => sortBy(relation, "isChecked") as Modelfunction_Textline[];

export const sortModelfunction_RoleByModelFunctionId = (
  relation: { [primaryKey: string]: Modelfunction_Role } | Modelfunction_Role[]
) => sortBy(relation, "modelFunctionId") as Modelfunction_Role[];

export const sortModelfunction_RoleByRoleId = (
  relation: { [primaryKey: string]: Modelfunction_Role } | Modelfunction_Role[]
) => sortBy(relation, "roleId") as Modelfunction_Role[];

export const sortFunctionsById = (
  relation: { [primaryKey: string]: Function } | Function[]
) => sortBy(relation, "id") as Function[];

export const sortFunctionsByName = (
  relation: { [primaryKey: string]: Function } | Function[]
) => sortBy(relation, "name") as Function[];

export const sortFunctionsByDepartmentId = (
  relation: { [primaryKey: string]: Function } | Function[]
) => sortBy(relation, "departmentId") as Function[];

export const sortFunction_TextlineByFunctionId = (
  relation: { [primaryKey: string]: Function_Textline } | Function_Textline[]
) => sortBy(relation, "functionId") as Function_Textline[];

export const sortFunction_TextlineByTextLineId = (
  relation: { [primaryKey: string]: Function_Textline } | Function_Textline[]
) => sortBy(relation, "textLineId") as Function_Textline[];

export const sortFunction_TextlineByIsChecked = (
  relation: { [primaryKey: string]: Function_Textline } | Function_Textline[]
) => sortBy(relation, "isChecked") as Function_Textline[];

export const sortFunction_RoleByFunctionId = (
  relation: { [primaryKey: string]: Function_Role } | Function_Role[]
) => sortBy(relation, "functionId") as Function_Role[];

export const sortFunction_RoleByRoleId = (
  relation: { [primaryKey: string]: Function_Role } | Function_Role[]
) => sortBy(relation, "roleId") as Function_Role[];

export const sortFunction_ModelfunctionByFunctionId = (
  relation:
    | { [primaryKey: string]: Function_Modelfunction }
    | Function_Modelfunction[]
) => sortBy(relation, "functionId") as Function_Modelfunction[];

export const sortFunction_ModelfunctionByModelFunctionId = (
  relation:
    | { [primaryKey: string]: Function_Modelfunction }
    | Function_Modelfunction[]
) => sortBy(relation, "modelFunctionId") as Function_Modelfunction[];

export const sortDepartmentsById = (
  relation: { [primaryKey: string]: Department } | Department[]
) => sortBy(relation, "id") as Department[];

export const sortDepartmentsByName = (
  relation: { [primaryKey: string]: Department } | Department[]
) => sortBy(relation, "name") as Department[];

export const sortDepartmentsByParentDepartmentId = (
  relation: { [primaryKey: string]: Department } | Department[]
) => sortBy(relation, "parentDepartmentId") as Department[];

export const sortDepartment_TextlineByDepartmentId = (
  relation:
    | { [primaryKey: string]: Department_Textline }
    | Department_Textline[]
) => sortBy(relation, "departmentId") as Department_Textline[];

export const sortDepartment_TextlineByTextLineId = (
  relation:
    | { [primaryKey: string]: Department_Textline }
    | Department_Textline[]
) => sortBy(relation, "textLineId") as Department_Textline[];

export const sortTeamsById = (
  relation: { [primaryKey: string]: Team } | Team[]
) => sortBy(relation, "id") as Team[];

export const sortTeamsByName = (
  relation: { [primaryKey: string]: Team } | Team[]
) => sortBy(relation, "name") as Team[];

export const sortPeopleById = (
  relation: { [primaryKey: string]: Person } | Person[]
) => sortBy(relation, "id") as Person[];

export const sortPeopleByName = (
  relation: { [primaryKey: string]: Person } | Person[]
) => sortBy(relation, "name") as Person[];

export const sortPerson_RoleByPersonId = (
  relation: { [primaryKey: string]: Person_Role } | Person_Role[]
) => sortBy(relation, "personId") as Person_Role[];

export const sortPerson_RoleByRoleId = (
  relation: { [primaryKey: string]: Person_Role } | Person_Role[]
) => sortBy(relation, "roleId") as Person_Role[];

export const sortPerson_FunctionByPersonId = (
  relation: { [primaryKey: string]: Person_Function } | Person_Function[]
) => sortBy(relation, "personId") as Person_Function[];

export const sortPerson_FunctionByFunctionId = (
  relation: { [primaryKey: string]: Person_Function } | Person_Function[]
) => sortBy(relation, "functionId") as Person_Function[];

export const sortTeamMembershipsById = (
  relation: { [primaryKey: string]: TeamMembership } | TeamMembership[]
) => sortBy(relation, "id") as TeamMembership[];

export const sortTeamMembershipsByName = (
  relation: { [primaryKey: string]: TeamMembership } | TeamMembership[]
) => sortBy(relation, "name") as TeamMembership[];

export const sortTeamMembershipsByTeamId = (
  relation: { [primaryKey: string]: TeamMembership } | TeamMembership[]
) => sortBy(relation, "teamId") as TeamMembership[];

export const sortTeamMembershipsByPersonId = (
  relation: { [primaryKey: string]: TeamMembership } | TeamMembership[]
) => sortBy(relation, "personId") as TeamMembership[];

export const sortTeamMembershipsByIsSuperior = (
  relation: { [primaryKey: string]: TeamMembership } | TeamMembership[]
) => sortBy(relation, "isSuperior") as TeamMembership[];

export const sortTextLinesById = (
  relation: { [primaryKey: string]: TextLine } | TextLine[]
) => sortBy(relation, "id") as TextLine[];

export const sortTextLineRevisionsById = (
  relation: { [primaryKey: string]: TextLineRevision } | TextLineRevision[]
) => sortBy(relation, "id") as TextLineRevision[];

export const sortTextLineRevisionsByContent = (
  relation: { [primaryKey: string]: TextLineRevision } | TextLineRevision[]
) => sortBy(relation, "content") as TextLineRevision[];

export const sortTextLineRevisionsByTextLineId = (
  relation: { [primaryKey: string]: TextLineRevision } | TextLineRevision[]
) => sortBy(relation, "textLineId") as TextLineRevision[];

export const sortTextLineRevisionsBySubSubGroupId = (
  relation: { [primaryKey: string]: TextLineRevision } | TextLineRevision[]
) => sortBy(relation, "subSubGroupId") as TextLineRevision[];

export const sortMainGroupsById = (
  relation: { [primaryKey: string]: MainGroup } | MainGroup[]
) => sortBy(relation, "id") as MainGroup[];

export const sortMainGroupsByName = (
  relation: { [primaryKey: string]: MainGroup } | MainGroup[]
) => sortBy(relation, "name") as MainGroup[];

export const sortMainGroupsByPosition = (
  relation: { [primaryKey: string]: MainGroup } | MainGroup[]
) => sortBy(relation, "position") as MainGroup[];

export const sortMainGroupsByDisplayToUser = (
  relation: { [primaryKey: string]: MainGroup } | MainGroup[]
) => sortBy(relation, "displayToUser") as MainGroup[];

export const sortSubGroupsById = (
  relation: { [primaryKey: string]: SubGroup } | SubGroup[]
) => sortBy(relation, "id") as SubGroup[];

export const sortSubGroupsByName = (
  relation: { [primaryKey: string]: SubGroup } | SubGroup[]
) => sortBy(relation, "name") as SubGroup[];

export const sortSubGroupsByMainGroupId = (
  relation: { [primaryKey: string]: SubGroup } | SubGroup[]
) => sortBy(relation, "mainGroupId") as SubGroup[];

export const sortSubGroupsByPosition = (
  relation: { [primaryKey: string]: SubGroup } | SubGroup[]
) => sortBy(relation, "position") as SubGroup[];

export const sortSubGroupsByDisplayToUser = (
  relation: { [primaryKey: string]: SubGroup } | SubGroup[]
) => sortBy(relation, "displayToUser") as SubGroup[];

export const sortSubSubGroupsById = (
  relation: { [primaryKey: string]: SubSubGroup } | SubSubGroup[]
) => sortBy(relation, "id") as SubSubGroup[];

export const sortSubSubGroupsByName = (
  relation: { [primaryKey: string]: SubSubGroup } | SubSubGroup[]
) => sortBy(relation, "name") as SubSubGroup[];

export const sortSubSubGroupsBySubGroupId = (
  relation: { [primaryKey: string]: SubSubGroup } | SubSubGroup[]
) => sortBy(relation, "subGroupId") as SubSubGroup[];

export const sortSubSubGroupsByPosition = (
  relation: { [primaryKey: string]: SubSubGroup } | SubSubGroup[]
) => sortBy(relation, "position") as SubSubGroup[];

export const sortSubSubGroupsByDisplayToUser = (
  relation: { [primaryKey: string]: SubSubGroup } | SubSubGroup[]
) => sortBy(relation, "displayToUser") as SubSubGroup[];

export const sortSelectionsById = (
  relation: { [primaryKey: string]: Selection } | Selection[]
) => sortBy(relation, "id") as Selection[];

export const sortSelectionsByOrder = (
  relation: { [primaryKey: string]: Selection } | Selection[]
) => sortBy(relation, "order") as Selection[];

export const sortSelection_OrgentityBySelectionId = (
  relation:
    | { [primaryKey: string]: Selection_Orgentity }
    | Selection_Orgentity[]
) => sortBy(relation, "selectionId") as Selection_Orgentity[];

export const sortSelection_OrgentityByOrgEntityType = (
  relation:
    | { [primaryKey: string]: Selection_Orgentity }
    | Selection_Orgentity[]
) => sortBy(relation, "orgEntityType") as Selection_Orgentity[];

export const sortSelection_OrgentityByOrgEntityId = (
  relation:
    | { [primaryKey: string]: Selection_Orgentity }
    | Selection_Orgentity[]
) => sortBy(relation, "orgEntityId") as Selection_Orgentity[];

export const sortSelection_OrgentityByOrder = (
  relation:
    | { [primaryKey: string]: Selection_Orgentity }
    | Selection_Orgentity[]
) => sortBy(relation, "order") as Selection_Orgentity[];

export const sortScopesById = (
  relation: { [primaryKey: string]: Scope } | Scope[]
) => sortBy(relation, "id") as Scope[];

export const sortScopesByName = (
  relation: { [primaryKey: string]: Scope } | Scope[]
) => sortBy(relation, "name") as Scope[];

export const sortRoleTemplatesById = (
  relation: { [primaryKey: string]: RoleTemplate } | RoleTemplate[]
) => sortBy(relation, "id") as RoleTemplate[];

export const sortRoleTemplatesByName = (
  relation: { [primaryKey: string]: RoleTemplate } | RoleTemplate[]
) => sortBy(relation, "name") as RoleTemplate[];

export const sortRoleTemplatesByScopeId = (
  relation: { [primaryKey: string]: RoleTemplate } | RoleTemplate[]
) => sortBy(relation, "scopeId") as RoleTemplate[];

export const sortScope_TextlineByScopeId = (
  relation: { [primaryKey: string]: Scope_Textline } | Scope_Textline[]
) => sortBy(relation, "scopeId") as Scope_Textline[];

export const sortScope_TextlineByTextLineId = (
  relation: { [primaryKey: string]: Scope_Textline } | Scope_Textline[]
) => sortBy(relation, "textLineId") as Scope_Textline[];

export const sortRoletemplate_TextlineByRoleTemplateId = (
  relation:
    | { [primaryKey: string]: Roletemplate_Textline }
    | Roletemplate_Textline[]
) => sortBy(relation, "roleTemplateId") as Roletemplate_Textline[];

export const sortRoletemplate_TextlineByTextLineId = (
  relation:
    | { [primaryKey: string]: Roletemplate_Textline }
    | Roletemplate_Textline[]
) => sortBy(relation, "textLineId") as Roletemplate_Textline[];

export const sortSettingsByName = (
  relation: { [primaryKey: string]: Setting } | Setting[]
) => sortBy(relation, "name") as Setting[];

export const sortSettingsByValue = (
  relation: { [primaryKey: string]: Setting } | Setting[]
) => sortBy(relation, "value") as Setting[];

import React, { FC, memo, useContext, useRef } from "react";
import { EvaluationsContext } from "../../contexts/evaluations";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
  evaluationsState,
} from "../../atoms";
import { Evaluation, Id, TextLineRevision } from "../../types";
import { Matrix } from "../../components/matrix";
import { Box, Stack, RadioGroup, Radio, Flex, Spacer } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { evaluationRatings } from "../../constants";
import { updateEvaluationRating } from "../../api/update-evaluation-rating";
import { Group } from "../../components/group";

export const Ratings: FC = () => {
  const containerRef = useRef<any>();
  const labelRef = useRef<any>();
  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const evaluations_ratings = useRecoilValue(evaluations_ratingsState);

  const textLineRevisionIds = Object.values(evaluations_ratings)
    .filter(
      (rel) =>
        rel.evaluationId ===
        selectedEvaluationState.context.selectedEvaluationId
    )
    .map((rel) => rel.textLineRevisionId);

  return (
    <Matrix
      textLineRevisionIds={textLineRevisionIds}
      renderGroupRow={(subSubGroup, index) => (
        <Flex
          borderBottomWidth={1}
          borderBottomColor="gray.200"
          alignItems="flex-end"
          px={2}
          mt={index > 0 ? 24 : 0}
        >
          <Group subSubGroup={subSubGroup} />

          <Spacer />

          <Flex ref={containerRef}>
            <Stack
              transform="rotate(90deg) translateY(100%)"
              transformOrigin="bottom right"
              spacing={8}
            >
              {evaluationRatings
                .slice()
                .reverse()
                .map((rating) => (
                  <Flex
                    key={rating.value}
                    ref={labelRef}
                    transform="rotate(-45deg)"
                    transformOrigin="bottom right"
                    align="center"
                    lineHeight={0}
                    fontSize="sm"
                    _after={{
                      content: "''",
                      borderBottom: "1px solid #eee",
                      flexGrow: 1,
                      ml: 2,
                      minW: 3,
                    }}
                    color={`${rating.color}.500`}
                  >
                    {rating.label}
                  </Flex>
                ))}
            </Stack>
          </Flex>
        </Flex>
      )}
      renderTextLineRevisionRow={(textLineRevision) => (
        <TextLineRevisionRow textLineRevision={textLineRevision} />
      )}
    />
  );
};

type TextLineRevisionRowProps = {
  textLineRevision: TextLineRevision;
};

const TextLineRevisionRow: FC<TextLineRevisionRowProps> = memo((props) => {
  const { textLineRevision } = props;

  const [updateState, updateSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setEvaluations_ratings(event.data.evaluations_ratings);
      },
    },
  });

  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const [evaluations_ratings, setEvaluations_ratings] = useRecoilState(
    evaluations_ratingsState
  );
  const evaluations = useRecoilValue(evaluationsState);
  const selectedEvaluation = evaluations[
    selectedEvaluationState.context.selectedEvaluationId as Id
  ] as Evaluation | undefined;

  const evaluation_rating = Object.values(evaluations_ratings).find(
    (rel) =>
      rel.evaluationId === selectedEvaluation?.id &&
      rel.textLineRevisionId === textLineRevision.id
  );

  const selectedComparisonEvaluation = evaluations[
    selectedEvaluationState.context.selectedComparisonEvaluationId as Id
  ] as Evaluation | undefined;

  const comparisonEvaluation_rating = Object.values(evaluations_ratings).find(
    (rel) =>
      rel.evaluationId === selectedComparisonEvaluation?.id &&
      rel.textLineRevisionId === textLineRevision.id
  );

  return (
    <Stack
      direction="row"
      align="start"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      spacing={3}
      py={2}
    >
      <Box flexGrow={1} pl={2}>
        {textLineRevision.content}
      </Box>

      <RadioGroup
        transform="translateY(0.25rem)"
        onChange={(rating) => {
          if (selectedEvaluation) {
            updateSend("UPDATE", {
              update: updateEvaluationRating({
                subscriptionId: activeSubscriptionId as Id,
                evaluationId: selectedEvaluation.id,
                textLineRevisionId: textLineRevision.id,
                rating: rating as typeof evaluationRatings[number]["value"],
              }),
            });
          }
        }}
        value={evaluation_rating?.rating}
      >
        <Stack direction="row" spacing={4}>
          {evaluationRatings.map((rating) => (
            <Box
              key={`${rating.value}-${updateState.value}`}
              borderBottomWidth={
                comparisonEvaluation_rating?.rating === rating.value ? 3 : 0
              }
              borderBottomColor="red.500"
            >
              <Radio
                value={rating.value}
                colorScheme={rating.color}
                isDisabled={
                  updateState.matches("loading") ||
                  (selectedEvaluationState.matches({ selected: "conducted" }) &&
                    evaluation_rating?.rating !== rating.value)
                }
              />
            </Box>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  );
});

import React, { FC } from "react";
import { Id } from "../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
} from "../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { clearEvaluationTextLineRevisions } from "3.0/api/clear-evaluation-text-line-revisions";

type Props = {
  targetEvaluationId: Id;
  onSuccess: () => void;
};

export const ResetScope: FC<Props> = (props) => {
  const { targetEvaluationId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setEvaluations_ratings = useSetRecoilState(evaluations_ratingsState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () => {
        return clearEvaluationTextLineRevisions({
          evaluationId: targetEvaluationId,
          subscriptionId: activeSubscriptionId as Id,
        });
      },
    },

    actions: {
      success: (_, event: any) => {
        setEvaluations_ratings(event.data.evaluations_ratings);
        onSuccess();
        toast({
          title: "scope gereset",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "scope resetten niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
      colorScheme="red"
    >
      reset
    </Button>
  );
};

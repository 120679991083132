import { api } from "./common";
import {
  Id,
  Evaluation,
  Evaluation_Rating,
  evaluation_ratingKey,
} from "../types";
import { keyBy, flatMap } from "lodash";
import { evaluationRatings, evaluationStatuses } from "../constants";

type ApiResponse = {
  id: number;
  status: typeof evaluationStatuses[number]["apiValue"];
  remarks: string | null;
  evaluator: {
    id: number;
  } | null;
  person: {
    id: number;
  };
  evaluationDateTimeUTC: string;
  textlines: {
    textlineRevisionId: number;
    score: typeof evaluationRatings[number]["apiValue"];
  }[];
}[];

type TransformedData = {
  evaluations: Record<Id, Evaluation>;
  evaluations_ratings: Record<Id, Evaluation_Rating>;
};

export const fetchEvaluations = async (
  subscriptionId: Id
): Promise<TransformedData> => {
  const evaluations = await api<ApiResponse>("v1/init/personevaluations", {
    headers: {
      subscriptionid: subscriptionId.toString(),
    },
  });

  return {
    evaluations: keyBy(
      evaluations
        // temp until API is fixed

        .map(
          ({
            id,
            person,
            evaluator,
            remarks,
            status: apiStatus,
            evaluationDateTimeUTC: dateTime,
          }) => ({
            id,
            dateTime,
            personId: person.id,
            evaluatorPersonId: evaluator?.id,
            status:
              evaluationStatuses.find((status) => status.apiValue === apiStatus)
                ?.value || "definingScope",
            remarks: remarks || "",
          })
        ),
      (evaluation) => evaluation.id
    ),

    evaluations_ratings: keyBy(
      flatMap(evaluations, (evaluation) =>
        evaluation.textlines.map((textLine) => ({
          evaluationId: evaluation.id,
          textLineRevisionId: textLine.textlineRevisionId,
          rating:
            evaluationRatings.find(
              (rating) => rating.apiValue === textLine.score
            )?.value || "unknown",
        }))
      ),
      evaluation_ratingKey
    ),
  };
};

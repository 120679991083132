import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  activeSubscriptionIdState,
  functionsState,
  orgUnitsState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { updateFunctionOrgUnit } from "../../../api/update-function-org-unit";

type FormData = { orgUnitId?: Id };

type Props = {
  id: Id;
  onSuccess: (newOrgUnitId: Id) => void;
};

export const UpdateFunctionOrgUnit: FC<Props> = (props) => {
  const { id } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const orgUnits = useRecoilValue(orgUnitsState);

  const [functions, setFunctions] = useRecoilState(functionsState);
  const func = functions[id];

  const { register, handleSubmit, watch } = useForm<FormData>({
    defaultValues: { orgUnitId: func.orgUnitId },
  });
  const watchOrgUnitId = watch("orgUnitId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        updateFunctionOrgUnit({
          id: props.id,
          orgUnitId: Number(watchOrgUnitId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setFunctions(event.data.functions);
        toast({
          title: "organisatie-eenheid voor functie aangepast",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "aanpassen van organisatie-eenheid voor functie niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl>
          <FormLabel>organisatie-eenheid</FormLabel>
          <Select
            placeholder="kies organisatie-eenheid"
            name="orgUnitId"
            ref={register()}
          >
            {Object.values(orgUnits).map((orgUnit) => (
              <option key={orgUnit.id} value={orgUnit.id}>
                {orgUnit.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          pas aan
        </Button>
      </Stack>
    </form>
  );
};

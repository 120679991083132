import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import {
  Stack,
  Heading,
  Checkbox,
  Spacer,
  Flex,
  Link as ChakraLink,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { teamsState } from "../../../atoms";
import { Link as ReactRouterLink, useRouteMatch } from "react-router-dom";
import { AddTeam } from "./add-team";
import { sortBy } from "lodash";

export const OverviewTeams: FC = () => {
  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const teams = useRecoilValue(teamsState);

  const { url } = useRouteMatch();

  return (
    <Stack spacing={4}>
      <Flex align="center">
        <Heading as="h3" size="sm" fontWeight="medium">
          teams
        </Heading>

        <Spacer />

        {/* add team */}
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">voeg team toe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>team toevoegen</PopoverHeader>
                <PopoverBody>
                  {isOpen && ( // force re-render when closed
                    <AddTeam
                      onSuccess={(newTeamId) => {
                        toggleSelectOrgEntity({ type: "team", id: newTeamId });
                        onClose();
                      }}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>

      {sortBy(Object.values(teams), (team) => team.name.toLowerCase()).map(
        (team) => (
          <Flex key={team.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "team", id: team.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "team", id: team.id });
              }}
            >
              {team.name}
            </Checkbox>

            <Spacer />

            <ChakraLink as={ReactRouterLink} to={`${url}/teams/${team.id}`}>
              details
            </ChakraLink>
          </Flex>
        )
      )}
    </Stack>
  );
};

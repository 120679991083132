import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  functions_rolesState,
  functionsState,
  modelFunctions_rolesState,
  modelFunctionsState,
  people_rolesState,
  peopleState,
  rolesState,
} from "../../../atoms";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Tooltip,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { DeleteRole } from "./delete-role";
import { RenameRole } from "./rename-role";
import { InfoIcon } from "@chakra-ui/icons";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { PDF } from "./pdf";

export const Role: FC = () => {
  const { goBack } = useHistory();
  const { id: rawId } = useParams<{id: string}>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const role = useRecoilValue(rolesState)[id];

  const people = useRecoilValue(peopleState);
  const linkedPeople = Object.values(useRecoilValue(people_rolesState))
    .filter((rel) => rel.roleId === id)
    .map((rel) => people[rel.personId]);

  const functions = useRecoilValue(functionsState);
  const linkedFunctions = Object.values(useRecoilValue(functions_rolesState))
    .filter((rel) => rel.roleId === id)
    .map((rel) => functions[rel.functionId]);

  const modelFunctions = useRecoilValue(modelFunctionsState);
  const linkedModelFunctions = Object.values(
    useRecoilValue(modelFunctions_rolesState)
  )
    .filter((rel) => rel.roleId === id)
    .map((rel) => modelFunctions[rel.modelFunctionId]);

  if (!role) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {role.name}
        </Heading>

        <PDF
          orgEntityType="role"
          orgEntityId={role.id}
          orgEntityName={role.name}
        />

        {/* delete role */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder rol</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>rol verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeleteRole
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename role */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenameRole
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* linked people */}
      <Stack spacing={4}>
        <Stack direction="row" align="center">
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde personen
          </Heading>

          <Tooltip
            hasArrow={true}
            label="Dit overzicht bevat alleen rechtstreekse koppelingen. De rol is indirect (via modelfuncties en functies) mogelijk aan andere personen gekoppeld."
          >
            <InfoIcon boxSize={4} />
          </Tooltip>
        </Stack>

        {linkedPeople.map((person) => (
          <Flex key={person.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "person", id: person.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "person", id: person.id });
              }}
              alignItems="baseline"
            >
              {person.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/personen/${person.id}`}
            >
              details
            </ChakraLink>
          </Flex>
        ))}
      </Stack>

      {/* linked functions */}
      <Stack spacing={4}>
        <Stack direction="row" align="center">
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde functies
          </Heading>

          <Tooltip
            hasArrow={true}
            label="Dit overzicht bevat alleen rechtstreekse koppelingen. De rol is indirect (via modelfuncties) mogelijk aan andere functies gekoppeld."
          >
            <InfoIcon boxSize={4} />
          </Tooltip>
        </Stack>

        {linkedFunctions.map((func) => (
          <Flex key={func.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "function", id: func.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "function", id: func.id });
              }}
              alignItems="baseline"
            >
              {func.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/functies/${func.id}`}
            >
              details
            </ChakraLink>
          </Flex>
        ))}
      </Stack>

      {/* linked model functions */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          gekoppelde modelfuncties
        </Heading>

        {linkedModelFunctions.map((modelFunction) => (
          <Flex key={modelFunction.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "modelFunction",
                id: modelFunction.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({
                  type: "modelFunction",
                  id: modelFunction.id,
                });
              }}
              alignItems="baseline"
            >
              {modelFunction.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/modelfuncties/${modelFunction.id}`}
            >
              details
            </ChakraLink>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};

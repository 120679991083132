import { createContext, Dispatch, SetStateAction } from "react";
import { Id } from "../types";
import { OrgEntityType } from "3.0/selectors";
import { SubjectsFilter, TpgFilter } from "../screens/organisation";

export type OrganisationContextShape = {
  selectedOrgEntityIds: Id[];
  setSelectedOrgEntityIds: Dispatch<SetStateAction<Id[]>>;
  orgEntityIsSelected: (props: { type: OrgEntityType; id: Id }) => boolean;
  toggleSelectOrgEntity: (props: { type: OrgEntityType; id: Id }) => void;

  keywordFilter: string;
  setKeywordFilter: Dispatch<SetStateAction<string>>;

  tpgFilter: TpgFilter;
  setTpgFilter: Dispatch<SetStateAction<TpgFilter>>;

  subjectsFilter: SubjectsFilter;
  setSubjectsFilter: Dispatch<SetStateAction<SubjectsFilter>>;
};

export const OrganisationContext = createContext<
  Partial<OrganisationContextShape>
>({});

import { api } from "./common";
import { Id } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id; id: Id; parentOrgUnitId?: Id };

export const updateParentOrgUnit = async (props: Props) => {
  const { orgUnits: currentOrgUnits } = await fetchOrgEntities(
    props.subscriptionId
  );
  const orgUnit = currentOrgUnits[props.id];

  await api("v2/departments", {
    method: "POST",
    body: JSON.stringify({
      id: props.id,
      name: orgUnit.name,
      displayName: orgUnit.name,
      ...(props.parentOrgUnitId
        ? { parentDepartmentId: props.parentOrgUnitId }
        : {}),
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { orgUnits } = await fetchOrgEntities(props.subscriptionId);

  return { orgUnits };
};

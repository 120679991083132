import { api } from "./common";
import { Id, Person_Role } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person_Role, "personId" | "roleId">;

export const linkPersonToRole = async (props: Props) => {
  await api("v2/persons/roles", {
    method: "POST",
    body: JSON.stringify({
      personId: props.personId,
      roleId: props.roleId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people_roles } = await fetchOrgEntities(props.subscriptionId);

  return { people_roles };
};

import { api } from "./common";
import { Id, ModelFunction_Role } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<
  ModelFunction_Role,
  "modelFunctionId" | "roleId"
>;

export const unlinkModelFunctionFromRole = async (props: Props) => {
  await api(`v2/modelfunctions/roles`, {
    method: "DELETE",
    body: JSON.stringify({
      modelFunctionId: props.modelFunctionId,
      roleId: props.roleId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { modelFunctions_roles } = await fetchOrgEntities(props.subscriptionId);

  return { modelFunctions_roles };
};

import React, {
  FC,
  useState,
  CSSProperties,
  Children,
  isValidElement,
} from "react";
import { Box, Flex, Stack, Button } from "@chakra-ui/react";

export const PanelGroup: FC = (props) => {
  const { children } = props;

  return (
    <Flex h={"full"}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return null;

        return <Box>{child}</Box>;
      })}
    </Flex>
  );
};

type PanelProps = {
  id: string;
  title: string;

  // todo: just compose ChakraBox, probably
  minWidth: CSSProperties["minWidth"];
  maxWidth: CSSProperties["maxWidth"];
};

export const Panel: FC<PanelProps> = (props) => {
  const { title, children } = props;

  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return (
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        h="full"
        w={6}
        p={0}
        minW={0}
        bg="gray.50"
        borderRightWidth={1}
        borderRightColor={"gray.200"}
        alignItems="flex-start"
        pt={2}
        borderRadius={0}
      >
        <Box sx={{ writingMode: "vertical-lr" }} fontSize="sm">
          {title}
        </Box>
      </Button>
    );
  }

  return (
    <Stack
      h="full"
      spacing={1}
      minW={props.minWidth}
      maxW={props.maxWidth}
      flexGrow={1}
      borderRightWidth={1}
      borderRightColor={"gray.200"}
    >
      <Button
        bgColor="gray.50"
        borderBottomWidth={1}
        flexShrink={0}
        h={6}
        borderRadius={0}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <Stack direction="row" spacing={2} align="center">
          <Box fontSize="sm">{title}</Box>
        </Stack>
      </Button>

      <Box h="full" overflowX="auto">
        {children}
      </Box>
    </Stack>
  );
};

import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    persianGreen: "#00a79d",
  },

  components: {
    Link: {
      baseStyle: {
        color: "persianGreen",
      },
    },
  },

  styles: {
    global: {
      body: {
        overflow: "hidden",
        h: "100vh",
      },
    },
  },
});

import React, { FC } from "react";
import { useRecoilValue } from "recoil";
import { helpTextsState } from "../atoms";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  IconButton,
  Stack,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";

type Props = {
  tag: string;
};

export const HelpText: FC<Props> = (props) => {
  const { tag } = props;

  const helpTexts = useRecoilValue(helpTextsState);
  const helpText = tag in helpTexts ? helpTexts[tag] : undefined;

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="bekijk helptekst"
          icon={<InfoIcon />}
          size="xs"
          isRound={true}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack spacing={4}>
            <div
              dangerouslySetInnerHTML={{ __html: helpText?.content || "" }}
            />

            {helpText?.externalLink && (
              <Link href={helpText.externalLink} isExternal>
                <Stack direction="row" spacing={2} align="center">
                  <span>lees meer</span>
                  <ExternalLinkIcon />
                </Stack>
              </Link>
            )}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

import React, { FC } from "react";
import { Stack, StackDivider } from "@chakra-ui/react";
import { OverviewRoles } from "./overview-roles";
import { OverviewPeople } from "./overview-people";
import { OverviewModelFunctions } from "./overview-model-functions";
import { OverviewOrgUnits } from "./overview-org-units";
import { OverviewFunctions } from "./overview-functions";
import { OverviewTeams } from "./overview-teams";

export const Overview: FC = () => {
  return (
    <Stack divider={<StackDivider />} spacing={6}>
      {/* people */}
      <OverviewPeople />

      {/* teams */}
      <OverviewTeams />

      {/* org units */}
      <OverviewOrgUnits />

      {/* functions */}
      <OverviewFunctions />

      {/* model functions */}
      <OverviewModelFunctions />

      <OverviewRoles />
    </Stack>
  );
};

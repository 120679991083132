import { api } from "./common";
import { Id, Person } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person, "id" | "name">;

export const renamePerson = async (props: Props) => {
  await api("v2/persons", {
    method: "POST",
    body: JSON.stringify({ id: props.id, displayName: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people } = await fetchOrgEntities(props.subscriptionId);

  return { people };
};

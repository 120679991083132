import React, { FC } from "react";
import { RecoilRoot } from "recoil";
import { App } from "./app";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { Helmet } from "react-helmet";

export const Main: FC = () => {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <RecoilRoot>
        <Helmet titleTemplate="%s - Regelbox" />

        <App />
      </RecoilRoot>
    </ChakraProvider>
  );
};

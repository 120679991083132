import { api } from "./common";
import { Id } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";
import { evaluationStatuses } from "../constants";

type Props = {
  subscriptionId: Id;
  evaluationId: Id;
};

export const clearEvaluationTextLineRevisions = async (props: Props) => {
  const evaluations = await fetchEvaluations(props.subscriptionId);
  const evaluation = evaluations.evaluations[props.evaluationId];

  return api("v2/personevaluation/update", {
    method: "POST",
    body: JSON.stringify({
      // values that should be preserved:
      id: evaluation.id,
      status: evaluationStatuses.find(
        (status) => status.value === evaluation.status
      )?.apiValue,
      personId: evaluation.personId,
      evaluatorId: evaluation.evaluatorPersonId,
      remarks: evaluation.remarks,
      evaluationDateTimeUTC: evaluation.dateTime,

      // values that should be updated:
      textLineRevisionIds: [],
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  }).then(() => fetchEvaluations(props.subscriptionId));
};

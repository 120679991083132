import { atom } from "recoil";
import {
  Evaluation,
  Evaluation_Rating,
  Id,
  MainGroup,
  ModelFunction,
  ModelFunction_Role,
  Person,
  Person_TextLineRevision,
  Role,
  RoleTemplate,
  RoleTemplate_TextLine,
  Scope,
  Scope_TextLine,
  SubGroup,
  Subscription,
  SubSubGroup,
  TextLineRevision,
  User,
  Function,
  Function_Role,
  Function_ModelFunction,
  Person_Role,
  Person_Function,
  OrgUnit,
  Function_TextLineRevision,
  ModelFunction_TextLineRevision,
  Role_TextLineRevision,
  OrgUnit_TextLineRevision,
  Team,
  Team_TextLineRevision,
  Person_Team,
  HelpText,
} from "./types";

export const userState = atom<User | undefined>({
  key: "user",
  default: undefined,
});

export const subscriptionsState = atom<Record<Id, Subscription>>({
  key: "subscriptions",
  default: {},
});

export const activeSubscriptionIdState = atom<Id | undefined>({
  key: "active-subscription-id",
  default: undefined,
});

export const rolesState = atom<Record<Id, Role>>({
  key: "roles",
  default: {},
});

export const roles_textLineRevisionsState = atom<
  Record<Id, Role_TextLineRevision>
>({
  key: "roles_textLineRevisions",
  default: {},
});

export const modelFunctionsState = atom<Record<Id, ModelFunction>>({
  key: "modelFunctions",
  default: {},
});

export const modelFunctions_rolesState = atom<Record<Id, ModelFunction_Role>>({
  key: "modelFunctions_roles",
  default: {},
});

export const modelFunctions_textLineRevisionsState = atom<
  Record<Id, ModelFunction_TextLineRevision>
>({
  key: "modelFunctions_textLineRevisions",
  default: {},
});

export const functionsState = atom<Record<Id, Function>>({
  key: "functions",
  default: {},
});

export const functions_rolesState = atom<Record<Id, Function_Role>>({
  key: "functions_roles",
  default: {},
});

export const functions_modelFunctionsState = atom<
  Record<Id, Function_ModelFunction>
>({
  key: "functions_modelFunctions",
  default: {},
});

export const functions_textLineRevisionsState = atom<
  Record<Id, Function_TextLineRevision>
>({
  key: "functions_textLineRevisions",
  default: {},
});

export const teamsState = atom<Record<Id, Team>>({
  key: "teams",
  default: {},
});

export const teams_textLineRevisionsState = atom<
  Record<Id, Team_TextLineRevision>
>({
  key: "teams_textLineRevisions",
  default: {},
});

export const people_teamsState = atom<Record<Id, Person_Team>>({
  key: "people_teams",
  default: {},
});

export const peopleState = atom<Record<Id, Person>>({
  key: "people",
  default: {},
});

export const people_rolesState = atom<Record<Id, Person_Role>>({
  key: "people_roles",
  default: {},
});

export const people_functionsState = atom<Record<Id, Person_Function>>({
  key: "people_functions",
  default: {},
});

export const people_textLineRevisionsState = atom<
  Record<Id, Person_TextLineRevision>
>({
  key: "people_textLineRevisions",
  default: {},
});

export const orgUnitsState = atom<Record<Id, OrgUnit>>({
  key: "orgUnits",
  default: {},
});

export const orgUnits_textLineRevisionsState = atom<
  Record<Id, OrgUnit_TextLineRevision>
>({
  key: "orgUnits_textLineRevisions",
  default: {},
});

export const evaluationsState = atom<Record<Id, Evaluation>>({
  key: "evaluations",
  default: {},
});

export const evaluations_ratingsState = atom<Record<Id, Evaluation_Rating>>({
  key: "evaluations_ratings",
  default: {},
});

export const textLineRevisionsState = atom<Record<Id, TextLineRevision>>({
  key: "textLineRevisions",
  default: {},
});

export const mainGroupsState = atom<Record<Id, MainGroup>>({
  key: "mainGroups",
  default: {},
});

export const subGroupsState = atom<Record<Id, SubGroup>>({
  key: "subGroups",
  default: {},
});

export const subSubGroupsState = atom<Record<Id, SubSubGroup>>({
  key: "subSubGroups",
  default: {},
});

export const scopesState = atom<Record<Id, Scope>>({
  key: "scopes",
  default: {},
});

export const scopes_textLinesState = atom<Record<Id, Scope_TextLine>>({
  key: "scopes_textLines",
  default: {},
});

export const roleTemplatesState = atom<Record<Id, RoleTemplate>>({
  key: "roleTemplates",
  default: {},
});

export const roleTemplates_textLinesState = atom<
  Record<Id, RoleTemplate_TextLine>
>({
  key: "roleTemplates_textLines",
  default: {},
});

export const helpTextsState = atom<Record<string, HelpText>>({
  key: "helpTexts",
  default: {},
});

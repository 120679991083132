import { api } from "./common";
import { Id, Team } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Team, "id" | "name">;

export const renameTeam = async (props: Props) => {
  await api("v2/teams", {
    method: "POST",
    body: JSON.stringify({ id: props.id, name: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { teams } = await fetchOrgEntities(props.subscriptionId);

  return { teams };
};

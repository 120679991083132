import React, { FC, ReactNode, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import {
  Stack,
  Heading,
  Checkbox,
  Spacer,
  Flex,
  Link as ChakraLink,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Box,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { orgUnitsState } from "../../../atoms";
import { Link as ReactRouterLink, useRouteMatch } from "react-router-dom";
import { AddOrgUnit } from "./add-org-unit";
import { Id } from "../../../types";
import { sortBy } from "lodash";

export const OverviewOrgUnits: FC = () => {
  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const orgUnits = useRecoilValue(orgUnitsState);

  const { url } = useRouteMatch();

  return (
    <Stack spacing={4}>
      <Flex align="center">
        <Heading as="h3" size="sm" fontWeight="medium">
          organisatie-eenheden
        </Heading>

        <Spacer />

        {/* add org unit */}
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">voeg organisatie-eenheid toe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>organisatie-eenheid toevoegen</PopoverHeader>
                <PopoverBody>
                  {isOpen && ( // force re-render when closed
                    <AddOrgUnit
                      onSuccess={(newOrgUnitId) => {
                        toggleSelectOrgEntity({
                          type: "orgUnit",
                          id: newOrgUnitId,
                        });
                        onClose();
                      }}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>

      {(function renderOrgUnits(props: {
        parentId?: Id;
        depth: number;
      }): ReactNode {
        return (
          <>
            {sortBy(Object.values(orgUnits), (orgUnit) =>
              orgUnit.name.toLowerCase()
            )
              .filter((orgUnit) => orgUnit.parentOrgUnitId === props.parentId)
              .map((orgUnit) => (
                <React.Fragment key={orgUnit.id}>
                  <Flex align="center">
                    <Checkbox
                      isChecked={orgEntityIsSelected({
                        type: "orgUnit",
                        id: orgUnit.id,
                      })}
                      onChange={() => {
                        toggleSelectOrgEntity({
                          type: "orgUnit",
                          id: orgUnit.id,
                        });
                      }}
                    >
                      {props.depth > 0 ? (
                        <Stack direction="row" align="baseline">
                          <Box
                            w={`${props.depth * 0.75}rem`}
                            borderBottomWidth={1}
                            alignSelf="center"
                          />
                          <Box>{orgUnit.name}</Box>
                        </Stack>
                      ) : (
                        orgUnit.name
                      )}
                    </Checkbox>

                    <Spacer />

                    <ChakraLink
                      as={ReactRouterLink}
                      to={`${url}/organisatie-eenheden/${orgUnit.id}`}
                    >
                      details
                    </ChakraLink>
                  </Flex>

                  {renderOrgUnits({
                    parentId: orgUnit.id,
                    depth: props.depth + 1,
                  })}
                </React.Fragment>
              ))}
          </>
        );
      })({ depth: 0 })}
    </Stack>
  );
};

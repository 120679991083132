import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  functions_modelFunctionsState,
  functionsState,
  modelFunctionsState,
  rolesState,
  modelFunctions_rolesState,
} from "../../../atoms";
import {
  Button,
  Checkbox,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Link as ChakraLink,
  Flex,
} from "@chakra-ui/react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { RenameModelFunction } from "./rename-model-function";
import { DeleteModelFunction } from "./delete-model-function";
import { LinkModelFunctionToRole } from "./link-model-function-to-role";
import { UnlinkModelFunctionFromRole } from "./unlink-model-function-from-role";
import { PDF } from "./pdf";

export const ModelFunction: FC = () => {
  const { goBack } = useHistory();
  const { id: rawId } = useParams<{ id: string }>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const modelFunctions = useRecoilValue(modelFunctionsState);
  const modelFunction = modelFunctions[id];

  const functions = useRecoilValue(functionsState);
  const linkedFunctions = Object.values(
    useRecoilValue(functions_modelFunctionsState)
  )
    .filter((rel) => rel.modelFunctionId === id)
    .map((rel) => functions[rel.functionId]);

  const roles = useRecoilValue(rolesState);
  const linkedRoles = Object.values(useRecoilValue(modelFunctions_rolesState))
    .filter((rel) => rel.modelFunctionId === id)
    .map((rel) => roles[rel.roleId]);

  if (!modelFunction) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {modelFunction.name}
        </Heading>

        <PDF
          orgEntityType="modelFunction"
          orgEntityId={modelFunction.id}
          orgEntityName={modelFunction.name}
        />

        {/* delete model function */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder modelfunctie</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>modelfunctie verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeleteModelFunction
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename model function */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenameModelFunction
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* linked functions */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          gekoppelde functies
        </Heading>

        {linkedFunctions.map((func) => (
          <Stack direction="row" key={func.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "function",
                id: func.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "function", id: func.id });
              }}
              alignItems="baseline"
            >
              {func.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/functies/${func.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked roles */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde rollen
          </Heading>

          <Spacer />

          {/* link role */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">koppel rol</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>rol koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkModelFunctionToRole
                        modelFunctionId={modelFunction.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedRoles.map((role) => (
          <Stack direction="row" key={role.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "role",
                id: role.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({
                  type: "role",
                  id: role.id,
                });
              }}
              alignItems="baseline"
            >
              {role.name}
            </Checkbox>

            <Spacer />

            {/* unlink role */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>rol ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkModelFunctionFromRole
                          modelFunctionId={modelFunction.id}
                          roleId={role.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/rollen/${role.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

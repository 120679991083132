import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import {
  Stack,
  Heading,
  Checkbox,
  Spacer,
  Flex,
  Link as ChakraLink,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { modelFunctionsState } from "../../../atoms";
import { Link as ReactRouterLink, useRouteMatch } from "react-router-dom";
import { AddModelFunction } from "./add-model-function";
import { sortBy } from "lodash";

export const OverviewModelFunctions: FC = () => {
  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const modelFunctions = useRecoilValue(modelFunctionsState);

  const { url } = useRouteMatch();

  return (
    <Stack spacing={4}>
      <Flex align="center">
        <Heading as="h3" size="sm" fontWeight="medium">
          modelfuncties
        </Heading>

        <Spacer />

        {/* add model function */}
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">voeg modelfunctie toe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>modelfunctie toevoegen</PopoverHeader>
                <PopoverBody>
                  {isOpen && ( // force re-render when closed
                    <AddModelFunction
                      onSuccess={(newModelFunctionId) => {
                        toggleSelectOrgEntity({
                          type: "modelFunction",
                          id: newModelFunctionId,
                        });
                        onClose();
                      }}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>

      {sortBy(Object.values(modelFunctions), (modelFunction) =>
        modelFunction.name.toLowerCase()
      ).map((modelFunction) => (
        <Flex key={modelFunction.id} align="center">
          <Checkbox
            isChecked={orgEntityIsSelected({
              type: "modelFunction",
              id: modelFunction.id,
            })}
            onChange={() => {
              toggleSelectOrgEntity({
                type: "modelFunction",
                id: modelFunction.id,
              });
            }}
          >
            {modelFunction.name}
          </Checkbox>

          <Spacer />

          <ChakraLink
            as={ReactRouterLink}
            to={`${url}/modelfuncties/${modelFunction.id}`}
          >
            details
          </ChakraLink>
        </Flex>
      ))}
    </Stack>
  );
};

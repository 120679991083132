import { assign, Machine } from "xstate";
import { Id } from "../types";

export type SelectedEvaluationContext = {
  selectedEvaluationId?: Id;
  selectedComparisonEvaluationId?: Id;
};

// export type SelectedEvaluationStateSchema = {
//   statuses: {};
// };

export type SelectedEvaluationEvent =
  | { type: "SELECT_EVALUATION"; evaluationId: Id }
  | { type: "DESELECT_EVALUATION" }
  | { type: "SELECT_COMPARISON_EVALUATION"; evaluationId: Id }
  | { type: "DESELECT_COMPARISON_EVALUATION" }
  | { type: "DEFINING_SCOPE" }
  | { type: "SCOPE_DEFINED" }
  | { type: "CONDUCTING" }
  | { type: "CONDUCTED" }
  | { type: "NEXT" };

export const selectedEvaluationMachine = Machine<
  SelectedEvaluationContext,
  any,
  SelectedEvaluationEvent
>({
  strict: true,
  id: "selected-evaluation",
  context: {
    selectedEvaluationId: undefined,
    selectedComparisonEvaluationId: undefined,
  },
  initial: "idle",
  states: {
    idle: {
      on: {
        SELECT_EVALUATION: {
          target: "selected",
          actions: assign({
            selectedEvaluationId: (_, event) => event.evaluationId,
          }),
        },
      },
    },
    selected: {
      on: {
        DESELECT_EVALUATION: {
          target: "idle",
          actions: assign({
            selectedComparisonEvaluationId: (_, event) => undefined,
          }),
        },
        SELECT_COMPARISON_EVALUATION: {
          actions: assign({
            selectedComparisonEvaluationId: (_, event) => event.evaluationId,
          }),
        },
        DESELECT_COMPARISON_EVALUATION: {
          actions: assign({
            selectedComparisonEvaluationId: (_, event) => undefined,
          }),
        },
      },
      initial: "pending",
      states: {
        pending: {
          on: {
            DEFINING_SCOPE: "definingScope",
            SCOPE_DEFINED: "scopeDefined",
            CONDUCTING: "conducting",
            CONDUCTED: "conducted",
          },
        },
        definingScope: {
          on: {
            NEXT: "scopeDefined",
          },
        },
        scopeDefined: {
          on: {
            NEXT: "conducting",
          },
        },
        conducting: {
          on: {
            NEXT: "conducted",
          },
        },
        conducted: {
          type: "final",
        },
      },
    },
  },
});

import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeSubscriptionIdState, orgUnitsState } from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { addOrgUnit } from "../../../api/add-org-unit";

type FormData = { name: string; parentOrgUnitId?: Id };

type Props = {
  onSuccess: (newOrgUnitId: Id) => void;
};

export const AddOrgUnit: FC<Props> = (props) => {
  const { onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const [orgUnits, setOrgUnits] = useRecoilState(orgUnitsState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchName = watch("name");
  const watchParentOrgUnitId = watch("parentOrgUnitId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        addOrgUnit({
          name: watchName,
          parentOrgUnitId: Number(watchParentOrgUnitId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setOrgUnits(event.data.orgUnits);
        onSuccess(event.data.newOrgUnitId);
        toast({
          title: "organisatie-eenheid toegevoegd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "toevoegen van organisatie-eenheid niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.name !== undefined}>
          <FormLabel>naam</FormLabel>
          <Input
            name="name"
            ref={register({
              required: "je hebt geen naam ingevuld",
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>bovenliggende organisatie-eenheid</FormLabel>
          <Select
            placeholder="kies bovenliggende organisatie-eenheid"
            name="parentOrgUnitId"
            ref={register()}
          >
            {Object.values(orgUnits).map((orgUnit) => (
              <option key={orgUnit.id} value={orgUnit.id}>
                {orgUnit.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          voeg toe
        </Button>
      </Stack>
    </form>
  );
};

import { api } from "./common";
import { Id, Person } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person, "id">;

export const deletePerson = async (props: Props) => {
  await api(`v2/persons/${props.id}`, {
    method: "DELETE",
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people } = await fetchOrgEntities(props.subscriptionId);

  return { people };
};

import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  functionsState,
  people_functionsState,
  people_rolesState,
  people_teamsState,
  peopleState,
  rolesState,
  teamsState,
} from "../../../atoms";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Link as ChakraLink,
} from "@chakra-ui/react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { RenamePerson } from "./rename-person";
import { DeletePerson } from "./delete-person";
import { LinkPersonToFunction } from "./link-person-to-function";
import { UnlinkPersonFromFunction } from "./unlink-person-from-function";
import { LinkPersonToRole } from "./link-person-to-role";
import { UnlinkPersonFromRole } from "./unlink-person-from-role";
import { LinkPersonToTeam } from "./link-person-to-team";
import { UnlinkPersonFromTeam } from "./unlink-person-from-team";
import { PDF } from "./pdf";

export const Person: FC = () => {
  const { goBack } = useHistory();

  const { id: rawId } = useParams<{ id: string }>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const person = useRecoilValue(peopleState)[id];

  const functions = useRecoilValue(functionsState);
  const linkedFunctions = Object.values(useRecoilValue(people_functionsState))
    .filter((rel) => rel.personId === id)
    .map((rel) => functions[rel.functionId]);

  const roles = useRecoilValue(rolesState);
  const linkedRoles = Object.values(useRecoilValue(people_rolesState))
    .filter((rel) => rel.personId === id)
    .map((rel) => roles[rel.roleId]);

  const teams = useRecoilValue(teamsState);
  const linkedTeams = Object.values(useRecoilValue(people_teamsState))
    .filter((rel) => rel.personId === id)
    .map((rel) => teams[rel.teamId]);

  if (!person) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {person.name}
        </Heading>

        <PDF
          orgEntityType="person"
          orgEntityId={person.id}
          orgEntityName={person.name}
        />

        {/* delete person */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder persoon</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>persoon verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeletePerson
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename person */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenamePerson
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* linked functions */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde functies
          </Heading>

          <Spacer />

          {/* link function */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">koppel functie</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>functie koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkPersonToFunction
                        personId={person.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedFunctions.map((func) => (
          <Stack direction="row" key={func.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "function", id: func.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "function", id: func.id });
              }}
              alignItems="baseline"
            >
              {func.name}
            </Checkbox>

            <Spacer />

            {/* unlink function */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>functie ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkPersonFromFunction
                          personId={person.id}
                          functionId={func.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/functies/${func.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked roles */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde rollen
          </Heading>

          <Spacer />

          {/* link role */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">koppel rol</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>rol koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkPersonToRole
                        personId={person.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedRoles.map((role) => (
          <Stack direction="row" key={role.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "role", id: role.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "role", id: role.id });
              }}
              alignItems="baseline"
            >
              {role.name}
            </Checkbox>

            <Spacer />

            {/* unlink role */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>rol ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkPersonFromRole
                          personId={person.id}
                          roleId={role.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/rollen/${role.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked teams */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            teamlidmaatschappen
          </Heading>

          <Spacer />

          {/* link role */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">maak lid van team</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>team koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkPersonToTeam
                        personId={person.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedTeams.map((team) => (
          <Stack direction="row" key={team.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "team", id: team.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "team", id: team.id });
              }}
              alignItems="baseline"
            >
              {team.name}
            </Checkbox>

            <Spacer />

            {/* unlink role */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>team ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkPersonFromTeam
                          personId={person.id}
                          teamId={team.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/teams/${team.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

import { merge } from "lodash";
import { env } from "../constants";
import { userManager } from "../user-manager";

const settings = {
  dev: {
    apiRoot: "https://ap-regelbox-api-test.azurewebsites.net/api/",
  },

  test: {
    apiRoot: "https://ap-regelbox-api-test.azurewebsites.net/api/",
  },

  production: {
    apiRoot: "https://api.regelbox.nl/api/",
  },
};

export const api = async <T>(
  endpoint: string,
  customInit?: RequestInit
): Promise<T> => {
  const user = await userManager.getUser();

  const init: RequestInit = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${settings[env].apiRoot}${endpoint}`,
    merge(customInit, init) as RequestInit
  );

  let { data, errors } = await response.json();

  if (response.ok) {
    return data;
  } else {
    return Promise.reject(errors);
  }
};

import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  people_functionsState,
} from "../../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { unlinkPersonFromFunction } from "../../../api/unlink-person-from-function";

type Props = {
  personId: Id;
  functionId: Id;
  onSuccess: () => void;
};

export const UnlinkPersonFromFunction: FC<Props> = (props) => {
  const { personId, functionId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople_functions = useSetRecoilState(people_functionsState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        unlinkPersonFromFunction({
          personId,
          functionId,
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople_functions(event.data.people_functions);
        onSuccess();
        toast({
          title: "koppeling tussen persoon en functie verwijderd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "verwijderen van koppeling niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
    >
      bevestig
    </Button>
  );
};

import { api } from "./common";
import { Id } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id; id: Id; orgUnitId?: Id };

export const updateFunctionOrgUnit = async (props: Props) => {
  const { functions: currentFunctions } = await fetchOrgEntities(
    props.subscriptionId
  );
  const func = currentFunctions[props.id];

  await api("v2/functions", {
    method: "POST",
    body: JSON.stringify({
      id: props.id,
      name: func.name,
      departmentId: props.orgUnitId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { functions } = await fetchOrgEntities(props.subscriptionId);

  return { functions };
};

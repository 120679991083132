import { api } from "./common";
import { Function_ModelFunction, Id } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<
  Function_ModelFunction,
  "functionId" | "modelFunctionId"
>;

export const unlinkFunctionFromModelFunction = async (props: Props) => {
  await api(`v2/functions/modelfunctions`, {
    method: "DELETE",
    body: JSON.stringify({
      functionId: props.functionId,
      modelFunctionId: props.modelFunctionId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { functions_modelFunctions } = await fetchOrgEntities(
    props.subscriptionId
  );

  return { functions_modelFunctions };
};

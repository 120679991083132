import { api } from "./common";
import { Evaluation, Id } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";
import { evaluationStatuses } from "../constants";

type Props = { subscriptionId: Id; evaluationId: Id } & Pick<
  Evaluation,
  "status"
>;

export const updateEvaluationStatus = async (props: Props) => {
  const evaluations = await fetchEvaluations(props.subscriptionId);
  const evaluation = evaluations.evaluations[props.evaluationId];

  return api("v2/personevaluation/update", {
    method: "POST",
    body: JSON.stringify({
      // values that should be preserved:
      id: evaluation.id,
      personId: evaluation.personId,
      evaluatorId: evaluation.evaluatorPersonId,
      remarks: evaluation.remarks,
      textLineRevisionIds: Object.values(evaluations.evaluations_ratings)
        .filter((rel) => rel.evaluationId === evaluation.id)
        .map((rel) => rel.textLineRevisionId),

      // values that should be updated:
      evaluationDateTimeUTC: new Date().toISOString(),
      status: evaluationStatuses.find((status) => status.value === props.status)
        ?.apiValue,
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  }).then(() => fetchEvaluations(props.subscriptionId));
};

import React, { FC, memo } from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  latestTextLineRevisionByTextLineIdState,
  orgEntities_textLineStatusesState,
  OrgEntityType,
  sortedMainGroupsState,
  sortedSubGroupsState,
  sortedSubSubGroupsState,
} from "../../../selectors";
import { Id } from "../../../types";
import { useRecoilValue } from "recoil";
import { subGroupsState, subSubGroupsState } from "../../../atoms";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  Box,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

type Props = {
  orgEntityType: OrgEntityType;
  orgEntityId: Id;
  orgEntityName: string;
};

export const PDF: FC<Props> = memo((props) => {
  const { orgEntityId, orgEntityType, orgEntityName } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const sortedMainGroups = useRecoilValue(sortedMainGroupsState);
  const sortedSubGroups = useRecoilValue(sortedSubGroupsState);
  const sortedSubSubGroups = useRecoilValue(sortedSubSubGroupsState);
  const subGroups = useRecoilValue(subGroupsState);
  const subSubGroups = useRecoilValue(subSubGroupsState);
  const latestTextLineRevisionByTextLineId = useRecoilValue(
    latestTextLineRevisionByTextLineIdState
  );
  const orgEntities_textLineStatuses = useRecoilValue(
    orgEntities_textLineStatusesState
  );

  const isReady =
    Object.values(latestTextLineRevisionByTextLineId).length > 0 &&
    Object.values(subGroups).length > 0 &&
    Object.values(subSubGroups).length > 0;

  const button = (
    <Button
      size="xs"
      isLoading={!isReady}
      loadingText="PDF wordt geladen..."
      onClick={() => {
        onOpen();
      }}
    >
      download PDF
    </Button>
  );

  if (!isReady) {
    return <>{button}</>;
  }

  const checkedTextLineRevisions = Object.values(orgEntities_textLineStatuses)
    .filter(
      (rel) =>
        rel.orgEntityType === orgEntityType &&
        rel.orgEntityId === orgEntityId &&
        (rel.calculatedStatus === "checkedInSubProfile" ||
          rel.calculatedStatus === "checkedInOwnProfile")
    )
    .map((rel) => latestTextLineRevisionByTextLineId[rel.textLineId]);

  const withGroupsIds = checkedTextLineRevisions.map((textLineRevision) => ({
    ...textLineRevision,
    subGroupId: subSubGroups[textLineRevision.subSubGroupId].subGroupId,
    mainGroupId:
      subGroups[subSubGroups[textLineRevision.subSubGroupId].subGroupId]
        .mainGroupId,
  }));

  const document = (
    <Document>
      <Page
        style={{
          paddingLeft: 50,
          paddingRight: 50,
          paddingTop: 30,
          paddingBottom: 30,
          fontSize: 10,
          fontFamily: "Helvetica",
        }}
      >
        <Text
          style={{
            fontSize: 10,
            marginBottom: 20,
            textAlign: "center",
            color: "grey",
          }}
          fixed
        >
          {orgEntityName} ({new Date().getDate()}-{new Date().getMonth() + 1}-
          {new Date().getFullYear()})
        </Text>

        {sortedMainGroups
          .filter((mainGroup) =>
            withGroupsIds.some(
              (textLineRevision) =>
                textLineRevision.mainGroupId === mainGroup.id
            )
          )
          .map((mainGroup, i) => (
            <React.Fragment key={mainGroup.id}>
              <Text
                style={{
                  fontFamily: "Helvetica-Bold",
                  marginBottom: 3,
                  ...(i > 0 && { marginTop: 10 }),
                }}
              >
                {mainGroup.name}
              </Text>

              {sortedSubGroups
                .filter(
                  (subGroup) =>
                    subGroup.mainGroupId === mainGroup.id &&
                    withGroupsIds.some(
                      (textLineRevision) =>
                        textLineRevision.subGroupId === subGroup.id
                    )
                )
                .map((subGroup, i) => (
                  <React.Fragment key={subGroup.id}>
                    {subGroup.isDisplayed && (
                      <Text
                        style={{
                          fontFamily: "Helvetica-Oblique",
                          marginBottom: 3,
                          marginTop: i > 0 ? 8 : 0,
                        }}
                      >
                        {subGroup.name}
                      </Text>
                    )}
                    {sortedSubSubGroups
                      .filter(
                        (subSubGroup) =>
                          subSubGroup.subGroupId === subGroup.id &&
                          withGroupsIds.some(
                            (textLineRevision) =>
                              textLineRevision.subSubGroupId === subSubGroup.id
                          )
                      )
                      .map((subSubGroup, i) => (
                        <React.Fragment key={subSubGroup.id}>
                          <View
                            wrap={false}
                            style={{ marginTop: i > 0 ? 8 : 0 }}
                          >
                            {subSubGroup.isDisplayed && (
                              <Text
                                style={{
                                  marginBottom: 5,
                                }}
                              >
                                {subSubGroup.name}
                              </Text>
                            )}
                            <View>
                              {withGroupsIds
                                .filter(
                                  (textLineRevision) =>
                                    textLineRevision.subSubGroupId ===
                                    subSubGroup.id
                                )
                                .map((textLineRevision) => (
                                  <View
                                    key={textLineRevision.id}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Text style={{ marginRight: 10 }}>-</Text>
                                    <Text
                                      style={{
                                        marginBottom: 2,
                                        paddingBottom: 2,
                                      }}
                                    >
                                      {textLineRevision.content}
                                    </Text>
                                  </View>
                                ))}
                            </View>
                          </View>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        <Text
          style={{
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>PDF</DrawerHeader>
            <DrawerBody>
              <Stack h="full" align="flex-start">
                <PDFDownloadLink
                  document={document}
                  fileName={`${orgEntityName} ${new Date().getDate()}-${
                    new Date().getMonth() + 1
                  }-${new Date().getFullYear()}.pdf`}
                >
                  {({ loading }) => (
                    <Button
                      isLoading={loading}
                      loadingText="PDF wordt geladen..."
                      rightIcon={<DownloadIcon />}
                    >
                      download
                    </Button>
                  )}
                </PDFDownloadLink>

                <Box flexGrow={1} alignSelf="stretch">
                  <PDFViewer height="100%" width="100%">
                    {document}
                  </PDFViewer>
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {button}
    </>
  );
});

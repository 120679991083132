import { api } from "./common";
import { Evaluation, Id } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";

type Props = { subscriptionId: Id } & Pick<
  Evaluation,
  "personId" | "evaluatorPersonId"
>;

type ApiResponse = {
  id: number;
};

export const addEvaluation = async (props: Props) => {
  const res = await api<ApiResponse>("v2/personevaluation", {
    method: "POST",
    body: JSON.stringify({
      personId: props.personId,
      evaluatorId: props.evaluatorPersonId,
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  });

  const evaluations = await fetchEvaluations(props.subscriptionId);

  return { ...evaluations, newEvaluationId: res.id };
};

import { createContext } from "react";
import { Machine } from "../types";
import { AuthEvent, AuthStateSchema } from "../machines/auth";
import { FetchEvent, FetchStateSchema } from "../machines/fetch";

export type AppContextShape = {
  authMachine: Machine<{}, AuthStateSchema, AuthEvent>;
  userAndSubscriptionsMachine: Machine<{}, FetchStateSchema, FetchEvent>;
  contentMachine: Machine<{}, FetchStateSchema, FetchEvent>;
  orgEntitiesMachine: Machine<{}, FetchStateSchema, FetchEvent>;
  profilesMachine: Machine<{}, FetchStateSchema, FetchEvent>;
};

export const AppContext = createContext<Partial<AppContextShape>>({});

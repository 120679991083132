import React, { FC } from "react";
import { Id } from "../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluationsState,
  evaluations_ratingsState,
} from "../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { deleteEvaluation } from "../../api/delete-evaluation";

type Props = {
  id: Id;
  onSuccess: () => void;
};

export const DeleteEvaluation: FC<Props> = (props) => {
  const { id, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setEvaluations = useSetRecoilState(evaluationsState);
  const setEvaluations_ratings = useSetRecoilState(evaluations_ratingsState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () => {
        return deleteEvaluation({
          id,
          subscriptionId: activeSubscriptionId as Id,
        });
      },
    },

    actions: {
      success: (_, event: any) => {
        setEvaluations(event.data.evaluations);
        setEvaluations_ratings(event.data.evaluations_ratings);
        onSuccess();
        toast({
          title: "evaluatie verwijderd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "evaluatie verwijderen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
      colorScheme="red"
    >
      verwijder
    </Button>
  );
};

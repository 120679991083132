import React, { FC, useContext } from "react";
import {
  Evaluation,
  evaluation_ratingKey,
  Id,
  TextLineRevision,
} from "../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
  evaluationsState,
} from "../../atoms";
import { EvaluationsContext } from "../../contexts/evaluations";
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { people_textLineRevisionStatusesState } from "../../selectors";
import { Matrix } from "../../components/matrix";
import { toggleEvaluationTextLineRevision } from "../../api/toggle-evaluation-text-line";
import { Group } from "../../components/group";
import { CopyFromScope } from "./copy-from-scope";
import { ResetScope } from "./reset-scope";
import { SelectAllTextLineRevisions } from "./select-all-text-line-revisions";

export const Scope: FC = () => {
  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const evaluations = useRecoilValue(evaluationsState);
  const selectedEvaluation = evaluations[
    selectedEvaluationState.context.selectedEvaluationId as Id
  ] as Evaluation | undefined;

  const people_textLineRevisionStatuses = useRecoilValue(
    people_textLineRevisionStatusesState
  );

  const textLineRevisionIds = Object.values(people_textLineRevisionStatuses)
    .filter(
      (rel) =>
        rel.personId === selectedEvaluation?.personId &&
        (rel.status === "checkedInOwnProfile" ||
          rel.status === "checkedInSubProfile")
    )
    .map((rel) => rel.textLineRevisionId);

  return (
    <Stack spacing={4} h="full">
      <Stack direction="row">
        <Popover placement="right-start">
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">neem scope over</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>scope overnemen</PopoverHeader>
                <PopoverBody>
                  {isOpen &&
                    selectedEvaluation && ( // force re-render when closed
                      <CopyFromScope
                        targetEvaluationId={selectedEvaluation.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>

        <Popover placement="right-start">
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">selecteer alles</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>alles selecteren</PopoverHeader>
                <PopoverBody>
                  {isOpen &&
                    selectedEvaluation && ( // force re-render when closed
                      <SelectAllTextLineRevisions
                        targetEvaluationId={selectedEvaluation.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>

        <Popover placement="right-start">
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">reset scope</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>scope resetten</PopoverHeader>
                <PopoverBody>
                  {isOpen &&
                    selectedEvaluation && ( // force re-render when closed
                      <ResetScope
                        targetEvaluationId={selectedEvaluation.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Stack>
      <Box flexGrow={1}>
        <Matrix
          textLineRevisionIds={textLineRevisionIds}
          renderGroupRow={(subSubGroup, index) => {
            return (
              <Box mt={index > 0 ? 24 : 0}>
                <Group subSubGroup={subSubGroup} />
              </Box>
            );
          }}
          renderTextLineRevisionRow={(textLineRevision) => (
            <TextLineRevisionRow textLineRevision={textLineRevision} />
          )}
        />
      </Box>
    </Stack>
  );
};

type TextLineRevisionRowProps = {
  textLineRevision: TextLineRevision;
};

const TextLineRevisionRow: FC<TextLineRevisionRowProps> = (props) => {
  const { textLineRevision } = props;

  const [updateState, updateSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setEvaluations_ratings(event.data.evaluations_ratings);
      },
    },
  });

  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const [evaluations_ratings, setEvaluations_ratings] = useRecoilState(
    evaluations_ratingsState
  );
  const evaluations = useRecoilValue(evaluationsState);
  const selectedEvaluation =
    evaluations[selectedEvaluationState.context.selectedEvaluationId as Id];

  const isChecked =
    evaluation_ratingKey({
      textLineRevisionId: textLineRevision.id,
      evaluationId: selectedEvaluation.id,
    }) in evaluations_ratings;

  return (
    <Stack
      direction="row"
      align="baseline"
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      py={2}
      spacing={3}
    >
      <Switch
        key={textLineRevision.id}
        size="sm"
        isDisabled={
          updateState.matches("loading") ||
          selectedEvaluationState.matches({ selected: "scopeDefined" })
        }
        id={String(textLineRevision.id)}
        onChange={(e) => {
          e.target.blur();

          updateSend("UPDATE", {
            update: toggleEvaluationTextLineRevision({
              subscriptionId: activeSubscriptionId as Id,
              evaluationId: selectedEvaluation.id,
              textLineRevisionId: textLineRevision.id,
            }),
          });
        }}
        isChecked={isChecked}
      />
      <Box
        as="label"
        flexGrow={1}
        htmlFor={String(textLineRevision.id)}
        cursor="pointer"
      >
        {textLineRevision.content}
      </Box>
    </Stack>
  );
};

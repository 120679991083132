import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeSubscriptionIdState, teamsState } from "../../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { deleteTeam } from "3.0/api/delete-team";

type Props = {
  id: Id;
  onSuccess: () => void;
};

export const DeleteTeam: FC<Props> = (props) => {
  const { id, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setTeams = useSetRecoilState(teamsState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        deleteTeam({ id, subscriptionId: activeSubscriptionId as Id }),
    },

    actions: {
      success: (_, event: any) => {
        setTeams(event.data.teams);
        onSuccess();
        toast({
          title: "team verwijderd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "verwijderen van team niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
    >
      bevestig
    </Button>
  );
};

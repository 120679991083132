import { api } from "./common";
import { Id, Function } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Function, "id" | "name">;

export const renameFunction = async (props: Props) => {
  const { functions: currentFunctions } = await fetchOrgEntities(
    props.subscriptionId
  );
  const func = currentFunctions[props.id];

  await api("v2/functions", {
    method: "POST",
    body: JSON.stringify({
      id: props.id,
      name: props.name,
      departmentId: func.orgUnitId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { functions } = await fetchOrgEntities(props.subscriptionId);

  return { functions };
};

import { UserManager } from "oidc-client";
import { env } from "./constants";

const settings = {
  dev: {
    authority: "https://idp-test.regelbox.nl/",
    redirect_uri: "http://localhost:3000/callback.html",
    post_logout_redirect_uri: "http://localhost:3000/index.html",
  },

  test: {
    authority: "https://idp-test.regelbox.nl/",
    redirect_uri: "https://app-test.regelbox.nl/callback.html",
    post_logout_redirect_uri: "https://app-test.regelbox.nl/index.html",
  },

  production: {
    authority: "https://idp.regelbox.nl",
    redirect_uri: "https://app.regelbox.nl/callback.html",
    post_logout_redirect_uri: "https://app.regelbox.nl/index.html",
  },
};

export const userManager = new UserManager({
  ...settings[env],
  automaticSilentRenew: true,
  client_id: "js_app",
  response_type: "id_token token",
  scope: "openid email api1",
});

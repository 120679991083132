import { Machine } from "xstate";
import { userManager } from "../user-manager";

export type AuthStateSchema = {
  states: {
    pending: {};
    loggedIn: {};
    busyLoggingIn: {};
    busyLoggingOut: {};
  };
};

export type AuthEvent =
  | { type: "USER_FOUND" }
  | { type: "USER_NOT_FOUND" }
  | { type: "LOG_OUT" };

export const authMachine = Machine<{}, AuthStateSchema, AuthEvent>({
  strict: true,
  id: "auth",
  initial: "pending",
  states: {
    pending: {
      invoke: {
        id: "getUser",
        src: () => {
          return async (callback) => {
            const user = await userManager.getUser();

            if (user && !user.expired) {
              callback("USER_FOUND");
            } else {
              callback("USER_NOT_FOUND");
            }
          };
        },
      },
      on: {
        USER_FOUND: {
          target: "loggedIn",
        },
        USER_NOT_FOUND: "busyLoggingIn",
      },
    },

    loggedIn: {
      on: {
        LOG_OUT: "busyLoggingOut",
      },
    },

    busyLoggingIn: {
      entry: () => userManager.signinRedirect(),
      type: "final",
    },

    busyLoggingOut: {
      entry: () => userManager.signoutRedirect(),
      type: "final",
    },
  },
});

import React, { FC, useContext } from "react";
import {
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { Evaluation, Id } from "../../types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluationsState,
  peopleState,
} from "../../atoms";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { addEvaluation } from "../../api/add-evaluation";
import { EvaluationsContext } from "../../contexts/evaluations";

type FormData = Pick<Evaluation, "personId" | "evaluatorPersonId" | "remarks">;

export const AddEvaluation: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateState, updateSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setEvaluations(event.data.evaluations);
        onClose();
        selectedEvaluationSend("SELECT_EVALUATION", {
          evaluationId: event.data.newEvaluationId,
        });
      },
    },
  });

  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [
    selectedEvaluationState,
    selectedEvaluationSend,
  ] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setEvaluations = useSetRecoilState(evaluationsState);
  const people = useRecoilValue(peopleState);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "all",
  });

  const onSubmit = (data: FormData) => {
    updateSend("UPDATE", {
      update: addEvaluation({
        subscriptionId: activeSubscriptionId as Id,
        personId: Number(data.personId),
        evaluatorPersonId: Number(data.evaluatorPersonId),
      }),
    });
  };

  return (
    <>
      <Button
        size="sm"
        leftIcon={<AddIcon />}
        onClick={onOpen}
        isDisabled={!selectedEvaluationState.matches("idle")}
      >
        voeg evaluatie toe
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>evaluatie toevoegen</ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <Stack spacing={8} align="start">
                  {/* person id */}
                  <FormControl
                    isRequired
                    isInvalid={errors.personId !== undefined}
                  >
                    <FormLabel>beoordeelde</FormLabel>
                    <Select
                      placeholder="kies beoordeelde"
                      name="personId"
                      ref={register({
                        required: "je hebt geen beoordeelde gekozen",
                      })}
                    >
                      {Object.values(people).map((person) => (
                        <option key={person.id} value={person.id}>
                          {person.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.personId?.message}
                    </FormErrorMessage>
                  </FormControl>

                  {/* evaluator person id */}
                  <FormControl
                    isRequired
                    isInvalid={errors.evaluatorPersonId !== undefined}
                  >
                    <FormLabel>beoordelaar</FormLabel>
                    <Select
                      placeholder="kies beoordelaar"
                      name="evaluatorPersonId"
                      ref={register({
                        required: "je hebt geen beoordelaar gekozen",
                      })}
                    >
                      {Object.values(people).map((person) => (
                        <option key={person.id} value={person.id}>
                          {person.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.evaluatorPersonId?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  isLoading={updateState.matches("loading")}
                >
                  voeg evaluatie toe
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

/**
 * Environment
 */

export const env = (() => {
  const hostname = window.location.hostname;

  if (hostname === "localhost") {
    return "dev";
  }

  if (
    hostname === "app.regelbox.nl" ||
    hostname === "regelbox.azurewebsites.net"
  ) {
    return "production";
  }

  return "test";
})();

export const evaluationRatings = [
  {
    value: "inadequate",
    label: "onvoldoende",
    apiValue: 1,
    color: "red",
  },
  {
    value: "belowExpectation",
    label: "beneden verwachting",
    apiValue: 2,
    color: "yellow",
  },
  {
    value: "asExpected",
    label: "naar verwachting",
    apiValue: 3,
    color: "teal",
  },
  {
    value: "overqualified",
    label: "overgekwalificeerd",
    apiValue: 4,
    color: "purple",
  },
  {
    value: "unknown",
    label: "nog niet beoordeeld",
    apiValue: 0,
    color: "cyan",
  },
  {
    value: "cannotBeEvaluated",
    label: "is niet te beoordelen",
    apiValue: 6,
    color: "gray",
  },
] as const;

export const evaluationStatuses = [
  {
    value: "definingScope",
    label: "scope bepalen",
    apiValue: 0,
    color: "yellow",
  },
  {
    value: "scopeDefined",
    label: "scope bepaald",
    apiValue: 1,
    color: "purple",
  },
  {
    value: "conducting",
    label: "in uitvoering",
    apiValue: 2,
    color: "cyan",
  },
  {
    value: "conducted",
    label: "uitgevoerd",
    apiValue: 3,
    color: "green",
  },
] as const;

import { api } from "./common";
import { Id } from "../types";
import { fetchProfiles } from "./fetch-profiles";

type Props = {
  subscriptionId: Id;
  orgEntityId: Id;
  textLineId: Id;
} & (
  | {
      orgEntityType: "person";
      status: "checked" | "unchecked" | "inherited";
    }
  | {
      orgEntityType: "function";
      status: "checked" | "unchecked" | "inherited";
    }
  | {
      orgEntityType: "modelFunction";
      status: "checked" | "unchecked" | "inherited";
    }
  | {
      orgEntityType: "role";
      status: "checked" | "notChecked";
    }
  | {
      orgEntityType: "orgUnit";
      status: "checked" | "notChecked";
    }
  | {
      orgEntityType: "team";
      status: "checked" | "notChecked";
    }
);

export const updateOrgEntityTextLine = async (props: Props) => {
  const method =
    props.status === "checked" || props.status === "unchecked"
      ? "POST"
      : "DELETE";

  const endpoints = {
    person: "persons",
    function: "functions",
    modelFunction: "modelfunctions",
    role: "roles",
    orgUnit: "departments",
    team: "teams",
  };

  return api(
    `v2/${endpoints[props.orgEntityType]}/${props.orgEntityId}/textlines/${
      props.textLineId
    }`,
    {
      method,

      ...(method === "POST" &&
      (props.orgEntityType === "person" ||
        props.orgEntityType === "function" ||
        props.orgEntityType === "modelFunction")
        ? { body: JSON.stringify({ check: props.status === "checked" }) }
        : {}),

      headers: {
        subscriptionid: props.subscriptionId.toString(),
      },
    }
  ).then(() => fetchProfiles(props.subscriptionId));
};

import React, { FC } from "react";
import { Function_ModelFunction, Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  functions_modelFunctionsState,
  modelFunctionsState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { linkFunctionToModelFunction } from "../../../api/link-function-to-model-function";

type FormData = Pick<Function_ModelFunction, "modelFunctionId">;

type Props = {
  functionId: Id;
  onSuccess: () => void;
};

export const LinkFunctionToModelFunction: FC<Props> = (props) => {
  const { functionId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setFunctions_modelFunctions = useSetRecoilState(
    functions_modelFunctionsState
  );
  const modelFunctions = useRecoilValue(modelFunctionsState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchModelFunctionId = watch("modelFunctionId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        linkFunctionToModelFunction({
          functionId,
          modelFunctionId: Number(watchModelFunctionId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setFunctions_modelFunctions(event.data.functions_modelFunctions);
        onSuccess();
        toast({
          title: "koppeling tussen functie en modelfunctie tot stand gebracht",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "koppelen niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl
          isRequired
          isInvalid={errors.modelFunctionId !== undefined}
        >
          <FormLabel>modelfunctie</FormLabel>
          <Select
            placeholder="kies modelfunctie"
            name="modelFunctionId"
            ref={register({
              required: "je hebt geen modelfunctie gekozen",
            })}
          >
            {Object.values(modelFunctions).map((modelFunction) => (
              <option key={modelFunction.id} value={modelFunction.id}>
                {modelFunction.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.modelFunctionId?.message}</FormErrorMessage>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          koppel
        </Button>
      </Stack>
    </form>
  );
};

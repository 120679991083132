import React, { FC } from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { SubSubGroup } from "../types";
import { useRecoilValue } from "recoil";
import { mainGroupsState, subGroupsState } from "../atoms";

type Props = {
  subSubGroup: SubSubGroup;
};

export const Group: FC<Props> = (props) => {
  const { subSubGroup } = props;

  const subGroup = useRecoilValue(subGroupsState)[subSubGroup.subGroupId];
  const mainGroup = useRecoilValue(mainGroupsState)[subGroup.mainGroupId];

  return (
    <Box fontWeight="medium" pb={2} opacity={0.75}>
      <Box>{mainGroup.name}</Box>

      {subGroup.isDisplayed && (
        <Stack spacing={2} direction="row" align="flex-start" ml={3} mt={1}>
          <Flex align="flex-end">
            <Box h={4} borderLeftWidth={1} borderColor="blackAlpha.400" />
            <Box w={2} borderBottomWidth={1} borderColor="blackAlpha.400" />
          </Flex>
          <Box transform="translateY(2px)">{subGroup.name}</Box>
        </Stack>
      )}

      {subSubGroup.isDisplayed && (
        <Stack
          spacing={2}
          direction="row"
          align="flex-start"
          ml={subGroup.isDisplayed ? 10 : 3}
          mt={1}
        >
          <Flex align="flex-end">
            <Box h={4} borderLeftWidth={1} borderColor="blackAlpha.400" />
            <Box w={2} borderBottomWidth={1} borderColor="blackAlpha.400" />
          </Flex>
          <Box transform="translateY(2px)">{subSubGroup.name}</Box>
        </Stack>
      )}
    </Box>
  );
};

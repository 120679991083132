import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Id, SubSubGroup, TextLineRevision } from "../types";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { useRecoilValue } from "recoil";
import { sortedSubSubGroupsState } from "../selectors";
import { textLineRevisionsState } from "../atoms";
import { flatMap, groupBy } from "lodash";

type Props = {
  textLineRevisionIds: Id[];
  renderGroupRow: (subSubGroup: SubSubGroup, index: number) => ReactElement;
  renderTextLineRevisionRow: (
    textLineRevision: TextLineRevision,
    index: number
  ) => ReactElement;
};

export const Matrix: FC<Props> = (props) => {
  const { textLineRevisionIds, renderGroupRow, renderTextLineRevisionRow } =
    props;

  const cache = new CellMeasurerCache({
    defaultHeight: 30,
    fixedWidth: true,
  });

  const [, updateState] = React.useState<{}>();

  const sortedSubSubGroups = useRecoilValue(sortedSubSubGroupsState);
  const textLineRevisions = useRecoilValue(textLineRevisionsState);

  const relevantSubSubGroupIds = textLineRevisionIds.map(
    (id) => textLineRevisions[id].subSubGroupId
  );

  const relevantTextLineRevisionsBySubSubGroupId = groupBy(
    Object.values(textLineRevisions).filter((textLineRevision) =>
      textLineRevisionIds.includes(textLineRevision.id)
    ),
    (textLineRevision) => textLineRevision.subSubGroupId
  );

  const rows = flatMap(
    Object.values(sortedSubSubGroups).filter((subSubGroup) => {
      return relevantSubSubGroupIds.includes(subSubGroup.id);
    }),
    (subSubGroup) => {
      return [
        subSubGroup,
        ...relevantTextLineRevisionsBySubSubGroupId[subSubGroup.id],
      ];
    }
  );

  useEffect(() => {
    updateState({});
  }, [textLineRevisionIds]);

  useEffect(() => {
    cache.clearAll();
  });

  const listRef = useRef<any>();

  return (
    <AutoSizer onResize={() => cache.clearAll()}>
      {({ width, height }) => (
        <List
          style={{
            outline: "none",
          }}
          ref={listRef}
          width={width}
          height={height}
          rowCount={rows.length}
          rowHeight={cache.rowHeight}
          rowRenderer={({ index, key, parent, style }) => {
            const row = rows[index];

            return (
              <CellMeasurer
                parent={parent}
                key={key}
                cache={cache}
                rowIndex={index}
              >
                <div style={style}>
                  {"content" in row
                    ? renderTextLineRevisionRow(row, index)
                    : renderGroupRow(row, index)}
                </div>
              </CellMeasurer>
            );
          }}
        />
      )}
    </AutoSizer>
  );
};

import { api } from "./common";
import { Id, Role } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Role, "id" | "name">;

export const renameRole = async (props: Props) => {
  await api("v2/roles", {
    method: "POST",
    body: JSON.stringify({ id: props.id, name: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { roles } = await fetchOrgEntities(props.subscriptionId);

  return { roles };
};

import { api } from "./common";
import { Id, Role } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Role, "name">;

type ApiResponse = {
  id: number;
};

export const addRole = async (props: Props) => {
  const res = await api<ApiResponse>("v2/roles", {
    method: "POST",
    body: JSON.stringify({ name: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { roles } = await fetchOrgEntities(props.subscriptionId);

  return { roles, newRoleId: res.id };
};

import { api } from "./common";
import { Id, Person_Function } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<
  Person_Function,
  "personId" | "functionId"
>;

export const linkPersonToFunction = async (props: Props) => {
  await api("v2/persons/functions", {
    method: "POST",
    body: JSON.stringify({
      personId: props.personId,
      functionId: props.functionId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people_functions } = await fetchOrgEntities(props.subscriptionId);

  return { people_functions };
};

import { keyBy } from "lodash";
import { api } from "./common";
import { Id, Subscription, User } from "../types";

type ApiResponse = {
  name: string;
  subscriptions: {
    id: number;
    name: string;
  }[];
};

type TransformedData = {
  user: User;
  subscriptions: Record<Id, Subscription>;
};

export const fetchUserAndSubscriptions = (): Promise<TransformedData> =>
  api<ApiResponse>("v1/init/user").then(({ name, subscriptions }) => ({
    user: { name },
    subscriptions: keyBy(subscriptions, subscription => subscription.id)
  }));

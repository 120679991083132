import React, { FC } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { Overview } from "./overview";
import { Role } from "./role";
import { Person } from "./person";
import { OrgUnit } from "./org-unit";
import { Function } from "./function";
import { Team } from "./team";
import { ModelFunction } from "./model-function";

export const OrgEntities: FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Overview />
      </Route>

      <Route path={`${path}/personen/:id`}>
        <Person />
      </Route>

      <Route path={`${path}/teams/:id`}>
        <Team />
      </Route>

      <Route path={`${path}/organisatie-eenheden/:id`}>
        <OrgUnit />
      </Route>

      <Route path={`${path}/functies/:id`}>
        <Function />
      </Route>

      <Route path={`${path}/modelfuncties/:id`}>
        <ModelFunction />
      </Route>

      <Route path={`${path}/rollen/:id`}>
        <Role />
      </Route>
    </Switch>
  );
};

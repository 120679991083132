import { api } from "./common";
import {
  Id,
  Scope,
  Scope_TextLine,
  RoleTemplate,
  RoleTemplate_TextLine,
  MainGroup,
  SubGroup,
  SubSubGroup,
  TextLineRevision,
  scope_textLineKey,
  roleTemplate_textLineKey,
} from "../types";
import { keyBy, flatMap, flatMapDeep } from "lodash";

// temp to speed up development:
// import { data } from "./_subscription.json";

type ApiResponse = {
  scopes: {
    id: number;
    name: string;
    roleTemplates: {
      id: number;
      name: string;
      textLineIds: number[];
    }[];
    textLineIds: number[];
  }[];

  mainGroups: {
    id: number;
    name: string;
    displayToUser: boolean;
    position: number;
  }[];

  subGroups: {
    id: number;
    name: string;
    mainGroupId: number;
    displayToUser: boolean;
    position: number;
  }[];

  subsubGroups: {
    id: number;
    name: string;
    subGroupId: number;
    displayToUser: boolean;
    position: number;
  }[];

  textLineRevisions: {
    id: number;
    textLineId: number;
    subSubGroupId: number;
    content: string;
    textLineEnabled: true; // this appears to always be true?
  }[];
};

type TransformedData = {
  scopes: Record<Id, Scope>;
  scopes_textLines: Record<Id, Scope_TextLine>;
  roleTemplates: Record<Id, RoleTemplate>;
  roleTemplates_textLines: Record<Id, RoleTemplate_TextLine>;
  mainGroups: Record<Id, MainGroup>;
  subGroups: Record<Id, SubGroup>;
  subSubGroups: Record<Id, SubSubGroup>;
  textLineRevisions: Record<Id, TextLineRevision>;
};

export const fetchContent = async (
  subscriptionId: Id
): Promise<TransformedData> => {
  // temp to speed up development:

  const data = await api<ApiResponse>("v1/init/subscription", {
    headers: {
      subscriptionid: subscriptionId.toString(),
    },
  });

  return {
    scopes: keyBy(
      data.scopes.map(({ id, name }) => ({ id, name })),
      (scope) => scope.id
    ),

    scopes_textLines: keyBy(
      flatMap(data.scopes, (scope) =>
        scope.textLineIds.map((textLineId) => ({
          scopeId: scope.id,
          textLineId,
        }))
      ),
      scope_textLineKey
    ),

    roleTemplates: keyBy(
      flatMap(data.scopes, (scope) =>
        scope.roleTemplates.map(({ id, name }) => ({
          id,
          name,
          scopeId: scope.id,
        }))
      ),
      (roleTemplate) => roleTemplate.id
    ),

    roleTemplates_textLines: keyBy(
      flatMapDeep(data.scopes, (scope) =>
        scope.roleTemplates.map((roleTemplate) =>
          roleTemplate.textLineIds.map((textLineId) => ({
            roleTemplateId: roleTemplate.id,
            textLineId,
          }))
        )
      ),
      roleTemplate_textLineKey
    ),

    mainGroups: keyBy(
      data.mainGroups.map(
        ({ id, name, position, displayToUser: isDisplayed }) => ({
          id,
          name,
          position,
          isDisplayed,
        })
      ),
      (mainGroup) => mainGroup.id
    ),

    subGroups: keyBy(
      data.subGroups.map(
        ({ id, name, position, mainGroupId, displayToUser: isDisplayed }) => ({
          id,
          name,
          position,
          isDisplayed,
          mainGroupId,
        })
      ),
      (subGroup) => subGroup.id
    ),

    subSubGroups: keyBy(
      data.subsubGroups.map(
        ({ id, name, position, subGroupId, displayToUser: isDisplayed }) => ({
          id,
          name,
          position,
          isDisplayed,
          subGroupId,
        })
      ),
      (subSubGroup) => subSubGroup.id
    ),

    textLineRevisions: keyBy(
      data.textLineRevisions.map(
        ({ id, textLineId, subSubGroupId, content }) => ({
          id,
          textLineId,
          subSubGroupId,
          content,
        })
      ),
      (subSubGroup) => subSubGroup.id
    ),
  };
};

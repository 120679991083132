import React, { FC, useContext } from "react";
import { Evaluation, Id } from "../../types";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
  evaluationsState,
  peopleState,
} from "../../atoms";
import { EvaluationsContext } from "../../contexts/evaluations";
import {
  FormLabel,
  FormControl,
  Select,
  Stack,
  Button,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  StackDivider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { updateEvaluationBaseData } from "../../api/update-evaluation-base-data";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { DeleteEvaluation } from "./delete-evaluation";

type FormData = Pick<Evaluation, "personId" | "evaluatorPersonId" | "remarks">;

export const Details: FC = () => {
  const [updateBaseDataState, updateBaseDataSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setEvaluations(event.data.evaluations);
        setEvaluations_ratings(event.data.evaluations_ratings);
      },
    },
  });

  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState] = selectedEvaluationMachine as NonNullable<
    typeof selectedEvaluationMachine
  >;

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const people = useRecoilValue(peopleState);

  const [evaluations, setEvaluations] = useRecoilState(evaluationsState);
  const selectedEvaluation = evaluations[
    selectedEvaluationState.context.selectedEvaluationId as Id
  ] as Evaluation | undefined;

  const setEvaluations_ratings = useSetRecoilState(evaluations_ratingsState);

  const { register, handleSubmit, watch, errors } = useForm<FormData>({
    defaultValues: {
      personId: String(selectedEvaluation?.personId),
      evaluatorPersonId: String(selectedEvaluation?.evaluatorPersonId),
      remarks: selectedEvaluation?.remarks,
    },
    mode: "all",
  });
  const watchPersonId = watch("personId");

  const onSubmit = (data: FormData) => {
    if (selectedEvaluation) {
      updateBaseDataSend("UPDATE", {
        update: updateEvaluationBaseData({
          subscriptionId: activeSubscriptionId as Id,
          evaluationId: selectedEvaluation.id,
          personId: data.personId && Number(data.personId),
          evaluatorPersonId:
            data.evaluatorPersonId && Number(data.evaluatorPersonId),
          remarks: data.remarks,
        }),
      });
    }
  };

  return (
    <Stack spacing={8} divider={<StackDivider />}>
      <div>
        <Popover placement="right-start">
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">verwijder evaluatie</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>evaluatie verwijderen</PopoverHeader>
                <PopoverBody>
                  {isOpen &&
                    selectedEvaluation && ( // force re-render when closed
                      <DeleteEvaluation
                        id={selectedEvaluation.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8} align="start">
          {/* person id */}
          <FormControl isRequired isInvalid={errors.personId !== undefined}>
            <FormLabel>beoordeelde</FormLabel>
            <Select
              placeholder="kies beoordeelde"
              name="personId"
              ref={register({
                required: "je hebt geen beoordeelde gekozen",
              })}
              isDisabled={
                !selectedEvaluationState.matches({ selected: "definingScope" })
              }
            >
              {Object.values(people).map((person) => (
                <option key={person.id} value={person.id}>
                  {person.name}
                </option>
              ))}
            </Select>
            {errors.personId === undefined &&
              String(watchPersonId) !==
                String(selectedEvaluation?.personId) && (
                <FormHelperText>
                  pas op: wanneer je de beoordeelde aanpast, moet de scope
                  (eventuele eerder gekozen tekstregels) opnieuw worden
                  vastgesteld
                </FormHelperText>
              )}
            <FormErrorMessage>{errors.personId?.message}</FormErrorMessage>
          </FormControl>

          {/* evaluator person id */}
          <FormControl
            isRequired
            isInvalid={errors.evaluatorPersonId !== undefined}
          >
            <FormLabel>beoordelaar</FormLabel>
            <Select
              placeholder="kies beoordelaar"
              name="evaluatorPersonId"
              ref={register({
                required: "je hebt geen beoordelaar gekozen",
              })}
              isDisabled={
                !selectedEvaluationState.matches({ selected: "definingScope" })
              }
            >
              {Object.values(people).map((person) => (
                <option key={person.id} value={person.id}>
                  {person.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.evaluatorPersonId?.message}
            </FormErrorMessage>
          </FormControl>

          {/* remarks */}
          <FormControl>
            <FormLabel>opmerkingen</FormLabel>
            <Textarea
              placeholder="vul hier eventueel opmerkingen in"
              name="remarks"
              ref={register}
              rows={10}
            />
            <FormErrorMessage>
              {errors.evaluatorPersonId?.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            size="sm"
            isLoading={updateBaseDataState.matches("loading")}
            isDisabled={selectedEvaluationState.matches({
              selected: "conducted",
            })}
          >
            opslaan
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

import { Machine } from "xstate";

export type FetchStateSchema = {
  states: {
    idle: {};
    loading: {};
    success: {};
    failure: {};
  };
};

export type FetchEvent = { type: "FETCH" } | { type: "RETRY" };

export const fetchMachine = Machine<{}, FetchStateSchema, FetchEvent>({
  strict: true,
  id: "fetch",
  initial: "idle",
  states: {
    idle: {
      on: {
        FETCH: {
          target: "loading",
        },
      },
    },

    loading: {
      invoke: {
        id: "fetch",
        src: "fetch",
        onDone: {
          target: "success",
          actions: ["success"],
        },
        onError: {
          target: "failure",
          actions: ["failure"],
        },
      },
    },

    success: {
      type: "final",
    },

    failure: {
      on: {
        RETRY: "loading",
      },
    },
  },
});

import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../contexts/organisation";
import { useRecoilValue } from "recoil";
import { orgEntitiesState } from "../../selectors";
import { Stack, Button, Checkbox, Heading } from "@chakra-ui/react";
import { roleTemplatesState, scopesState } from "3.0/atoms";
import { groupBy, sortBy } from "lodash";
import { Tree } from "antd";

export const Selection: FC = () => {
  const {
    selectedOrgEntityIds,
    setSelectedOrgEntityIds,
    orgEntityIsSelected,
    toggleSelectOrgEntity,
  } = useContext(OrganisationContext) as NonNullable<OrganisationContextShape>;

  const orgEntities = useRecoilValue(orgEntitiesState);

  const scopes = useRecoilValue(scopesState);
  const roleTemplates = useRecoilValue(roleTemplatesState);

  const roleTemplatesByScopeId = groupBy(
    roleTemplates,
    (roleTemplate) => roleTemplate.scopeId
  );

  return (
    <Stack spacing={6}>
      <Button
        size="sm"
        isDisabled={selectedOrgEntityIds.length === 0}
        onClick={() => {
          setSelectedOrgEntityIds([]);
        }}
      >
        deselecteer alles
      </Button>

      <Stack spacing={3}>
        {selectedOrgEntityIds
          // .filter((id) => orgEntities[id].type !== "roleTemplate")
          .map((id) => {
            const orgEntity = orgEntities[id];

            return (
              <Checkbox
                key={id}
                isChecked={orgEntityIsSelected({
                  type: orgEntity.type,
                  id: orgEntity.id,
                })}
                onChange={() => {
                  toggleSelectOrgEntity({
                    type: orgEntity.type,
                    id: orgEntity.id,
                  });
                }}
                alignItems="baseline"
              >
                {orgEntity.name}
              </Checkbox>
            );
          })}
      </Stack>

      <Stack spacing={2}>
        <Heading as="h3" size="sm" fontWeight="medium">
          templates
        </Heading>

        <Tree
          checkable
          selectable={false}
          // checkedKeys={checkedTpgKeys}
          checkedKeys={
            selectedOrgEntityIds.filter(
              (id) => orgEntities[id].type === "roleTemplate"
            ) as string[]
          }
          onCheck={(checkedKeys) => {
            setSelectedOrgEntityIds([
              ...selectedOrgEntityIds.filter(
                (id) => orgEntities[id].type !== "roleTemplate"
              ),
              ...(checkedKeys as string[]),
            ]);
          }}
        >
          {Object.values(scopes)
            .filter((scope) => roleTemplatesByScopeId[scope.id]?.length > 0)
            .map((scope) => (
              <Tree.TreeNode
                key={`scope-${scope.id}`}
                title={
                  <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                    {scope.name}
                  </span>
                }
                style={{
                  fontSize: "1rem",
                }}
                checkable={false}
              >
                {sortBy(
                  roleTemplatesByScopeId[scope.id],
                  (roleTemplate) => roleTemplate.name
                ).map((roleTemplate) => (
                  <Tree.TreeNode
                    key={`roleTemplate-${roleTemplate.id}`}
                    title={
                      <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                        {roleTemplate.name}
                      </span>
                    }
                    style={{ fontSize: "1rem" }}
                  />
                ))}
              </Tree.TreeNode>
            ))}
        </Tree>
      </Stack>
    </Stack>
  );
};

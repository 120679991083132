import { api } from "./common";
import { Id, Team } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Team, "name">;

type ApiResponse = {
  id: number;
};

export const addTeam = async (props: Props) => {
  const res = await api<ApiResponse>("v2/teams", {
    method: "POST",
    body: JSON.stringify({ name: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { teams } = await fetchOrgEntities(props.subscriptionId);

  return { teams, newTeamId: res.id };
};

import React, { FC } from "react";
import { Id } from "../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  evaluationsState,
  evaluations_ratingsState,
} from "../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { addTextLineRevisionsToEvaluation } from "../../api/add-text-line-revisions-to-evaluation";
import { people_textLineRevisionStatusesState } from "3.0/selectors";

type Props = {
  targetEvaluationId: Id;
  onSuccess: () => void;
};

export const SelectAllTextLineRevisions: FC<Props> = (props) => {
  const { targetEvaluationId, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setEvaluations_ratings = useSetRecoilState(evaluations_ratingsState);
  const evaluations = useRecoilValue(evaluationsState);
  const people_textLineRevisionStatuses = useRecoilValue(
    people_textLineRevisionStatusesState
  );

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () => {
        const targetPersonTextLineRevisionIds = Object.values(
          people_textLineRevisionStatuses
        )
          .filter(
            (rel) =>
              rel.personId === evaluations[targetEvaluationId].personId &&
              (rel.status === "checkedInOwnProfile" ||
                rel.status === "checkedInSubProfile")
          )
          .map((rel) => rel.textLineRevisionId);

        return addTextLineRevisionsToEvaluation({
          evaluationId: targetEvaluationId,
          textLineRevisionIds: targetPersonTextLineRevisionIds,
          subscriptionId: activeSubscriptionId as Id,
        });
      },
    },

    actions: {
      success: (_, event: any) => {
        setEvaluations_ratings(event.data.evaluations_ratings);
        onSuccess();
        toast({
          title: "alle tekstregels geselecteerd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "tekstregels selecteren niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      colorScheme="red"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
    >
      selecteer alles
    </Button>
  );
};

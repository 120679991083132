import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeSubscriptionIdState,
  functionsState,
  orgUnitsState,
} from "../../../atoms";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { addFunction } from "../../../api/add-function";

type FormData = { name: string; orgUnitId: Id };

type Props = {
  onSuccess: (newFunctionId: Id) => void;
};

export const AddFunction: FC<Props> = (props) => {
  const { onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setFunctions = useSetRecoilState(functionsState);
  const orgUnits = useRecoilValue(orgUnitsState);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const watchName = watch("name");
  const watchOrgUnitId = watch("orgUnitId");

  const onSubmit = () => {
    send(state.matches("failure") ? "RETRY" : "FETCH");
  };

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        addFunction({
          name: watchName,
          orgUnitId: Number(watchOrgUnitId),
          subscriptionId: activeSubscriptionId as Id,
        }),
    },

    actions: {
      success: (_, event: any) => {
        setFunctions(event.data.functions);
        onSuccess(event.data.newFunctionId);
        toast({
          title: "functie toegevoegd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "toevoegen van functie niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8} align="start">
        <FormControl isRequired isInvalid={errors.name !== undefined}>
          <FormLabel>naam</FormLabel>
          <Input
            name="name"
            ref={register({
              required: "je hebt geen naam ingevuld",
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>organisatie-eenheid</FormLabel>
          <Select
            placeholder="kies organisatie-eenheid"
            name="orgUnitId"
            ref={register()}
          >
            {Object.values(orgUnits).map((orgUnit) => (
              <option key={orgUnit.id} value={orgUnit.id}>
                {orgUnit.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" size="sm" isLoading={state.matches("loading")}>
          voeg toe
        </Button>
      </Stack>
    </form>
  );
};

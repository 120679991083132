import { api } from "./common";
import { Id, Person_Team } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<Person_Team, "personId" | "teamId">;

export const unlinkPersonFromTeam = async (props: Props) => {
  const memberships = (await api("v2/teammemberships", {
    headers: { subscriptionid: props.subscriptionId.toString() },
  })) as { id: number; person: { id: number }; team: { id: number } }[];

  const membershipId = memberships.find(
    (membership) =>
      membership.person.id === props.personId &&
      membership.team.id === props.teamId
  )?.id;

  await api(`v2/teammemberships/${membershipId}`, {
    method: "DELETE",
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { people_teams } = await fetchOrgEntities(props.subscriptionId);

  return { people_teams };
};

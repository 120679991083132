import { api } from "./common";
import { Id, ModelFunction } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<ModelFunction, "name">;

type ApiResponse = {
  id: number;
};

export const addModelFunction = async (props: Props) => {
  const res = await api<ApiResponse>("v2/modelfunctions", {
    method: "POST",
    body: JSON.stringify({ name: props.name }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { modelFunctions } = await fetchOrgEntities(props.subscriptionId);

  return { modelFunctions, newModelFunctionId: res.id };
};

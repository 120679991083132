import React, { FC } from "react";
import { Id } from "../../../types";
import { useMachine } from "@xstate/react";
import { fetchMachine } from "../../../machines/fetch";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeSubscriptionIdState, peopleState } from "../../../atoms";
import { Button, useToast } from "@chakra-ui/react";
import { deletePerson } from "../../../api/delete-person";

type Props = {
  id: Id;
  onSuccess: () => void;
};

export const DeletePerson: FC<Props> = (props) => {
  const { id, onSuccess } = props;

  const toast = useToast();

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);
  const setPeople = useSetRecoilState(peopleState);

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetch: () =>
        deletePerson({ id, subscriptionId: activeSubscriptionId as Id }),
    },

    actions: {
      success: (_, event: any) => {
        setPeople(event.data.people);
        onSuccess();
        toast({
          title: "persoon verwijderd",
          status: "success",
          isClosable: true,
        });
      },

      failure: (_, event: any) => {
        toast({
          title: "verwijderen van persoon niet gelukt",
          description: JSON.stringify(event.data, null, 2),
          status: "error",
          isClosable: true,
        });
      },
    },
  });

  return (
    <Button
      type="submit"
      size="sm"
      isLoading={state.matches("loading")}
      onClick={() => {
        send(state.matches("failure") ? "RETRY" : "FETCH");
      }}
    >
      bevestig
    </Button>
  );
};

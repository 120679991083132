import { api } from "./common";
import { Id } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id; name: string; parentOrgUnitId?: Id };

type ApiResponse = {
  id: number;
};

export const addOrgUnit = async (props: Props) => {
  const res = await api<ApiResponse>("v2/departments", {
    method: "POST",
    body: JSON.stringify({
      name: props.name,
      displayName: props.name,
      ...(props.parentOrgUnitId
        ? { parentDepartmentId: props.parentOrgUnitId }
        : {}),
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { orgUnits } = await fetchOrgEntities(props.subscriptionId);

  return { orgUnits, newOrgUnitId: res.id };
};

import { api } from "./common";
import { Id, ModelFunction } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<ModelFunction, "id">;

export const deleteModelFunction = async (props: Props) => {
  await api(`v2/modelfunctions/${props.id}`, {
    method: "DELETE",
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { modelFunctions } = await fetchOrgEntities(props.subscriptionId);

  return { modelFunctions };
};

import { api } from "./common";
import { Id, OrgUnit } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<
  OrgUnit,
  "id" | "name" | "parentOrgUnitId"
>;

export const renameOrgUnit = async (props: Props) => {
  await api("v2/departments", {
    method: "POST",
    body: JSON.stringify({
      id: props.id,
      parentDepartmentId: props.parentOrgUnitId,
      name: props.name,
      displayName: props.name,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { orgUnits } = await fetchOrgEntities(props.subscriptionId);

  return { orgUnits };
};

import { EventObject, Interpreter, State } from "xstate";
import { evaluationRatings, evaluationStatuses } from "./constants";

export type Machine<TContext, TState, TEvent extends EventObject> = [
  State<TContext, TEvent>,
  Interpreter<TContext, TState, TEvent>["send"],
  Interpreter<TContext, TState, TEvent>
];

export type Id = number | string;

export type User = {
  name: string;
};

export type Subscription = {
  id: Id;
  name: string;
};

export type Role = {
  id: Id;
  name: string;
};

export type Role_TextLineRevision = {
  roleId: Id;
  textLineRevisionId: Id;
};

export const role_textLineRevisionKey = (
  props: Pick<Role_TextLineRevision, "roleId" | "textLineRevisionId">
) => `${props.roleId}-${props.textLineRevisionId}`;

export type ModelFunction = {
  id: Id;
  name: string;
};

export type ModelFunction_TextLineRevision = {
  modelFunctionId: Id;
  textLineRevisionId: Id;
  isChecked: boolean;
};

export const modelFunction_textLineRevisionKey = (
  props: Pick<
    ModelFunction_TextLineRevision,
    "modelFunctionId" | "textLineRevisionId"
  >
) => `${props.modelFunctionId}-${props.textLineRevisionId}`;

export type ModelFunction_Role = {
  modelFunctionId: Id;
  roleId: Id;
};

export const modelFunction_RoleKey = (
  props: Pick<ModelFunction_Role, "modelFunctionId" | "roleId">
) => `${props.modelFunctionId}-${props.roleId}`;

export type Function = {
  id: Id;
  name: string;
  orgUnitId: Id;
};

export type Function_TextLineRevision = {
  functionId: Id;
  textLineRevisionId: Id;
  isChecked: boolean;
};

export const function_textLineRevisionKey = (
  props: Pick<Function_TextLineRevision, "functionId" | "textLineRevisionId">
) => `${props.functionId}-${props.textLineRevisionId}`;

export type Function_Role = {
  functionId: Id;
  roleId: Id;
};

export const function_RoleKey = (
  props: Pick<Function_Role, "functionId" | "roleId">
) => `${props.functionId}-${props.roleId}`;

export type Function_ModelFunction = {
  functionId: Id;
  modelFunctionId: Id;
};

export const function_modelFunctionKey = (
  props: Pick<Function_ModelFunction, "functionId" | "modelFunctionId">
) => `${props.functionId}-${props.modelFunctionId}`;

export type Team = {
  id: Id;
  name: string;
};

export type Team_TextLineRevision = {
  teamId: Id;
  textLineRevisionId: Id;
};

export const team_textLineRevisionKey = (
  props: Pick<Team_TextLineRevision, "teamId" | "textLineRevisionId">
) => `${props.teamId}-${props.textLineRevisionId}`;

export type OrgUnit = {
  id: Id;
  name: string;
  parentOrgUnitId?: Id;
};

export type OrgUnit_TextLineRevision = {
  orgUnitId: Id;
  textLineRevisionId: Id;
};

export const orgUnit_textLineRevisionKey = (
  props: Pick<OrgUnit_TextLineRevision, "orgUnitId" | "textLineRevisionId">
) => `${props.orgUnitId}-${props.textLineRevisionId}`;

export type Person = {
  id: Id;
  name: string;
};

export type Person_TextLineRevision = {
  personId: Id;
  textLineRevisionId: Id;
  isChecked: boolean;
};

export const person_textLineRevisionKey = (
  props: Pick<Person_TextLineRevision, "personId" | "textLineRevisionId">
) => `${props.personId}-${props.textLineRevisionId}`;

export type Person_Role = {
  personId: Id;
  roleId: Id;
};

export const person_roleKey = (
  props: Pick<Person_Role, "personId" | "roleId">
) => `${props.personId}-${props.roleId}`;

export type Person_Function = {
  personId: Id;
  functionId: Id;
};

export const person_functionKey = (
  props: Pick<Person_Function, "personId" | "functionId">
) => `${props.personId}-${props.functionId}`;

export type Person_Team = {
  personId: Id;
  teamId: Id;
};

export const person_teamKey = (
  props: Pick<Person_Team, "personId" | "teamId">
) => `${props.personId}-${props.teamId}`;

export type Evaluation = {
  id: Id;
  dateTime: string;
  personId: Id;
  evaluatorPersonId?: Id;
  status: typeof evaluationStatuses[number]["value"];
  remarks: string;
};

export type Evaluation_Rating = {
  evaluationId: Id;
  textLineRevisionId: Id;
  rating: typeof evaluationRatings[number]["value"];
};

export const evaluation_ratingKey = (
  props: Pick<Evaluation_Rating, "evaluationId" | "textLineRevisionId">
) => `${props.evaluationId}-${props.textLineRevisionId}`;

/**
 * For now, text lines appear to be purely derived state.
 * A text line is currently nothing more than an ID and its existence
 * can be derived from iterating through the text line revisions.
 *
 * Therefore, there's no need for a separate TextLine type at this moment.
 */

// export type TextLine = {
//   id: Id;
// };

export type TextLineRevision = {
  id: Id;
  content: string;
  textLineId: Id;
  subSubGroupId: Id;
};

export type Scope = {
  id: Id;
  name: string;
};

export type Scope_TextLine = {
  scopeId: Id;
  textLineId: Id;
};

export const scope_textLineKey = (
  props: Pick<Scope_TextLine, "scopeId" | "textLineId">
) => `${props.scopeId}-${props.textLineId}`;

export type RoleTemplate = {
  id: Id;
  name: string;
  scopeId: Id;
};

export type RoleTemplate_TextLine = {
  roleTemplateId: Id;
  textLineId: Id;
};

export const roleTemplate_textLineKey = (
  props: Pick<RoleTemplate_TextLine, "roleTemplateId" | "textLineId">
) => `${props.roleTemplateId}-${props.textLineId}`;

export type MainGroup = {
  id: Id;
  name: string;
  position: number;
  isDisplayed: boolean;
};

export type SubGroup = {
  id: Id;
  name: string;
  position: number;
  isDisplayed: boolean;
  mainGroupId: Id;
};

export type SubSubGroup = {
  id: Id;
  name: string;
  position: number;
  isDisplayed: boolean;
  subGroupId: Id;
};

// todo: remove
export type TextLineRevisionStatus =
  | "checkedInOwnProfile"
  | "uncheckedInOwnProfile"
  | "checkedInSubProfile"
  | "notChecked";

export type HelpText = {
  tag: string;
  content: string;
  externalLink?: string;
};

import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { people_teamsState, peopleState, teamsState } from "../../../atoms";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Link as ChakraLink,
} from "@chakra-ui/react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { DeleteTeam } from "./delete-team";
import { RenameTeam } from "./rename-team";
import { PDF } from "./pdf";

export const Team: FC = () => {
  const { goBack } = useHistory();
  const { id: rawId } = useParams<{ id: string }>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const team = useRecoilValue(teamsState)[id];

  const people = useRecoilValue(peopleState);
  const linkedPeople = Object.values(useRecoilValue(people_teamsState))
    .filter((rel) => rel.teamId === id)
    .map((rel) => people[rel.personId]);

  if (!team) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {team.name}
        </Heading>

        <PDF
          orgEntityType="team"
          orgEntityId={team.id}
          orgEntityName={team.name}
        />

        {/* delete team */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder team</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>team verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeleteTeam
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename team */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenameTeam
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* linked people */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          gekoppelde personen
        </Heading>

        {linkedPeople.map((person) => (
          <Flex key={person.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "person", id: person.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "person", id: person.id });
              }}
              alignItems="baseline"
            >
              {person.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/personen/${person.id}`}
            >
              details
            </ChakraLink>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};

import { selector } from "recoil";
import {
  functions_modelFunctionsState,
  functions_rolesState,
  functions_textLineRevisionsState,
  functionsState,
  mainGroupsState,
  modelFunctions_rolesState,
  modelFunctions_textLineRevisionsState,
  modelFunctionsState,
  orgUnits_textLineRevisionsState,
  orgUnitsState,
  people_functionsState,
  people_rolesState,
  people_textLineRevisionsState,
  peopleState,
  roles_textLineRevisionsState,
  rolesState,
  subGroupsState,
  subSubGroupsState,
  teams_textLineRevisionsState,
  teamsState,
  textLineRevisionsState,
  roleTemplatesState,
  roleTemplates_textLinesState,
} from "./atoms";
import { sortBy, keyBy, flatMap, uniq, groupBy, memoize } from "lodash";
import {
  Function_TextLineRevision,
  function_textLineRevisionKey,
  Id,
  ModelFunction,
  ModelFunction_TextLineRevision,
  modelFunction_textLineRevisionKey,
  Person,
  Person_TextLineRevision,
  person_textLineRevisionKey,
  Role,
  Role_TextLineRevision,
  role_textLineRevisionKey,
  TextLineRevisionStatus,
  Function,
  OrgUnit,
  Team,
  RoleTemplate,
} from "./types";

/**
 * ...
 */
export const sortedSubSubGroupsState = selector({
  key: "sortedSubSubGroups",
  get: ({ get }) => {
    const mainGroups = get(mainGroupsState);
    const subGroups = get(subGroupsState);
    const subSubGroups = get(subSubGroupsState);

    return sortBy(subSubGroups, (subSubGroup) => {
      const mainGroup =
        mainGroups[subGroups[subSubGroup.subGroupId].mainGroupId];
      const subGroup = subGroups[subSubGroup.subGroupId];

      return `${mainGroup.position}-${subGroup.position}-${subSubGroup.position}`;
    });
  },
});

/**
 * ...
 */
export const sortedSubGroupsState = selector({
  key: "sortedSubGroups",
  get: ({ get }) => {
    const mainGroups = get(mainGroupsState);
    const subGroups = get(subGroupsState);

    return sortBy(subGroups, (subGroup) => {
      const mainGroup = mainGroups[subGroups[subGroup.id].mainGroupId];

      return `${mainGroup.position}-${subGroup.position}`;
    });
  },
});

/**
 * ...
 */
export const sortedMainGroupsState = selector({
  key: "sortedMainGroups",
  get: ({ get }) => {
    const mainGroups = get(mainGroupsState);

    return sortBy(mainGroups, (mainGroup) => mainGroup.position);
  },
});

/**
 * ...
 */
export const latestTextLineRevisionByTextLineIdState = selector({
  key: "latestTextLineRevisionByTextLineId",
  get: ({ get }) =>
    keyBy(
      Object.values(get(textLineRevisionsState)),
      (textLineRevision) => textLineRevision.textLineId
    ),
});

/**
 * ...
 */
export const roles_textLineRevisionStatusesState = selector<
  Record<Id, Role_TextLineRevision & { status: TextLineRevisionStatus }>
>({
  key: "roles_textLineRevisionStatuses",
  get: ({ get }) => {
    const textLineRevisions = get(textLineRevisionsState);
    const roles_textLineRevisions = get(roles_textLineRevisionsState);
    const roles = get(rolesState);

    return keyBy(
      flatMap(
        Object.values(textLineRevisions),
        ({ id: textLineRevisionId }) => {
          return Object.values(roles).map(({ id: roleId }) => {
            const isLinkedToTextLine =
              role_textLineRevisionKey({
                roleId,
                textLineRevisionId,
              }) in roles_textLineRevisions;

            const status = isLinkedToTextLine
              ? "checkedInOwnProfile"
              : "notChecked";

            return {
              roleId,
              textLineRevisionId,
              status,
            };
          });
        }
      ),
      role_textLineRevisionKey
    );
  },
});

/**
 * ...
 */
export const modelFunctions_textLineRevisionStatusesState = selector<
  Record<
    Id,
    Pick<
      ModelFunction_TextLineRevision,
      "modelFunctionId" | "textLineRevisionId"
    > & { status: TextLineRevisionStatus }
  >
>({
  key: "modelFunctions_textLineRevisionStatuses",
  get: ({ get }) => {
    const modelFunctions = get(modelFunctionsState);
    const textLineRevisions = get(textLineRevisionsState);
    const modelFunctions_textLineRevisions = get(
      modelFunctions_textLineRevisionsState
    );
    const modelFunctions_roles = get(modelFunctions_rolesState);
    const roles_textLineRevisionStatuses = get(
      roles_textLineRevisionStatusesState
    );

    return keyBy(
      flatMap(
        Object.values(textLineRevisions),
        ({ id: textLineRevisionId }) => {
          return Object.values(modelFunctions).map(
            ({ id: modelFunctionId }) => {
              const ret = { modelFunctionId, textLineRevisionId };

              const inOwnProfile =
                modelFunctions_textLineRevisions[
                  modelFunction_textLineRevisionKey({
                    modelFunctionId,
                    textLineRevisionId,
                  })
                ];

              if (inOwnProfile) {
                return {
                  ...ret,
                  status: inOwnProfile.isChecked
                    ? "checkedInOwnProfile"
                    : "uncheckedInOwnProfile",
                };
              }

              const inLinkedRoles = Object.values(modelFunctions_roles)
                .filter((rel) => rel.modelFunctionId === modelFunctionId)
                .map((rel) => rel.roleId)
                .some(
                  (roleId) =>
                    roles_textLineRevisionStatuses[
                      role_textLineRevisionKey({ roleId, textLineRevisionId })
                    ].status === "checkedInOwnProfile"
                );

              if (inLinkedRoles) {
                return { ...ret, status: "checkedInSubProfile" };
              }

              return { ...ret, status: "notChecked" };
            }
          );
        }
      ),
      modelFunction_textLineRevisionKey
    );
  },
});

/**
 * ...
 */
export const functions_textLineRevisionStatusesState = selector<
  Record<
    Id,
    Pick<Function_TextLineRevision, "functionId" | "textLineRevisionId"> & {
      status: TextLineRevisionStatus;
    }
  >
>({
  key: "functions_textLineRevisionStatuses",
  get: ({ get }) => {
    const functions = get(functionsState);
    const textLineRevisions = get(textLineRevisionsState);
    const functions_textLineRevisions = get(functions_textLineRevisionsState);
    const functions_modelFunctions = get(functions_modelFunctionsState);
    const functions_roles = get(functions_rolesState);
    const modelFunctions_textLineRevisionStatuses = get(
      modelFunctions_textLineRevisionStatusesState
    );
    const roles_textLineRevisionStatuses = get(
      roles_textLineRevisionStatusesState
    );

    return keyBy(
      flatMap(
        Object.values(textLineRevisions),
        ({ id: textLineRevisionId }) => {
          return Object.values(functions).map(({ id: functionId }) => {
            const ret = { functionId, textLineRevisionId };

            const inOwnProfile =
              functions_textLineRevisions[
                function_textLineRevisionKey({ functionId, textLineRevisionId })
              ];

            if (inOwnProfile) {
              return {
                ...ret,
                status: inOwnProfile.isChecked
                  ? "checkedInOwnProfile"
                  : "uncheckedInOwnProfile",
              };
            }

            const inLinkedModelFunctions = Object.values(
              functions_modelFunctions
            )
              .filter((rel) => rel.functionId === functionId)
              .map((rel) => rel.modelFunctionId)
              .some((modelFunctionId) => {
                const status =
                  modelFunctions_textLineRevisionStatuses[
                    modelFunction_textLineRevisionKey({
                      modelFunctionId,
                      textLineRevisionId,
                    })
                  ].status;

                return (
                  status === "checkedInOwnProfile" ||
                  status === "checkedInSubProfile"
                );
              });

            const inLinkedRoles = Object.values(functions_roles)
              .filter((rel) => rel.functionId === functionId)
              .map((rel) => rel.roleId)
              .some(
                (roleId) =>
                  roles_textLineRevisionStatuses[
                    role_textLineRevisionKey({ roleId, textLineRevisionId })
                  ].status === "checkedInOwnProfile"
              );

            if (inLinkedModelFunctions || inLinkedRoles) {
              return { ...ret, status: "checkedInSubProfile" };
            }

            return { ...ret, status: "notChecked" };
          });
        }
      ),
      function_textLineRevisionKey
    );
  },
});

/**
 * ...
 */
export const people_textLineRevisionStatusesState = selector<
  Record<
    Id,
    Pick<Person_TextLineRevision, "personId" | "textLineRevisionId"> & {
      status: TextLineRevisionStatus;
    }
  >
>({
  key: "people_textLineRevisionStatuses",
  get: ({ get }) => {
    const people = get(peopleState);
    const textLineRevisions = get(textLineRevisionsState);
    const people_textLineRevisions = get(people_textLineRevisionsState);
    const people_functions = get(people_functionsState);
    const people_roles = get(people_rolesState);
    const functions_textLineRevisionStatuses = get(
      functions_textLineRevisionStatusesState
    );
    const roles_textLineRevisionStatuses = get(
      roles_textLineRevisionStatusesState
    );

    return keyBy(
      flatMap(
        Object.values(textLineRevisions),
        ({ id: textLineRevisionId }) => {
          return Object.values(people).map(({ id: personId }) => {
            const ret = { personId, textLineRevisionId };

            const inOwnProfile =
              people_textLineRevisions[
                person_textLineRevisionKey({ personId, textLineRevisionId })
              ];

            if (inOwnProfile) {
              return {
                ...ret,
                status: inOwnProfile.isChecked
                  ? "checkedInOwnProfile"
                  : "uncheckedInOwnProfile",
              };
            }

            const inLinkedFunctions = Object.values(people_functions)
              .filter((rel) => rel.personId === personId)
              .map((rel) => rel.functionId)
              .some((functionId) => {
                const status =
                  functions_textLineRevisionStatuses[
                    function_textLineRevisionKey({
                      functionId,
                      textLineRevisionId,
                    })
                  ].status;

                return (
                  status === "checkedInOwnProfile" ||
                  status === "checkedInSubProfile"
                );
              });

            const inLinkedRoles = Object.values(people_roles)
              .filter((rel) => rel.personId === personId)
              .map((rel) => rel.roleId)
              .some(
                (roleId) =>
                  roles_textLineRevisionStatuses[
                    role_textLineRevisionKey({ roleId, textLineRevisionId })
                  ].status === "checkedInOwnProfile"
              );

            if (inLinkedFunctions || inLinkedRoles) {
              return { ...ret, status: "checkedInSubProfile" };
            }

            return { ...ret, status: "notChecked" };
          });
        }
      ),
      person_textLineRevisionKey
    );
  },
});

/**
 * ...
 */

export const textLineIdsState = selector<Id[]>({
  key: "textLineIds",
  get: ({ get }) =>
    uniq(
      Object.values(get(textLineRevisionsState)).map(
        (textLineRevision) => textLineRevision.textLineId
      )
    ),
});

export type OrgEntityType =
  | "person"
  | "function"
  | "modelFunction"
  | "role"
  | "orgUnit"
  | "team"
  | "roleTemplate";

export const orgEntityKey = (props: { id: Id; type: OrgEntityType }) =>
  `${props.type}-${props.id}`;

export const orgEntitiesState = selector<
  Record<
    Id,
    (
      | Person
      | Function
      | ModelFunction
      | Role
      | OrgUnit
      | Team
      | RoleTemplate
    ) & {
      type: OrgEntityType;
    }
  >
>({
  key: "orgEntities",
  get: ({ get }) =>
    keyBy(
      [
        ...Object.values(get(rolesState)).map(
          (role) =>
            ({
              type: "role",
              ...role,
            } as const)
        ),

        ...Object.values(get(modelFunctionsState)).map(
          (modelFunction) =>
            ({
              type: "modelFunction",
              ...modelFunction,
            } as const)
        ),

        ...Object.values(get(functionsState)).map(
          (func) =>
            ({
              type: "function",
              ...func,
            } as const)
        ),

        ...Object.values(get(peopleState)).map(
          (person) =>
            ({
              type: "person",
              ...person,
            } as const)
        ),

        ...Object.values(get(orgUnitsState)).map(
          (orgUnit) =>
            ({
              type: "orgUnit",
              ...orgUnit,
            } as const)
        ),

        ...Object.values(get(teamsState)).map(
          (team) =>
            ({
              type: "team",
              ...team,
            } as const)
        ),

        ...Object.values(get(roleTemplatesState)).map(
          (roleTemplate) =>
            ({
              type: "roleTemplate",
              ...roleTemplate,
              name: `${roleTemplate.name} (templ.)`,
            } as const)
        ),
      ],
      orgEntityKey
    ),
});

export const orgEntity_textLineKey = (props: {
  orgEntityType: OrgEntityType;
  orgEntityId: Id;
  textLineId: Id;
}) =>
  `${orgEntityKey({
    type: props.orgEntityType,
    id: props.orgEntityId,
  })}-${props.textLineId}`;

export const orgEntities_textLinesState = selector<
  Record<
    Id,
    {
      orgEntityType: OrgEntityType;
      orgEntityId: Id;
      textLineId: Id;
      isChecked?: boolean;
    }
  >
>({
  key: "orgEntities_textLines",
  get: ({ get }) => {
    const roles_textLineRevisions = get(roles_textLineRevisionsState);
    const modelFunctions_textLineRevisions = get(
      modelFunctions_textLineRevisionsState
    );
    const functions_textLineRevisions = get(functions_textLineRevisionsState);
    const people_textLineRevisions = get(people_textLineRevisionsState);
    const orgUnits_textLineRevisions = get(orgUnits_textLineRevisionsState);
    const teams_textLineRevisions = get(teams_textLineRevisionsState);
    const roleTemplates_textLines = get(roleTemplates_textLinesState);
    const textLineRevisions = get(textLineRevisionsState);

    return keyBy(
      [
        ...Object.values(roles_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "role",
              orgEntityId: rel.roleId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
            } as const)
        ),

        ...Object.values(modelFunctions_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "modelFunction",
              orgEntityId: rel.modelFunctionId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
              isChecked: rel.isChecked,
            } as const)
        ),

        ...Object.values(functions_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "function",
              orgEntityId: rel.functionId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
              isChecked: rel.isChecked,
            } as const)
        ),

        ...Object.values(people_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "person",
              orgEntityId: rel.personId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
              isChecked: rel.isChecked,
            } as const)
        ),

        ...Object.values(teams_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "team",
              orgEntityId: rel.teamId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
            } as const)
        ),

        ...Object.values(orgUnits_textLineRevisions).map(
          (rel) =>
            ({
              orgEntityType: "orgUnit",
              orgEntityId: rel.orgUnitId,
              textLineId: textLineRevisions[rel.textLineRevisionId]?.textLineId,
            } as const)
        ),

        ...Object.values(roleTemplates_textLines).map(
          (rel) =>
            ({
              orgEntityType: "roleTemplate",
              orgEntityId: rel.roleTemplateId,
              textLineId: rel.textLineId,
            } as const)
        ),
      ],
      orgEntity_textLineKey
    );
  },
});

export type OrgEntity_TextLineStatus = {
  orgEntityType: OrgEntityType;
  orgEntityId: Id;
  textLineId: Id;
  ownProfileStatus: "checked" | "unchecked" | "inherited" | "notChecked";
  calculatedStatus:
    | "checkedInOwnProfile"
    | "uncheckedInOwnProfile"
    | "checkedInSubProfile"
    | "notChecked";
  subProfileStatuses: OrgEntity_TextLineStatus[];
};

export const orgEntities_textLineStatusesState__PREV = selector<
  Record<Id, OrgEntity_TextLineStatus>
>({
  key: "orgEntities_textLineStatuses__PREV",
  get: ({ get }) => {
    console.log("PREV is executed");

    const orgEntities = get(orgEntitiesState);
    const orgEntities_textLines = get(orgEntities_textLinesState);
    const people_functions = get(people_functionsState);
    const people_roles = get(people_rolesState);
    const functions_roles = get(functions_rolesState);
    const functions_modelFunctions = get(functions_modelFunctionsState);
    const modelFunctions_roles = get(modelFunctions_rolesState);

    const getOrgEntity_textLineStatus = (props: {
      orgEntityType: OrgEntityType;
      orgEntityId: Id;
      textLineId: Id;
    }): OrgEntity_TextLineStatus => {
      const { orgEntityType, orgEntityId, textLineId } = props;

      // TODO: refactor into something more compact
      const orgEntity_textLine =
        orgEntity_textLineKey(props) in orgEntities_textLines
          ? orgEntities_textLines[orgEntity_textLineKey(props)]
          : undefined;

      const ownProfileStatus = (() => {
        if (orgEntity_textLine === undefined) {
          if (
            orgEntityType === "role" ||
            orgEntityType === "orgUnit" ||
            orgEntityType === "team"
          ) {
            return "notChecked";
          } else {
            return "inherited";
          }
        }

        if (
          orgEntityType === "role" ||
          orgEntityType === "orgUnit" ||
          orgEntityType === "team" ||
          orgEntityType === "roleTemplate"
        ) {
          return "checked";
        }

        return orgEntity_textLine.isChecked ? "checked" : "unchecked";
      })();

      const subProfileStatuses = ((): OrgEntity_TextLineStatus[] => {
        if (orgEntityType === "person") {
          return [
            ...Object.values(people_functions)
              .filter((rel) => rel.personId === orgEntityId)
              .map((rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "function",
                  orgEntityId: rel.functionId,
                  textLineId,
                })
              ),

            ...Object.values(people_roles)
              .filter((rel) => rel.personId === orgEntityId)
              .map((rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "role",
                  orgEntityId: rel.roleId,
                  textLineId,
                })
              ),
          ];
        } else if (orgEntityType === "function") {
          return [
            ...Object.values(functions_modelFunctions)
              .filter((rel) => rel.functionId === orgEntityId)
              .map((rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "modelFunction",
                  orgEntityId: rel.modelFunctionId,
                  textLineId,
                })
              ),

            ...Object.values(functions_roles)
              .filter((rel) => rel.functionId === orgEntityId)
              .map((rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "role",
                  orgEntityId: rel.roleId,
                  textLineId,
                })
              ),
          ];
        } else if (orgEntityType === "modelFunction") {
          return [
            ...Object.values(modelFunctions_roles)
              .filter((rel) => rel.modelFunctionId === orgEntityId)
              .map((rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "role",
                  orgEntityId: rel.roleId,
                  textLineId,
                })
              ),
          ];
        } else {
          return [];
        }
      })();

      const calculatedStatus: OrgEntity_TextLineStatus["calculatedStatus"] =
        (() => {
          if (ownProfileStatus === "checked") {
            return "checkedInOwnProfile";
          } else if (ownProfileStatus === "unchecked") {
            return "uncheckedInOwnProfile";
          } else if (
            subProfileStatuses.find((status) =>
              ["checkedInOwnProfile", "checkedInSubProfile"].includes(
                status.calculatedStatus
              )
            )
          ) {
            return "checkedInSubProfile";
          } else {
            return "notChecked";
          }
        })();

      return {
        ...props,
        ownProfileStatus,
        calculatedStatus,
        subProfileStatuses,
      };
    };

    return keyBy(
      flatMap(
        uniq(Object.values(orgEntities_textLines).map((rel) => rel.textLineId)),
        (textLineId) =>
          Object.values(orgEntities).map(
            ({ type: orgEntityType, id: orgEntityId }) =>
              getOrgEntity_textLineStatus({
                orgEntityType,
                orgEntityId,
                textLineId,
              })
          )
      ),
      orgEntity_textLineKey
    );
  },
});

/**
 * ==================================
 * THE NEW ONE:
 * ==================================
 */

export const orgEntities_textLineStatusesState = selector<
  Record<Id, OrgEntity_TextLineStatus>
>({
  key: "orgEntities_textLineStatuses",
  get: ({ get }) => {
    console.log("CURRENT is executed");

    const orgEntities = get(orgEntitiesState);
    const orgEntities_textLines = get(orgEntities_textLinesState);
    const people_functions = get(people_functionsState);
    const people_roles = get(people_rolesState);
    const functions_roles = get(functions_rolesState);
    const functions_modelFunctions = get(functions_modelFunctionsState);
    const modelFunctions_roles = get(modelFunctions_rolesState);

    const functionsByPersonId = groupBy(
      people_functions,
      (rel) => rel.personId
    );
    const rolesByPersonId = groupBy(people_roles, (rel) => rel.personId);
    const modelFunctionsByFunctionId = groupBy(
      functions_modelFunctions,
      (rel) => rel.functionId
    );
    const rolesByFunctionId = groupBy(functions_roles, (rel) => rel.functionId);
    const rolesByModelFunctionId = groupBy(
      modelFunctions_roles,
      (rel) => rel.modelFunctionId
    );

    const getOrgEntity_textLineStatus = memoize(
      (props: {
        orgEntityType: OrgEntityType;
        orgEntityId: Id;
        textLineId: Id;
      }): OrgEntity_TextLineStatus => {
        const { orgEntityType, orgEntityId, textLineId } = props;

        const orgEntity_textLine =
          orgEntities_textLines[orgEntity_textLineKey(props)];

        const ownProfileStatus = (() => {
          if (orgEntity_textLine === undefined) {
            if (
              orgEntityType === "role" ||
              orgEntityType === "orgUnit" ||
              orgEntityType === "team"
            ) {
              return "notChecked";
            } else {
              return "inherited";
            }
          }

          if (
            orgEntityType === "role" ||
            orgEntityType === "orgUnit" ||
            orgEntityType === "team" ||
            orgEntityType === "roleTemplate"
          ) {
            return "checked";
          }

          return orgEntity_textLine.isChecked ? "checked" : "unchecked";
        })();

        const subProfileStatuses = ((): OrgEntity_TextLineStatus[] => {
          if (orgEntityType === "person") {
            const forFunctions = (functionsByPersonId[orgEntityId] || []).map(
              (rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "function",
                  orgEntityId: rel.functionId,
                  textLineId,
                })
            );

            const forRoles = (rolesByPersonId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );

            return forFunctions.concat(forRoles);
          }

          if (orgEntityType === "function") {
            const forModelFunctions = (
              modelFunctionsByFunctionId[orgEntityId] || []
            ).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "modelFunction",
                orgEntityId: rel.modelFunctionId,
                textLineId,
              })
            );

            const forRoles = (rolesByFunctionId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );

            return forModelFunctions.concat(forRoles);
          }

          if (orgEntityType === "modelFunction") {
            return (rolesByModelFunctionId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );
          }

          return [];
        })();

        const calculatedStatus: OrgEntity_TextLineStatus["calculatedStatus"] =
          (() => {
            if (ownProfileStatus === "checked") return "checkedInOwnProfile";

            if (ownProfileStatus === "unchecked")
              return "uncheckedInOwnProfile";

            const isCheckedInSubProfile = subProfileStatuses.some(
              (status) =>
                status.calculatedStatus === "checkedInOwnProfile" ||
                status.calculatedStatus === "checkedInSubProfile"
            );

            if (isCheckedInSubProfile) return "checkedInSubProfile";

            return "notChecked";
          })();

        return Object.assign({}, props, {
          ownProfileStatus,
          calculatedStatus,
          subProfileStatuses,
        } as const);
      },
      orgEntity_textLineKey
    );

    const relevantTextLineIds = uniq(
      Object.values(orgEntities_textLines).map((rel) => rel.textLineId)
    ).filter((v) => v);

    const roles_textLineStatuses: Record<Id, OrgEntity_TextLineStatus> = keyBy(
      relevantTextLineIds.flatMap(function flatMapRoleTextLineId(textLineId) {
        return Object.values(orgEntities)
          .filter(function filterType(rel) {
            return rel.type === "role";
          })
          .map(function mapRole({ type: orgEntityType, id: orgEntityId }) {
            const isChecked =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  orgEntityId,
                  orgEntityType,
                  textLineId,
                })
              ] !== undefined;

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus: isChecked ? "checked" : "notChecked",
              calculatedStatus: isChecked
                ? "checkedInOwnProfile"
                : "notChecked",
              subProfileStatuses: [] as OrgEntity_TextLineStatus[],
            } as const;
          });
      }),
      orgEntity_textLineKey
    );

    const modelFunctions_textLineStatuses: Record<
      Id,
      OrgEntity_TextLineStatus
    > = keyBy(
      relevantTextLineIds.flatMap((textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "modelFunction")
          .map(function mapModelFunction({
            type: orgEntityType,
            id: orgEntityId,
          }) {
            const orgEntity_textLine =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  orgEntityId,
                  orgEntityType,
                  textLineId,
                })
              ];

            const ownProfileStatus = (() => {
              if (orgEntity_textLine === undefined) return "inherited";
              if (orgEntity_textLine.isChecked) return "checked";
              return "unchecked";
            })();

            const subProfileStatuses = (
              rolesByModelFunctionId[orgEntityId] || []
            ).map(
              (rel) =>
                roles_textLineStatuses[
                  orgEntity_textLineKey({
                    orgEntityId: rel.roleId,
                    orgEntityType: "role",
                    textLineId,
                  })
                ]
            );

            const calculatedStatus: OrgEntity_TextLineStatus["calculatedStatus"] =
              (() => {
                if (ownProfileStatus === "checked")
                  return "checkedInOwnProfile";

                if (ownProfileStatus === "unchecked")
                  return "uncheckedInOwnProfile";

                const isCheckedInSubProfile = subProfileStatuses.some(
                  (status) =>
                    status.calculatedStatus === "checkedInOwnProfile" ||
                    status.calculatedStatus === "checkedInSubProfile"
                );

                if (isCheckedInSubProfile) return "checkedInSubProfile";

                return "notChecked";
              })();

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus,
              calculatedStatus: calculatedStatus,
              subProfileStatuses,
            } as const;
          })
      ),
      orgEntity_textLineKey
    );

    const functions_textLineStatuses = relevantTextLineIds.flatMap(
      (textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "function")
          .map(({ type: orgEntityType, id: orgEntityId }) => {
            return getOrgEntity_textLineStatus({
              orgEntityType,
              orgEntityId,
              textLineId,
            });
          })
    );

    const people_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "person")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const orgUnit_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "orgUnit")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const team_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "team")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const roleTemplate_textLineStatuses = relevantTextLineIds.flatMap(
      (textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "roleTemplate")
          .map(({ type: orgEntityType, id: orgEntityId }) => {
            const isChecked =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  textLineId,
                  orgEntityId,
                  orgEntityType,
                })
              ] !== undefined;

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus: isChecked ? "checked" : "inherited",
              calculatedStatus: isChecked
                ? "checkedInOwnProfile"
                : "notChecked",
              subProfileStatuses: [] as OrgEntity_TextLineStatus[],
            } as const;
          })
    );

    return Object.assign(
      {},

      roles_textLineStatuses,
      modelFunctions_textLineStatuses,

      keyBy(
        [
          ...functions_textLineStatuses,
          ...people_textLineStatuses,
          ...orgUnit_textLineStatuses,
          ...team_textLineStatuses,
          ...roleTemplate_textLineStatuses,
        ],
        orgEntity_textLineKey
      )
    );
  },
});

/**
 * ==================================
 * MODIFY BELOW UNTIL 🚀🚀🚀:
 * ==================================
 */

export const orgEntities_textLineStatusesState__NEXT = selector<
  Record<Id, OrgEntity_TextLineStatus>
>({
  key: "orgEntities_textLineStatuses__NEXT",
  get: ({ get }) => {
    console.log("NEXT is executed");

    const orgEntities = get(orgEntitiesState);
    const orgEntities_textLines = get(orgEntities_textLinesState);
    const people_functions = get(people_functionsState);
    const people_roles = get(people_rolesState);
    const functions_roles = get(functions_rolesState);
    const functions_modelFunctions = get(functions_modelFunctionsState);
    const modelFunctions_roles = get(modelFunctions_rolesState);

    const functionsByPersonId = groupBy(
      people_functions,
      (rel) => rel.personId
    );
    const rolesByPersonId = groupBy(people_roles, (rel) => rel.personId);
    const modelFunctionsByFunctionId = groupBy(
      functions_modelFunctions,
      (rel) => rel.functionId
    );
    const rolesByFunctionId = groupBy(functions_roles, (rel) => rel.functionId);
    const rolesByModelFunctionId = groupBy(
      modelFunctions_roles,
      (rel) => rel.modelFunctionId
    );

    const getOrgEntity_textLineStatus = memoize(
      (props: {
        orgEntityType: OrgEntityType;
        orgEntityId: Id;
        textLineId: Id;
      }): OrgEntity_TextLineStatus => {
        const { orgEntityType, orgEntityId, textLineId } = props;

        const orgEntity_textLine =
          orgEntities_textLines[orgEntity_textLineKey(props)];

        const ownProfileStatus = (() => {
          if (orgEntity_textLine === undefined) {
            if (
              orgEntityType === "role" ||
              orgEntityType === "orgUnit" ||
              orgEntityType === "team"
            ) {
              return "notChecked";
            } else {
              return "inherited";
            }
          }

          if (
            orgEntityType === "role" ||
            orgEntityType === "orgUnit" ||
            orgEntityType === "team" ||
            orgEntityType === "roleTemplate"
          ) {
            return "checked";
          }

          return orgEntity_textLine.isChecked ? "checked" : "unchecked";
        })();

        const subProfileStatuses = ((): OrgEntity_TextLineStatus[] => {
          if (orgEntityType === "person") {
            const forFunctions = (functionsByPersonId[orgEntityId] || []).map(
              (rel) =>
                getOrgEntity_textLineStatus({
                  orgEntityType: "function",
                  orgEntityId: rel.functionId,
                  textLineId,
                })
            );

            const forRoles = (rolesByPersonId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );

            return forFunctions.concat(forRoles);
          }

          if (orgEntityType === "function") {
            const forModelFunctions = (
              modelFunctionsByFunctionId[orgEntityId] || []
            ).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "modelFunction",
                orgEntityId: rel.modelFunctionId,
                textLineId,
              })
            );

            const forRoles = (rolesByFunctionId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );

            return forModelFunctions.concat(forRoles);
          }

          if (orgEntityType === "modelFunction") {
            return (rolesByModelFunctionId[orgEntityId] || []).map((rel) =>
              getOrgEntity_textLineStatus({
                orgEntityType: "role",
                orgEntityId: rel.roleId,
                textLineId,
              })
            );
          }

          return [];
        })();

        const calculatedStatus: OrgEntity_TextLineStatus["calculatedStatus"] =
          (() => {
            if (ownProfileStatus === "checked") return "checkedInOwnProfile";

            if (ownProfileStatus === "unchecked")
              return "uncheckedInOwnProfile";

            const isCheckedInSubProfile = subProfileStatuses.some(
              (status) =>
                status.calculatedStatus === "checkedInOwnProfile" ||
                status.calculatedStatus === "checkedInSubProfile"
            );

            if (isCheckedInSubProfile) return "checkedInSubProfile";

            return "notChecked";
          })();

        return Object.assign({}, props, {
          ownProfileStatus,
          calculatedStatus,
          subProfileStatuses,
        } as const);
      },
      orgEntity_textLineKey
    );

    const relevantTextLineIds = uniq(
      Object.values(orgEntities_textLines).map((rel) => rel.textLineId)
    ).filter((v) => v);

    const roles_textLineStatuses: Record<Id, OrgEntity_TextLineStatus> = keyBy(
      relevantTextLineIds.flatMap(function flatMapRoleTextLineId(textLineId) {
        return Object.values(orgEntities)
          .filter(function filterType(rel) {
            return rel.type === "role";
          })
          .map(function mapRole({ type: orgEntityType, id: orgEntityId }) {
            const isChecked =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  orgEntityId,
                  orgEntityType,
                  textLineId,
                })
              ] !== undefined;

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus: isChecked ? "checked" : "notChecked",
              calculatedStatus: isChecked
                ? "checkedInOwnProfile"
                : "notChecked",
              subProfileStatuses: [] as OrgEntity_TextLineStatus[],
            } as const;
          });
      }),
      orgEntity_textLineKey
    );

    const modelFunctions_textLineStatuses: Record<
      Id,
      OrgEntity_TextLineStatus
    > = keyBy(
      relevantTextLineIds.flatMap((textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "modelFunction")
          .map(function mapModelFunction({
            type: orgEntityType,
            id: orgEntityId,
          }) {
            const orgEntity_textLine =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  orgEntityId,
                  orgEntityType,
                  textLineId,
                })
              ];

            const ownProfileStatus = (() => {
              if (orgEntity_textLine === undefined) return "inherited";
              if (orgEntity_textLine.isChecked) return "checked";
              return "unchecked";
            })();

            const subProfileStatuses = (
              rolesByModelFunctionId[orgEntityId] || []
            ).map(
              (rel) =>
                roles_textLineStatuses[
                  orgEntity_textLineKey({
                    orgEntityId: rel.roleId,
                    orgEntityType: "role",
                    textLineId,
                  })
                ]
            );

            const calculatedStatus: OrgEntity_TextLineStatus["calculatedStatus"] =
              (() => {
                if (ownProfileStatus === "checked")
                  return "checkedInOwnProfile";

                if (ownProfileStatus === "unchecked")
                  return "uncheckedInOwnProfile";

                const isCheckedInSubProfile = subProfileStatuses.some(
                  (status) =>
                    status.calculatedStatus === "checkedInOwnProfile" ||
                    status.calculatedStatus === "checkedInSubProfile"
                );

                if (isCheckedInSubProfile) return "checkedInSubProfile";

                return "notChecked";
              })();

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus,
              calculatedStatus: calculatedStatus,
              subProfileStatuses,
            } as const;
          })
      ),
      orgEntity_textLineKey
    );

    const functions_textLineStatuses = relevantTextLineIds.flatMap(
      (textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "function")
          .map(({ type: orgEntityType, id: orgEntityId }) => {
            return getOrgEntity_textLineStatus({
              orgEntityType,
              orgEntityId,
              textLineId,
            });
          })
    );

    const people_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "person")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const orgUnit_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "orgUnit")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const team_textLineStatuses = relevantTextLineIds.flatMap((textLineId) =>
      Object.values(orgEntities)
        .filter((rel) => rel.type === "team")
        .map(({ type: orgEntityType, id: orgEntityId }) => {
          return getOrgEntity_textLineStatus({
            orgEntityType,
            orgEntityId,
            textLineId,
          });
        })
    );

    const roleTemplate_textLineStatuses = relevantTextLineIds.flatMap(
      (textLineId) =>
        Object.values(orgEntities)
          .filter((rel) => rel.type === "roleTemplate")
          .map(({ type: orgEntityType, id: orgEntityId }) => {
            const isChecked =
              orgEntities_textLines[
                orgEntity_textLineKey({
                  textLineId,
                  orgEntityId,
                  orgEntityType,
                })
              ] !== undefined;

            return {
              textLineId,
              orgEntityType,
              orgEntityId,
              ownProfileStatus: isChecked ? "checked" : "inherited",
              calculatedStatus: isChecked
                ? "checkedInOwnProfile"
                : "notChecked",
              subProfileStatuses: [] as OrgEntity_TextLineStatus[],
            } as const;
          })
    );

    return Object.assign(
      {},

      roles_textLineStatuses,
      modelFunctions_textLineStatuses,

      keyBy(
        [
          ...functions_textLineStatuses,
          ...people_textLineStatuses,
          ...orgUnit_textLineStatuses,
          ...team_textLineStatuses,
          ...roleTemplate_textLineStatuses,
        ],
        orgEntity_textLineKey
      )
    );
  },
});

import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import {
  Stack,
  Heading,
  Checkbox,
  Spacer,
  Flex,
  Link as ChakraLink,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { peopleState } from "../../../atoms";
import { Link as ReactRouterLink, useRouteMatch } from "react-router-dom";
import { AddPerson } from "./add-person";
import { sortBy } from "lodash";

export const OverviewPeople: FC = () => {
  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const people = useRecoilValue(peopleState);

  const { url } = useRouteMatch();

  return (
    <Stack spacing={4}>
      <Flex align="center">
        <Heading as="h3" size="sm" fontWeight="medium">
          personen
        </Heading>

        <Spacer />

        {/* add person */}
        <Popover>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Button size="xs">voeg persoon toe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>persoon toevoegen</PopoverHeader>
                <PopoverBody>
                  {isOpen && ( // force re-render when closed
                    <AddPerson
                      onSuccess={(newPersonId) => {
                        toggleSelectOrgEntity({
                          type: "person",
                          id: newPersonId,
                        });
                        onClose();
                      }}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>

      {sortBy(Object.values(people), (person) => person.name.toLowerCase()).map(
        (person) => (
          <Flex key={person.id} align="center">
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "person", id: person.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "person", id: person.id });
              }}
            >
              {person.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`${url}/personen/${person.id}`}
            >
              details
            </ChakraLink>
          </Flex>
        )
      )}
    </Stack>
  );
};

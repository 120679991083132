import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { functionsState, orgUnitsState } from "../../../atoms";
import {
  Button,
  Checkbox,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Link as ChakraLink,
} from "@chakra-ui/react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { RenameOrgUnit } from "./rename-org-unit";
import { DeleteOrgUnit } from "./delete-org-unit";
import { UpdateParentOrgUnit } from "./update-parent-org-unit";
import { PDF } from "./pdf";

export const OrgUnit: FC = () => {
  const { goBack } = useHistory();
  const { id: rawId } = useParams<{id: string}>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const orgUnits = useRecoilValue(orgUnitsState);

  const orgUnit = orgUnits[id];

  const parentOrgUnit =
    orgUnit.parentOrgUnitId && orgUnits[orgUnit.parentOrgUnitId];

  const childOrgUnits = Object.values(orgUnits).filter(
    (childOrgUnit) => childOrgUnit.parentOrgUnitId === orgUnit?.id
  );

  const functions = useRecoilValue(functionsState);
  const linkedFunctions = Object.values(functions).filter(
    (func) => func.orgUnitId === id
  );

  if (!orgUnit) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {orgUnit.name}
        </Heading>

        <PDF
          orgEntityType="orgUnit"
          orgEntityId={orgUnit.id}
          orgEntityName={orgUnit.name}
        />

        {/* delete org unit */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder organisatie-eenheid</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>organisatie-eenheid verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeleteOrgUnit
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename org unit */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenameOrgUnit
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* update parent org unit */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">
                    kies bovenliggende organisatie-eenheid
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    kies bovenliggende organisatie-eenheid
                  </PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <UpdateParentOrgUnit
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* parent org unit */}
      {parentOrgUnit && (
        <Stack spacing={4}>
          <Heading as="h3" size="sm" fontWeight="medium">
            bovenliggende organisatie-eenheid
          </Heading>

          <Stack direction="row" align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "orgUnit",
                id: parentOrgUnit.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({
                  type: "orgUnit",
                  id: parentOrgUnit.id,
                });
              }}
              alignItems="baseline"
            >
              {parentOrgUnit.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/organisatie-eenheden/${parentOrgUnit.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        </Stack>
      )}

      {/* child org units */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          onderliggende organisatie-eenheden
        </Heading>

        {childOrgUnits.map((orgUnit) => (
          <Stack direction="row" key={orgUnit.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "orgUnit",
                id: orgUnit.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "orgUnit", id: orgUnit.id });
              }}
              alignItems="baseline"
            >
              {orgUnit.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/organisatie-eenheden/${orgUnit.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked functions */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          gekoppelde functies
        </Heading>

        {linkedFunctions.map((func) => (
          <Stack direction="row" key={func.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({ type: "function", id: func.id })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "function", id: func.id });
              }}
              alignItems="baseline"
            >
              {func.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/functies/${func.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

import { api } from "./common";
import { Evaluation_Rating, Id } from "../types";
import { evaluationRatings } from "../constants";
import { fetchEvaluations } from "./fetch-evaluations";

type Props = {
  subscriptionId: Id;
  evaluationId: Id;
  textLineRevisionId: Id;
  rating: Evaluation_Rating["rating"];
};

export const updateEvaluationRating = (props: Props) => {
  return api("v2/personevaluation/score", {
    method: "POST",
    body: JSON.stringify({
      personEvaluationId: props.evaluationId,
      textlineRevisionId: props.textLineRevisionId,
      score: evaluationRatings.find((rating) => rating.value === props.rating)
        ?.apiValue,
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  }).then(() => fetchEvaluations(props.subscriptionId));
};

import { api } from "./common";
import { Function_Role, Id } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<
  Function_Role,
  "functionId" | "roleId"
>;

export const linkFunctionToRole = async (props: Props) => {
  await api("v2/functions/roles", {
    method: "POST",
    body: JSON.stringify({
      functionId: props.functionId,
      roleId: props.roleId,
    }),
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { functions_roles } = await fetchOrgEntities(props.subscriptionId);

  return { functions_roles };
};

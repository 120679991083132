import { createContext } from "react";
import { Machine } from "../types";
import { FetchEvent, FetchStateSchema } from "../machines/fetch";
import {
  SelectedEvaluationContext,
  SelectedEvaluationEvent,
} from "../machines/selected-evaluation";

export type EvaluationsContextShape = {
  evaluationsMachine: Machine<{}, FetchStateSchema, FetchEvent>;
  selectedEvaluationMachine: Machine<
    SelectedEvaluationContext,
    any,
    SelectedEvaluationEvent
  >;
};

export const EvaluationsContext = createContext<
  Partial<EvaluationsContextShape>
>({});

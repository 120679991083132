import { api } from "./common";
import { Id } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";
import { evaluationStatuses } from "../constants";

type Props = { subscriptionId: Id; evaluationId: Id; textLineRevisionId: Id };

export const toggleEvaluationTextLineRevision = async (props: Props) => {
  const evaluations = await fetchEvaluations(props.subscriptionId);
  const evaluation = evaluations.evaluations[props.evaluationId];

  const evaluationContainsTextLineRevision = Object.values(
    evaluations.evaluations_ratings
  ).some(
    (rel) =>
      rel.evaluationId === props.evaluationId &&
      rel.textLineRevisionId === props.textLineRevisionId
  );

  const currentTextLineRevisionIds = Object.values(
    evaluations.evaluations_ratings
  )
    .filter((rel) => rel.evaluationId === evaluation.id)
    .map((rel) => rel.textLineRevisionId);

  return api("v2/personevaluation/update", {
    method: "POST",
    body: JSON.stringify({
      // values that should be preserved:
      id: evaluation.id,
      status: evaluationStatuses.find(
        (status) => status.value === evaluation.status
      )?.apiValue,
      personId: evaluation.personId,
      evaluatorId: evaluation.evaluatorPersonId,
      remarks: evaluation.remarks,
      evaluationDateTimeUTC: evaluation.dateTime,

      // values that should be updated:
      textLineRevisionIds: evaluationContainsTextLineRevision
        ? currentTextLineRevisionIds.filter(
            (id) => id !== props.textLineRevisionId
          )
        : [...currentTextLineRevisionIds, props.textLineRevisionId],
    }),
    headers: {
      subscriptionid: props.subscriptionId.toString(),
    },
  }).then(() => fetchEvaluations(props.subscriptionId));
};

import React, { FC, useContext } from "react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../contexts/organisation";
import { Stack, StackDivider, Input, Heading } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import {
  mainGroupsState,
  roleTemplatesState,
  scopesState,
  subGroupsState,
} from "../../atoms";
import { Tree } from "antd";
import { groupBy, sortBy } from "lodash";
import { AntTreeNodeCheckedEvent } from "antd/lib/tree";
import "antd/lib/tree/style/index.css";
import { sortSubGroupsByPosition } from "../../../2.0/store-gen/generated";

export const Filters: FC = () => {
  const {
    keywordFilter,
    setKeywordFilter,
    tpgFilter,
    setTpgFilter,
    subjectsFilter,
    setSubjectsFilter,
  } = useContext(OrganisationContext) as NonNullable<OrganisationContextShape>;
  const mainGroups = useRecoilValue(mainGroupsState);
  const subGroups = useRecoilValue(subGroupsState);
  const subGroupIds = Object.values(subGroups).map((subGroup) => subGroup.id);
  const subGroupsByMainGroupId = groupBy(
    subGroups,
    (subGroup) => subGroup.mainGroupId
  );

  const mainGroupKeyPrefix = "main-";
  const allSubjectsKey = "all";

  const checkedSubjectsKeys =
    subGroupIds.length === subjectsFilter.length
      ? [allSubjectsKey]
      : subjectsFilter;

  const handleSubjectsCheck = (
    checkedKeys: string[],
    e: AntTreeNodeCheckedEvent
  ) => {
    if (e.node.props.eventKey === allSubjectsKey || checkedKeys.length === 0) {
      setSubjectsFilter(subGroupIds);
    } else {
      setSubjectsFilter(
        checkedKeys
          .filter(
            (key) => !key.includes(mainGroupKeyPrefix) && key !== allSubjectsKey
          )
          .map((key) => Number(key))
      );
    }
  };

  const scopes = useRecoilValue(scopesState);
  const roleTemplates = useRecoilValue(roleTemplatesState);

  const roleTemplatesByScopeId = groupBy(
    roleTemplates,
    (roleTemplate) => roleTemplate.scopeId
  );

  const checkedTpgKeys =
    tpgFilter.inRoleTemplateIds.length === 0 &&
    tpgFilter.notInRoleTemplatesAndInScopeIds.length === 0
      ? ["allTextLines"]
      : [
          ...tpgFilter.inRoleTemplateIds.map(
            (inRoleTemplateId) => `inRoleTemplateId-${inRoleTemplateId}`
          ),

          ...tpgFilter.notInRoleTemplatesAndInScopeIds.map(
            (notInRoleTemplatesAndInScopeId) =>
              `notInRoleTemplatesAndInScopeId-${notInRoleTemplatesAndInScopeId}`
          ),
        ];

  const handleTpgCheck = (
    checkedKeys: string[],
    e: AntTreeNodeCheckedEvent
  ) => {
    if (
      e.node.props.eventKey === "allTextLines" ||
      checkedKeys.filter((key) => key.includes("entireScope")).length ===
        Object.keys(scopes).length
    ) {
      setTpgFilter({
        inRoleTemplateIds: [],
        notInRoleTemplatesAndInScopeIds: [],
      });

      return;
    }

    setTpgFilter({
      inRoleTemplateIds: checkedKeys
        .filter((key) => key.includes("inRoleTemplateId"))
        .map((key) => key.split("-")[1]),

      notInRoleTemplatesAndInScopeIds: checkedKeys
        .filter((key) => key.includes("notInRoleTemplatesAndInScopeId"))
        .map((key) => key.split("-")[1]),
    });
  };

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack>
        <Heading size="sm" fontWeight="medium">
          zoek in tekst
        </Heading>
        <Input
          value={keywordFilter}
          onChange={(e) => {
            setKeywordFilter(e.target.value);
          }}
        />
      </Stack>

      <Stack>
        <Heading size="sm" fontWeight="medium">
          onderwerpen
        </Heading>

        <Tree
          checkable
          selectable={false}
          checkedKeys={checkedSubjectsKeys as any}
          onCheck={(checkedKeys, e) =>
            handleSubjectsCheck(checkedKeys as string[], e)
          }
        >
          <Tree.TreeNode
            title={
              <em style={{ color: "var(--chakra-colors-gray-800)" }}>
                Alle onderwerpen
              </em>
            }
            key={allSubjectsKey}
            style={{ fontSize: "1rem" }}
          />
          {sortBy(mainGroups, (mainGroup) => mainGroup.position).map(
            (mainGroup) => (
              <Tree.TreeNode
                key={`${mainGroupKeyPrefix}${mainGroup.id}`}
                title={
                  <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                    {mainGroup.name}
                  </span>
                }
                style={{ fontSize: "1rem" }}
                // style={{
                //   transition: "all ease-in-out .2s",
                //   ...(mainGroup.id in
                //     filteredEntities.mutedSubjects.mainGroups && {
                //     opacity: 0.3,
                //   }),
                // }}
              >
                {sortSubGroupsByPosition(
                  // @ts-ignore
                  subGroupsByMainGroupId[mainGroup.id]
                ).map((subGroup) => (
                  <Tree.TreeNode
                    key={subGroup.id}
                    title={
                      <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                        {subGroup.name}
                      </span>
                    }
                    style={{
                      fontSize: "1rem",
                    }}

                    // style={{
                    //   opacity:
                    //     !(
                    //       mainGroup.id in
                    //       filteredEntities.mutedSubjects.mainGroups
                    //     ) &&
                    //     subGroup.id in filteredEntities.mutedSubjects.subGroups
                    //       ? 0.3
                    //       : 1,
                    // }}
                  />
                ))}
              </Tree.TreeNode>
            )
          )}
        </Tree>
      </Stack>

      <Stack>
        <Heading size="sm" fontWeight="medium">
          branches / scopes / templates
        </Heading>

        <Tree
          checkable
          selectable={false}
          checkedKeys={checkedTpgKeys}
          onCheck={(checkedKeys, e) =>
            handleTpgCheck(checkedKeys as string[], e)
          }
        >
          <Tree.TreeNode
            key="allTextLines"
            title={
              <em style={{ color: "var(--chakra-colors-gray-800)" }}>
                Alle tekstregels
              </em>
            }
            style={{ fontSize: "1rem" }}
          />
          {Object.values(scopes).map((scope) => (
            <Tree.TreeNode
              key={`entireScope_${scope.id}`}
              title={
                <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                  {scope.name}
                </span>
              }
              style={{ fontSize: "1rem" }}
            >
              <Tree.TreeNode
                key={`notInRoleTemplatesAndInScopeId-${scope.id}`}
                title={
                  <em style={{ color: "var(--chakra-colors-gray-800)" }}>
                    Tekstregels buiten templates
                  </em>
                }
                style={{ fontSize: "1rem" }}
              />
              <>
                {sortBy(
                  roleTemplatesByScopeId[scope.id],
                  (roleTemplate) => roleTemplate.name
                ).map((roleTemplate) => (
                  <Tree.TreeNode
                    key={`inRoleTemplateId-${roleTemplate.id}`}
                    title={
                      <span style={{ color: "var(--chakra-colors-gray-800)" }}>
                        {roleTemplate.name}
                      </span>
                    }
                    style={{ fontSize: "1rem" }}
                  />
                ))}
              </>
            </Tree.TreeNode>
          ))}
        </Tree>
      </Stack>
    </Stack>
  );
};

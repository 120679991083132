import { api } from "./common";
import { Id, Evaluation } from "../types";
import { fetchEvaluations } from "./fetch-evaluations";

type Props = { subscriptionId: Id } & Pick<Evaluation, "id">;

export const deleteEvaluation = async (props: Props) => {
  await api(`v2/personevaluation/${props.id}`, {
    method: "DELETE",
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { evaluations, evaluations_ratings } = await fetchEvaluations(
    props.subscriptionId
  );

  return { evaluations, evaluations_ratings };
};

import { api } from "./common";
import { Id, OrgUnit } from "../types";
import { fetchOrgEntities } from "./fetch-org-entities";

type Props = { subscriptionId: Id } & Pick<OrgUnit, "id">;

export const deleteOrgUnit = async (props: Props) => {
  await api(`v2/departments/${props.id}`, {
    method: "DELETE",
    headers: { subscriptionid: props.subscriptionId.toString() },
  });

  const { orgUnits } = await fetchOrgEntities(props.subscriptionId);

  return { orgUnits };
};

import React, { FC, useEffect, useState } from "react";
import { OrganisationContext } from "../../contexts/organisation";
import { Id } from "../../types";
import { Panel, PanelGroup } from "../../components/panel";
import { Box } from "@chakra-ui/react";
import { OrgEntities } from "./org-entities";
import { Selection } from "./selection";
import { orgEntitiesState, orgEntityKey, OrgEntityType } from "../../selectors";
import { useRecoilValue } from "recoil";
import { Filters } from "./filters";
import { Helmet } from "react-helmet";
import { subGroupsState } from "../../atoms";
import { TextLineMatrix } from "./text-line-matrix";

export type TpgFilter = {
  notInRoleTemplatesAndInScopeIds: Id[];
  inRoleTemplateIds: Id[];
};

export type SubjectsFilter = Id[];

export const Organisation: FC = () => {
  const subGroups = useRecoilValue(subGroupsState);

  const [selectedEntityIds, setSelectedEntityIds] = useState<Id[]>([]);
  const [keywordFilter, setKeywordFilter] = useState<string>("");
  const [tpgFilter, setTpgFilter] = useState<TpgFilter>({
    notInRoleTemplatesAndInScopeIds: [],
    inRoleTemplateIds: [],
  });
  const [subjectsFilter, setSubjectsFilter] = useState<SubjectsFilter>([]);

  const orgEntityIsSelected = (props: { type: OrgEntityType; id: Id }) =>
    selectedEntityIds.includes(orgEntityKey(props));

  const toggleSelectOrgEntity = (props: { type: OrgEntityType; id: Id }) => {
    const key = orgEntityKey(props);
    setSelectedEntityIds(
      orgEntityIsSelected(props)
        ? selectedEntityIds.filter((id) => id !== key)
        : [...selectedEntityIds, key]
    );
  };

  const orgEntities = useRecoilValue(orgEntitiesState);

  useEffect(() => {
    setSubjectsFilter(Object.values(subGroups).map((subGroup) => subGroup.id));
  }, [subGroups]);

  return (
    <>
      <Helmet>
        <title>Organisatie</title>
      </Helmet>

      <OrganisationContext.Provider
        value={{
          selectedOrgEntityIds: selectedEntityIds.filter(
            (id) => id in orgEntities
          ),
          setSelectedOrgEntityIds: setSelectedEntityIds,
          orgEntityIsSelected,
          toggleSelectOrgEntity,

          keywordFilter,
          setKeywordFilter,

          tpgFilter,
          setTpgFilter,

          subjectsFilter,
          setSubjectsFilter,
        }}
      >
        <PanelGroup>
          <Panel
            id="entities"
            title="entiteiten"
            minWidth="400px"
            maxWidth="400px"
          >
            <Box p={3} h={"full"}>
              <OrgEntities />
            </Box>
          </Panel>

          <Panel
            id="selection"
            title="selectie"
            minWidth="300px"
            maxWidth="300px"
          >
            <Box p={3} h={"full"}>
              <Selection />
            </Box>
          </Panel>

          {selectedEntityIds.length > 0 && (
            <Panel
              id="filters"
              title="filters"
              minWidth="300px"
              maxWidth="300px"
            >
              <Box p={3} h={"full"}>
                <Filters />
              </Box>
            </Panel>
          )}

          {selectedEntityIds.length > 0 && (
            <Panel
              id="select-text-lines"
              title="tekstregels kiezen"
              minWidth="800px"
              maxWidth="800px"
            >
              <Box p={3} h={"full"}>
                <TextLineMatrix />
              </Box>
            </Panel>
          )}

          {selectedEntityIds.length > 0 && (
            <Panel
              id="selected-text-lines"
              title="gekozen tekstregels"
              minWidth="800px"
              maxWidth="800px"
            >
              <Box p={3} h={"full"}>
                <TextLineMatrix onlySelectedTextLines={true} />
              </Box>
            </Panel>
          )}
        </PanelGroup>
      </OrganisationContext.Provider>
    </>
  );
};

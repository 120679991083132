import React, { FC, useContext } from "react";
import {
  Link as ReactRouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  functions_modelFunctionsState,
  functions_rolesState,
  functionsState,
  modelFunctionsState,
  peopleState,
  people_functionsState,
  rolesState,
  orgUnitsState,
} from "../../../atoms";
import {
  Button,
  Checkbox,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  StackDivider,
  Link as ChakraLink,
  Flex,
} from "@chakra-ui/react";
import {
  OrganisationContext,
  OrganisationContextShape,
} from "../../../contexts/organisation";
import { DeleteFunction } from "./delete-function";
import { UpdateFunctionOrgUnit } from "./update-function-org-unit";
import { RenameFunction } from "./rename-function";
import { UnlinkFunctionFromModelFunction } from "./unlink-function-from-model-function";
import { LinkFunctionToModelFunction } from "./link-function-to-model-function";
import { LinkFunctionToRole } from "./link-function-to-role";
import { UnlinkFunctionFromRole } from "./unlink-function-from-role";
import { PDF } from "./pdf";

export const Function: FC = () => {
  const { goBack } = useHistory();
  const { id: rawId } = useParams<{ id: string }>();
  const id = Number(rawId);

  const { orgEntityIsSelected, toggleSelectOrgEntity } = useContext(
    OrganisationContext
  ) as NonNullable<OrganisationContextShape>;

  const functions = useRecoilValue(functionsState);

  const func = functions[id];

  const orgUnits = useRecoilValue(orgUnitsState);
  const orgUnit = orgUnits[func.orgUnitId];

  const people = useRecoilValue(peopleState);
  const linkedPeople = Object.values(useRecoilValue(people_functionsState))
    .filter((rel) => rel.functionId === id)
    .map((rel) => people[rel.personId]);

  const modelFunctions = useRecoilValue(modelFunctionsState);
  const linkedModelFunctions = Object.values(
    useRecoilValue(functions_modelFunctionsState)
  )
    .filter((rel) => rel.functionId === id)
    .map((rel) => modelFunctions[rel.modelFunctionId]);

  const roles = useRecoilValue(rolesState);
  const linkedRoles = Object.values(useRecoilValue(functions_rolesState))
    .filter((rel) => rel.functionId === id)
    .map((rel) => roles[rel.roleId]);

  if (!func) {
    return null;
  }

  return (
    <Stack divider={<StackDivider />} spacing={6}>
      <Stack spacing={4} align="start">
        <Heading as="h3" size="sm" fontWeight="medium">
          {func.name}
        </Heading>

        <PDF
          orgEntityType="function"
          orgEntityId={func.id}
          orgEntityName={func.name}
        />

        {/* delete function */}
        <div>
          <Popover placement="right-start">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">verwijder functie</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>functie verwijderen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <DeleteFunction
                        id={id}
                        onSuccess={() => {
                          goBack();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* rename function */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">pas naam aan</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>naam aanpassen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <RenameFunction
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>

        {/* update org unit */}
        <div>
          <Popover placement="right-start">
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">kies organisatie-eenheid</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>kies organisatie-eenheid</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <UpdateFunctionOrgUnit
                        id={id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </div>
      </Stack>

      {/* org unit */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          organisatie-eenheid
        </Heading>

        <Stack direction="row" key={orgUnit.id} align="baseline" spacing={4}>
          <Checkbox
            isChecked={orgEntityIsSelected({
              type: "orgUnit",
              id: orgUnit.id,
            })}
            onChange={() => {
              toggleSelectOrgEntity({ type: "orgUnit", id: orgUnit.id });
            }}
            alignItems="baseline"
          >
            {orgUnit.name}
          </Checkbox>

          <Spacer />

          <ChakraLink
            as={ReactRouterLink}
            to={`/organisatie/organisatie-eenheden/${orgUnit.id}`}
          >
            details
          </ChakraLink>
        </Stack>
      </Stack>

      {/* linked people */}
      <Stack spacing={4}>
        <Heading as="h3" size="sm" fontWeight="medium">
          gekoppelde personen
        </Heading>

        {linkedPeople.map((person) => (
          <Stack direction="row" key={person.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "person",
                id: person.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({ type: "person", id: person.id });
              }}
              alignItems="baseline"
            >
              {person.name}
            </Checkbox>

            <Spacer />

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/personen/${person.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked model functions */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde modelfuncties
          </Heading>

          <Spacer />

          {/* link model function */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">koppel modelfunctie</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>modelfunctie koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkFunctionToModelFunction
                        functionId={func.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedModelFunctions.map((modelFunction) => (
          <Stack
            direction="row"
            key={modelFunction.id}
            align="baseline"
            spacing={4}
          >
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "modelFunction",
                id: modelFunction.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({
                  type: "modelFunction",
                  id: modelFunction.id,
                });
              }}
              alignItems="baseline"
            >
              {modelFunction.name}
            </Checkbox>

            <Spacer />

            {/* unlink model function */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>modelfunctie ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkFunctionFromModelFunction
                          functionId={func.id}
                          modelFunctionId={modelFunction.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/modelfuncties/${modelFunction.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>

      {/* linked roles */}
      <Stack spacing={4}>
        <Flex>
          <Heading as="h3" size="sm" fontWeight="medium">
            gekoppelde rollen
          </Heading>

          <Spacer />

          {/* link role */}
          <Popover>
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <Button size="xs">koppel rol</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>rol koppelen</PopoverHeader>
                  <PopoverBody>
                    {isOpen && ( // force re-render when closed
                      <LinkFunctionToRole
                        functionId={func.id}
                        onSuccess={() => {
                          onClose();
                        }}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>

        {linkedRoles.map((role) => (
          <Stack direction="row" key={role.id} align="baseline" spacing={4}>
            <Checkbox
              isChecked={orgEntityIsSelected({
                type: "role",
                id: role.id,
              })}
              onChange={() => {
                toggleSelectOrgEntity({
                  type: "role",
                  id: role.id,
                });
              }}
              alignItems="baseline"
            >
              {role.name}
            </Checkbox>

            <Spacer />

            {/* unlink role */}
            <Popover>
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="xs">ontkoppel</Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>rol ontkoppelen</PopoverHeader>
                    <PopoverBody>
                      {isOpen && ( // force re-render when closed
                        <UnlinkFunctionFromRole
                          functionId={func.id}
                          roleId={role.id}
                          onSuccess={() => {
                            onClose();
                          }}
                        />
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>

            <ChakraLink
              as={ReactRouterLink}
              to={`/organisatie/rollen/${role.id}`}
            >
              details
            </ChakraLink>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

import React, { FC, useContext } from "react";
import { Id } from "../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  activeSubscriptionIdState,
  evaluations_ratingsState,
  evaluationsState,
} from "../../atoms";
import { EvaluationsContext } from "../../contexts/evaluations";
import {
  FormControl,
  Stack,
  Button,
  FormErrorMessage,
  Box,
  StackDivider,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { updateMachine } from "../../machines/update";
import { updateEvaluationStatus } from "../../api/update-evaluation-status";
import { CheckIcon } from "@chakra-ui/icons";

export const Status: FC = () => {
  const [, updateEvaluationStatusSend] = useMachine(updateMachine, {
    actions: {
      success: (_, event: any) => {
        setEvaluations(event.data.evaluations);
        setEvaluations_ratings(event.data.evaluations_ratings);
        selectedEvaluationSend("NEXT");
      },
    },
  });

  const { selectedEvaluationMachine } = useContext(EvaluationsContext);
  const [selectedEvaluationState, selectedEvaluationSend] =
    selectedEvaluationMachine as NonNullable<typeof selectedEvaluationMachine>;

  const activeSubscriptionId = useRecoilValue(activeSubscriptionIdState);

  const [evaluations, setEvaluations] = useRecoilState(evaluationsState);
  const selectedEvaluation =
    evaluations[selectedEvaluationState.context.selectedEvaluationId as Id];

  const [evaluations_ratings, setEvaluations_ratings] = useRecoilState(
    evaluations_ratingsState
  );

  const scopeIsEmpty = !Object.values(evaluations_ratings).some(
    (rel) =>
      rel.evaluationId === selectedEvaluationState.context.selectedEvaluationId
  );

  return (
    <Stack spacing={8} divider={<StackDivider />}>
      <Box>
        <Stack
          spacing={3}
          opacity={
            selectedEvaluationState.matches({ selected: "definingScope" })
              ? 1
              : 0.25
          }
        >
          <Heading size="sm" fontWeight="medium">
            scope bepalen
          </Heading>
          {selectedEvaluationState.matches({ selected: "definingScope" }) && (
            <>
              <Text>
                Dit is de voorbereidende stap van elke evaluatie. Het doel is om
                zowel de beoordelaar en beoordeelde als de inhoud van de
                evaluatie eenduidig vast te leggen.
              </Text>

              <FormControl isInvalid={scopeIsEmpty}>
                <Button
                  alignSelf="start"
                  size="sm"
                  onClick={() =>
                    updateEvaluationStatusSend("UPDATE", {
                      update: updateEvaluationStatus({
                        subscriptionId: activeSubscriptionId as Id,
                        evaluationId: selectedEvaluation.id,
                        status: "scopeDefined",
                      }),
                    })
                  }
                  isDisabled={scopeIsEmpty}
                  rightIcon={<CheckIcon />}
                >
                  rond bepalen van scope af
                </Button>

                {scopeIsEmpty && (
                  <FormErrorMessage>de scope is leeg</FormErrorMessage>
                )}
              </FormControl>
            </>
          )}
        </Stack>

        <Stack spacing={3}>
          <Heading
            size="sm"
            fontWeight="medium"
            opacity={
              selectedEvaluationState.matches({ selected: "scopeDefined" })
                ? 1
                : 0.25
            }
          >
            scope gereed
          </Heading>
          {selectedEvaluationState.matches({ selected: "scopeDefined" }) && (
            <>
              <Text>De evaluatie wacht op de start door de beoordelaar.</Text>{" "}
              <FormControl isInvalid={scopeIsEmpty}>
                <Button
                  alignSelf="start"
                  size="sm"
                  onClick={() =>
                    updateEvaluationStatusSend("UPDATE", {
                      update: updateEvaluationStatus({
                        subscriptionId: activeSubscriptionId as Id,
                        evaluationId: selectedEvaluation.id,
                        status: "conducting",
                      }),
                    })
                  }
                  rightIcon={<CheckIcon />}
                >
                  start de evaluatie
                </Button>
              </FormControl>
            </>
          )}
        </Stack>

        {selectedEvaluationState.matches({ selected: "conducting" }) && (
          <Stack spacing={3}>
            <Heading size="sm" fontWeight="medium">
              in uitvoering
            </Heading>

            <FormControl isInvalid={scopeIsEmpty}>
              <Button
                alignSelf="start"
                size="sm"
                onClick={() =>
                  updateEvaluationStatusSend("UPDATE", {
                    update: updateEvaluationStatus({
                      subscriptionId: activeSubscriptionId as Id,
                      evaluationId: selectedEvaluation.id,
                      status: "conducted",
                    }),
                  })
                }
                rightIcon={<CheckIcon />}
              >
                markeer evaluatie als gereed
              </Button>
            </FormControl>
          </Stack>
        )}

        {selectedEvaluationState.matches({ selected: "conducted" }) && (
          <Stack spacing={3}>
            <Heading size="sm" fontWeight="medium">
              uitgevoerd
            </Heading>
            <Text>
              De evaluatie wacht op bespreking en kan niet meer gemuteerd
              worden.
            </Text>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
